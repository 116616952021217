@mixin border() {
  border: 1px solid $color-red-a500;
}

.timeline {

  &.-future, &.past {
    .-contactpersoon {
      margin-left: .25rem;
    }
  }

  &.-future {
    margin-bottom: -1rem;

    & > div:last-child {
      padding-bottom: 3rem;
    }


    .-linecap {
      @extend .icon;
      @extend .icon-arrow-up-bold;
      font-size: 32px;
      position: relative;
      top: 8px;
      left: 16px;
      color: $color-blue-200;
    }
  }

  &.-past {
    margin-top: -1rem;

    & > :first-child {
      padding-top: 3rem;
    }

    & > :nth-last-child(2) {
      padding-bottom: 2rem;
    }

    .-linecap {
      position: relative;
      top: -.25rem;
      left: 24px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $color-blue-200;
    }
  }


  .-item {
    display: flex; //width: 100%;
    padding: 8px 0;
    background: linear-gradient(90deg, $color-blue-200 8px, $transparent-black-00 2px);
    background-repeat: repeat-y;
    background-position: 28px 0;
    .-content {
      width: 100%;
      .-date, .-contactpersoon {
        font-size: .8rem;
        display: inline-block;
        min-height: 16px;
        opacity: .7;
      }
      .-title {
        font-size: 1.25rem;
      }
      .-subtitle {
        font-size: 1rem;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        color: $color-white;
        border-radius: 3px;
        background: $color-blue;
      }
      .-description {
        &.text-fade {
          @extend .text-fade;
          height: 2rem;
          &.-stop-fade {
            height: initial;
            &:before {
              background: transparent;
            }
          }
        }
      }
    }
  }

  .-bullet {
    position: relative;
    top: 6px;
    display: flex;
    justify-content: space-around;
    width: 64px;
    height: 64px;
    margin-right: 16px;
    border: 8px solid $color-blue-200;
    border-radius: 42px;
    background-color: $color-blue;

    align-items: center;
    flex-shrink: 0;

    &.color-rose-bud-cherry {
      background-color: $color-burgundy;
    }
    &.color-curious-blue {
      background-color: $color-blue;
    }
    &.color-west-side {
      background-color: $color-orange;
    }
    &.color-caribbean-green {
      background-color: $color-turquoise;
    }
    &.color-iron {
      background-color: $color-grey;
    }
    &.color-crimson {
      background-color: $color-red;
    }
    &.color-mantis {
      background-color: $color-lightgreen-600;
    }

    span {
      font-size: 1.2rem;
      position: relative;
      top: -2px;
      color: $color-white;
    }
  }

  // .-item-afspraak, .-item-smpcontact, .-item-inschattingsgesprek {
  //   .-bullet {
  //     background-color: $color-burgundy;
  //     span {
  //       @extend .icon-calendar;
  //     }
  //   }
  // }

  //vervangt bovenstaande
  .-item-calendar {
    .-bullet {
      background-color: $color-burgundy;
      span {
        @extend .icon-calendar;
      }
    }
  }

  .-item-threshold, .-item-smpdrempel {
    .-bullet {
      span {
        @extend .icon-threshold;
        margin-top: -6px;
        margin-left: -5px;
      }
    }
  }

  .-item-smpdienst, .-item-eersteinschatting, .-item-opvolging {
    .-bullet {
      span {
        @extend .icon-cog;
        top: 0;
      }
    }
  }

  .-item-opleiding {
    .-bullet {
      background-color: $color-orange;
      span {
        @extend .icon-education;
      }
    }
  }

  .-item-opdracht, .-item-smpopdracht {
    .-bullet {
      background-color: $color-burgundy;
      span {
        @extend .icon-opdracht;
      }
    }
  }

  .-item-opmerking {
    .-bullet {
      span {
        @extend .icon-comment;
      }
    }
  }

  .-item-registratie {
    .-bullet {
      background-color: $color-turquoise;
      span {
        @extend .icon-registreer;
      }
    }
  }

  .-item-startpakket {
    .-bullet {
      background-color: $color-turquoise;
      span {
        @extend .icon-startpakket;
      }
    }
  }

  .-item-werk {
    .-bullet {
      background-color: $color-orange;
      span {
        @extend .icon-job;
      }
    }
  }

  .-item-dienstverlening, .-item-ondersteuningsnood {
    .-bullet {
      span {
        @extend .icon-handshake;
        font-size: 1.8rem;
        padding-top: 5px;
      }
    }
  }
  
  .-item-opvolggesprek {
    .-bullet {
      span {
        @extend .icon-comments;
        font-size: 1.8rem;
        padding-top: 5px;
      }
    }
  }

  .-item-transmissie {
    .-bullet {
      span {
        @extend .icon-two-arrows-right-left;
        font-size: 1.4rem;
        font-weight: bold;
        padding-top: 5px;
      }
    }
  }

  .-item-leerweg {
    .-bullet {
      span {
        @extend .icon-road-variant;
      }
    }
  }

  .-item-toelichting {
    .-bullet {
      span {
        @extend .icon-book-open-page-variant;
      }
    }
  }

  &.-present {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 28px;
    padding-top: .1px;//this is intended and do not remove, it is a hack for weird behaviour, ask and I(>steven) will show and explain ;-)
    padding-left: 8px;
    border-radius: 3px;
    background: $color-blue-200;

    & > .-date {
      font-size: 1.75rem;
      font-weight: 100;
      line-height: 90%;
      margin-top: 1rem;
      margin-left: 2.5rem;
      color: $transparent-black-60;
    }

    .-item {
      margin-left: -36px;
      padding-right: 40px;
      border-bottom: 1px solid $color-grey-100;

      .-content {
        .-description {
          &.text-fade:before {
            @include fade-background(rgba(219, 236, 248, 0), rgba(219, 236, 248, 1))
          }
        }
      }
    }

    .-item-today {
      border: 0;
    }
  }
}

