

// ICONS
// - - - - - - - - - - - - - - - - - - - -
// Add icons on an element. The icons are
// supplied with an icon-font, generated by FontCustom.
//
// Examples:
// ---------
// .selector {
//   @include icon(search);
// }
//
// .selector {
//   @include icon(search, 'after');
// }
//
// .selector {
//   @include icon(search) {
//     color: $color-primary;
//     @include font-size(32, 0);
//     @include rfb(margin-right, 1);
//   };
// }
//
// .selector {
//   position: relative;
//
//   @include icon(search, $replace: true) {
//     color: $color-grey;
//     position: absolute;
//     top: 0;
//     @include rfb(left, 1);
//   };
// }
//
@mixin icon(
  $icon,
  $position: 'before',
  $replace: false,
  $breakpoint: false
  ) {
  @if $replace {
    font-size: 0;
  }

  &:#{$position} {
    @extend .icon-#{$icon}:before;
    font-family: 'icons';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;

    speak: none;
    @if $replace and $breakpoint == false {
      @include font-size(16, 0);
    } @else if $breakpoint {
      font-size: 0;
    }
    @content;
  }
  @if $breakpoint {
    @include breakpoint($breakpoint) {
      &:#{$position} {
        @include font-size(16, 0);
      }
    }
  }
}
