%panel-group {
  @include rfb(margin-bottom, 1);
  border: $border-default;
  border-radius: 2px;
  background-color: $color-white;

  .panel {
    margin: 0;
    padding: 0;
    border-width: 0 0 1px;
    border-bottom: $border-default;
    border-radius: 0;
    box-shadow: none;

    &:first-of-type {
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      border: 0;
      border-radius: 0 0 2px 2px;
    }
  }

  .main-section {
    border-style: none;
  }
}

%col {
  //sass-lint:disable-block no-duplicate-properties
  position: relative;
  float: left;
  width: 100%;
  padding-right: 16px;
  padding-right: 1rem;
  padding-left: 16px;
  padding-left: 1rem;
}
