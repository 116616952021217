$breadcrumb-link-color: $color-grey-800;
$breadcrumb-last-color: $color-muted;

.breadcrumb {
  @include rfb(padding, .5, 1, 0, 1);
  display: none;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  color: $breadcrumb-last-color;

  a {
    color: $breadcrumb-link-color;

    &::after {
      @include rfb(margin-left, .5);
      font-family: 'icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      display: inline-block;
      content: char-from-code($icon-arrow-right);
      vertical-align: middle;
      text-decoration: inherit;
      text-transform: none;
      color: $breadcrumb-last-color;

      speak: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  ol {
    @extend %clear;
    @include rfb(padding-left, 0);
    @include rfb(margin-bottom, 0);
  }

  li {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
    list-style-image: none;
  }

  @media (min-width: $tablet-large) {
    display: block;
  }
}

// for breadcrumb inside container (see breadcrumb documentation)
.container {
  nav.breadcrumb {
    padding: 0;
    ol {
      @include rfb(margin-bottom, 1);
    }
  }
}
