// Fading text + read more for when your description is to long

@mixin fade-background($from-color: $transparent-white-00, $to-color: $color-white) {
  background: linear-gradient($from-color 50%, $to-color);
}

.text-fade, .text-fade-on {
  position: relative;
  overflow: hidden;
  height: 15rem;
  &:before {
    @include fade-background();
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.text-unfaded {
  min-height: 15rem;
  -webkit-transition: height 2s, -webkit-transform 2s; /* Safari */
  transition: height 2s, transform 2s;
}

.text-fade-button {
  position: relative;
  left: 0;
  &:before {
    @extend .icon;
    @extend .icon-arrow-down;
    padding-right: .3em;
    vertical-align: middle;
  }
}

@media (max-width: $screen-small) {
  .text-fade-button {
    // Regular button styling (can't extend inside media query :'( )
    @include font-smoothing();
    @include rfb(padding, .2, 1);
    font-family: $brand-font-regular;
    // Specific text-fade-button styling
    position: absolute;
    left: 50%;
    display: inline-block;
    margin-top: -1rem;
    cursor: pointer;
    transition: (all .1s);
    transform: translate(-50%, -50%);
    color: $color-white;
    border: 0;
    border-radius: 2px;
    background: $transparent-black-40;
    &:focus {
      border-radius: 5px;
      outline: $transparent-black-05 solid 3px;
    }
    &:hover {
      color: $color-white;
      background: saturate(lighten($color-primary, 5%), 20%);
    }

    &:visited {
      color: $color-white;
    }

    &:disabled {
      color: $color-grey-700;
      border-color: $color-grey;
      background: $color-grey-300;
    }
    &:before {
      content: none;
    }
  }
}
