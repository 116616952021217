.awesomplete [hidden] {
  display: none;
}

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.awesomplete {
  position: relative;
  display: inline-block;
  float: left;
}

/*fix positioning issue for use within huisstijl form field*/
.form-field .awesomplete input[type='text'] {
  float: none;
}

/*END*/

.awesomplete>input {
  display: block;
}

.awesomplete>ul {
  position: absolute;
  z-index: 1;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid $transparent-black-30;
  border-radius: .3em;
  background: $color-white;
  box-shadow: .05em .2em .6em $transparent-black-20;
  text-shadow: none;
}

.awesomplete>ul:empty {
  display: none;
}

@supports (transform: scale(0)) {
  .awesomplete>ul {
    transition: .3s cubic-bezier(.4, .2, .5, 1.4);
    transform-origin: 1.43em -.43em;
  }

  .awesomplete>ul[hidden], .awesomplete>ul:empty {
    display: block;
    transition-timing-function: ease;
    transform: scale(0);
    opacity: 0;
  }
}

/* Pointer */
/*no pointer for VDAB styling*/
/* 
.awesomplete > ul:before {
content: "";
position: absolute;
top: -.43em;
left: 1em;
width: 0; height: 0;
padding: .4em;
background: white;
border: inherit;
border-right: 0;
border-bottom: 0;
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
}
*/

.awesomplete>ul>li {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: .2em .5em;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  /*add VDAB styling*/
  color: $color-grey-900;
}

.awesomplete>ul>li[aria-selected='true'] {
  color: $color-white;
  background: $color-blue-a500;
}

.awesomplete>ul>li[aria-selected='true']:hover {
  opacity: 1;
  color: $color-white;
  background: $color-blue-a500;
}

.awesomplete>ul>li:hover {
  color: $color-black;
  background: transparentize($color-blue-a500, .7);
}

.awesomplete mark {
  font-weight: bold;
  color: $color-blue-a500;
  background: none;
}

.awesomplete li:hover mark {
  color: $color-black;
}

.awesomplete li[aria-selected='true'] mark {
  font-weight: bold;
  color: $color-white;
  background: none;
}

.awesomplete li[aria-selected='true'] .mark {
  font-weight: bold;
  color: $color-white;
  background: none;
}

.awesomplete-metadata {
  font-size: .8em;
  font-style: italic;
  margin-bottom: 0;
  white-space: normal;
}

/* extra class: does the same as standard highlight but for dev use*/
.awesomplete li[aria-selected='true'].highlight {
  opacity: 1;
  color: $color-white;
  background: $color-blue-a500;
}


/*
* CSS for multiselect 
*/

.awesomplete.-multi {
  ul {
    overflow-y: scroll;
    max-height: 200px;
  }

  li:not(.-close) {
    padding-left: 2rem;

    input {
      position: absolute;
      margin-top: .25rem;
      margin-left: -1.5rem;
    }

    label {
      display: block;
      cursor: pointer;
    }
  }

  .-close {
    position: sticky;
    bottom: -.1rem; //don't ask me why, dont know
    cursor: default;
    border-top: 1px solid $color-grey-300;
    background-color: $color-white;

  }
}