.panel {
  @include rfb(margin-bottom, 1);
  //overflow: hidden; // Overflow breaks typeaheads/menus
  position: relative;
  display: block;
  border: $border-default;
  border-radius: 2px;
  background-color: $color-white;
 
  &.panel-inside {
    background: $color-grey-100;
  }

  &.panel-table {
    clear: both;

    th, td {
      @include rfb(padding-left, 1);

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 15%;
      }
    }
  }

  .panel-body {
    @include rfb(padding, 1.5);

    // Remove double borders on form inside panel
    .form-container {
      border-right: 0;
      border-left: 0;
    }

    &.panel-accordion {
      border-bottom: $border-default;

      .accordion-title, .accordion-title-closed {
        font-weight: 500;
        width: 100%;
      }

      .accordion-title:after {
        @extend .icon-after;
        float: right;
        padding-top: .2em;
        content: char-from-code($icon-arrow-down);
        vertical-align: middle;
      }

      .accordion-title-closed:after {
        @extend .icon-after;
        float: right;
        padding-top: .2em;
        content: char-from-code($icon-arrow-right);
        vertical-align: middle;
      }
    }

    &.panel-accordion:last-child {
      border-bottom: 0;
    }

    // for gebruikersbeheer (IAM)
    &.panel-accordion-big {
      @include rfb(padding, 0);

      .alert-danger {
        margin-bottom: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .panel-accordion-big-head {
        @include rfb(padding, 1, 1.5);
        background-color: $color-grey-200;

        a {
          color: $color-grey-900;
        }

        strong.action-user:before {
          @include rfb(padding-right, .5);
          font-size: .7em;
        }

        .stamp-succes, .stamp {
          @include rfb(margin-left, .5);
        }

        &:hover {
          color: $color-white;
          background-color: $color-blue;

          a {
            color: $color-white;

            &:hover {
              color: $color-grey-200;
            }
          }
        }
      }

      .panel-accordion-big-body {
        @include rfb(padding, 1, 1.5);
      }
    }
  }

  .panel-accordion-arrow {
    @include rfb(padding, 1, 1.5);
    background-color: $color-grey-300;

    .panel-accordion-actions {
      float: right;
    }
  }

  .panel-footer {
    @include rfb(padding, 1, 1.5, 1, 1.5);
  }

  .panel-meta {
    background: $color-grey-100;
  }

  // Specific for Mijn Loopbaan dashboard
  .dashboard-image {
    @include rfb(padding, 0);
    @include rfb(margin, 0);

    img {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      display: block;
      zoom: 2; //increase if you have very small images
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin: auto;
    }
  }

  &.accent {
    background: $color-grey-100;
  }
}

.panel-page {
  border-top: 8px solid transparentize($color-blue-700, .5);

  // padding: 2rem;

  .block-display .block-display-head .head-action a {
    padding-right: 0;
    padding-left: .9rem;
    &:before {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .block-display:not(.block-display-neutral) .block-display-head .head-action [class^='header-action'], 
  .block-display:not(.block-display-neutral) .block-display-head .head-action [class^='icon-'] {
    color: $color-blue-700;
  }
  
  .block-display:not(.block-display-neutral) .block-display-head .head-action {
    
    a.button {
      color: $color-white;
    }

    [class^='action-'] {
      color: $color-blue-700;
    }
  }
  
  //TODO need to be refactored -> wrong use of "mu-..." !!!!!!!!!
  .mu-search-add-stuff-row .block-display {
    border: 1px solid $color-lightgreen-400;
  }

  .block-display {
    margin: .5rem 3rem;
    margin-bottom: 35px;

    &.pre-meta-block {
      margin: 0;
      background-color: transparentize($color-blue-700, .75);
      .block-display-head {
        // pl1 pr1 pt05;
        padding-top: .5rem;
        padding-right: 3rem;
        padding-bottom: .5rem;
        padding-left: 3rem;
      }
    }

    &.meta-block {
      td {
        width: 100%;
        padding-top: 0;
        vertical-align: top;
        color: $color-black;
        border: 0;
        label {
          color: $transparent-black-50;
        }
        ul {
          margin: 0;
          padding: 0 1rem;
          li {
            // list-style: none;
            padding: 0;
          }
        }
        &:first-of-type {
          width: 40%;
          padding-right: 1rem;
        }
      }
    }

    .block-display-head-form {
      background-color: $color-blue;
    }

    &.block-display-first {
      margin-bottom: 1rem;
      padding: 1rem 0 2rem;
      border-bottom: 1px solid transparentize($color-blue-700, .5);

      h1 {
        font-size: 1.75rem;
      }
    }

    .form-container {
      margin: 0;
      padding: 0;
      border: 0;
    }

    & .block-display-table { 
      // padding-left: 1rem;
      table {

        &.compare tr {
          td:first-of-type {
            padding-left: .5rem;
            border-left: 10px solid $color-white;
            border-left-width: 10px;
          }

          &.compare-row-removed td {
            text-decoration: line-through;
            color: $color-red-a500 !important;
            // padding-left: 1rem;
            border: 1px solid $color-red-a500;
            border-width: 1px 0 1px;
            background-color: transparentize($color-red-a500, .95);
            a {
              color: $color-red-a500 !important; 
            }

            &:first-of-type {
              border-left-width: 10px;
            }
            &:last-of-type {
              border-right-width: 1px;
            }
          }
          &.compare-row-added td {
            font-weight: 700;
            color: $color-green-700;
            border: 1px solid $color-green-700;
            border-width: 1px 0 1px;
            background-color: transparentize($color-green-700,  .95);
            
            &:first-of-type {
              border-left-width: 10px;
            }
            &:last-of-type {
              border-right-width: 1px;
            }
          }

        }

        tr {
          border-width: 0;
          background: transparent;
          th, td {
            padding: .5rem .5rem .5rem 0;
            border-bottom: 1px dotted $color-grey-300;
          }
          th:last-of-type, td:last-of-type {
            padding-right: 0;
          }

          //TODO need to be refactored -> wrong use of "mu-..." !!!!!!!!!
          &.mu-trigger-add-stuff-row td, &.mu-search-add-stuff-row td {
            border: 0;
          }
        }
      }
    }

    .block-display-head {
      border: 0;
      background: transparent;

      //TODO  need to be refactored -> a form is by default blue-ish and 
      //only a search is greenish so class is semantically WRONG !!!!!!!
      &.block-display-head-form {
        background-color: $color-lightgreen-200;
      }

      & > * {
        margin: 0;
        padding: 0;
        color: $color-blue-700;
      }

      h1, h2, h3, h4 {
        float: left;
        margin-top: .25rem;
      }
      // overrule to fix issue for Caroline D in IBO opleidingsplan
      h4 {
        font-size: 16px;
      }
    }

    .block-display-content, .block-display-table {
      padding: 0;
      border: 0;
    }

    .block-display-table {
      background-color: transparent;

      // sve: use this when head has 2 rows
      table thead:not(.table-head-default) {
        background-color: transparent;
      }
    }

    .block-display-pagination {
      border: 0;
      background-color: transparent;
    }

    .inline-search-result {
      padding: .25rem 1rem 0;
      //border: 1px solid $transparent-black-10;
      border-top-width: 0;
      border-radius: 0 0 4px 4px;
      background-color: $transparent-white-50;

      tr:last-of-type td {
        border: 0;
      }

      //TODO need to be refactored -> wrong use of "mu-..." !!!!!!!!!
      &.mu-search-stuff-result td {
        vertical-align: middle;
      }
    }

  }

  .panel-page-footer {
    margin: 0 2rem 2rem;
    padding-top: 1rem;
    border-top: 1px solid transparentize($color-blue-700, .5);
  }

  &.page-edit {
    background-color: $color-blue-100;
  }

  &.page-compare {
    .block-display {
      margin: 0;
      padding: .5rem 2rem 1rem;
      border-bottom: 4px double $color-grey-300;
    }
    .form-row {
      border: 1px solid $color-white;
      border-left-width: 10px;      
      &.compare-value-old {
        border: 1px solid $color-red-a500;
        border-left-width: 10px;
        background-color: transparentize($color-red-a500, .95);
        div:not(.form-label) {
          text-decoration: line-through;
          color: $color-red-a500;
        }
      }
    
      &.compare-value-new {
        border: 1px solid $color-green-700;
        border-left-width: 10px;
        background-color: transparentize($color-green-700,  .95);
        div {
          color: $color-green-700;
          &.form-field {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.panel-group {
  @include rfb(margin-bottom, 1);
  clear: both;
  border: $border-default;
  border-radius: 2px;
  background-color: $color-white;
  
  .panel-header {
    border-top: 0;
    border-right: 0;
    border-bottom: $border-default;
    border-left: 0;
  }

  .text-important {
    font-weight: 500;
  }

  .panel {
    margin: 0;
    padding: 0;
    border-width: 0 0 1px;
    border-bottom: $border-default;
    border-radius: 0;
    box-shadow: none;

    &:first-of-type {
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      border: 0;
      border-radius: 0 0 2px 2px;
    }
  }

  .block-display-content {
    border: 0;
  }

  .main-section {
    border-style: none;
  }
}

.panel-title {
  @include font-size($font-size-base);
  font-weight: 500;
  position: relative;
  display: block;
  float: none;
  margin: 0;
  color: $color-default;

  a {
    @include rfb(padding, 1);
    position: relative;
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: $color-default !important;

    &:hover {
      color: $color-grey-a500;
    }
  }
}

.panel-icon {
  @include font-size(16, 26);
  @include rfb(padding, .5, 0, 0, 1);
  position: absolute;
  top: 0;
  right: 0;
  display: block;
}

.panel-top-actions {
  @include rfb(top, .5);
  @include rfb(right, .5);
  position: absolute;
}

/* Panelsorter */
.panel-sorter {
  .panel {
    @include rfb(padding, .5);
  }
}

.panel-group .panel.beslist {
  padding-bottom: 2rem;
  border-bottom: 0;
  background-color: $color-grey-100;
}

/*.panel-group .panel.footer {border-bottom:none; padding:0.5rem 2rem !important;}*/
.panel-group .panel.title-panel {
  h1 {
    margin-top: 6px;
  }

  a.button {
    float: right;
  }
}

// .panel-body h3 {
//   margin:0;
// }

.panel-footer {
  @include rfb(padding, 1, 1.5, 1, 1.5);
  // background: $color-blue-100;
  border-top: 1px solid $transparent-black-10;
  background: $color-grey-200;
}

.panel-header-icon {
  float: right;
}

.panel-header {
  @include rfb(padding, 1, 1.5, 1, 1.5);
  border: 1px solid $transparent-black-10;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  background: $color-grey-200;

  strong {
    @include rfb(margin, 0);
    text-align: left;
  }

  select {
    float: right;
  }

  &.panel-tip {
    color: $color-white;
    background: $color-turquoise;
  }

  &.panel-info {
    color: $color-white;
    background: $color-blue;
  }

  &.panel-important {
    color: $color-white;
    background: $color-burgundy-300;
  }
}

.panel-group-important {
  border-top: 2px solid $color-burgundy-300;

  .text-important {
    font-weight: 500;
    color: $color-burgundy-300;
  }
}

.panel-form {
  border-top: 1px solid $transparent-black-10;
  border-bottom: 1px solid $transparent-black-10;
  background: $color-blue-100;
}

.panel-banner {
  img {
    width: 100%;
    height: 250px;
  }
  @media (max-width: $screen-small) {
    img {
      width: 100%;
      height: 100px;
    }
  }
}

/**
 * Panel Override for when you use slats
 */
.panel-slats {
  .panel-header, .panel-footer {
    @include rfb(padding, 1, 2, 1, 2);
  }

  .panel-body {
    @include rfb(padding, 2, 2, 1, 2);
  }
}

.panel-group-accordion {
  .panel-accordion-arrow {
    border-bottom: 1px solid $transparent-black-10;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

// MLB dashboard circle in a panel
.mlb-dashboard-circle {
  margin-top: -10.5em;
  margin-left: 15.5em;
}
.mlb-dashboard-info {
  margin-bottom: 0;
  padding-right: 8em;
  white-space: wrap;
  word-break: break-all;
}
.mlb-dashboard-mail {
  margin-bottom: 1.5em;
}

// Make panel work with table-inject
table tr.table-inject .panel {
  margin-bottom: 0;
  border: 0;
}

// Panel transformed to cv (V1, likely to change)
.panel-cv {
  .form-label {
    width: 30%;
    label {
      color: $transparent-black-50;
    }
    strong {
      color: $color-black(0, 0, 0, 1);
      
    }
  }
  .row-title h1 {
    padding-left: 30%;
  }
  .row-title p {
    padding-left: 30%;
  }
  .form-field {
    width: 70%;
  }
  .row-subtitle h5 {
    width: 30%;
  }
  .form-row {
    padding: 0;
  }
  .action-certified {
    opacity: .75;
    color: $color-blue;
  }
}
.cv-overlay {
  background: $color-yellow-200;
}
