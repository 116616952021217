.nav-dropdown-mobile {
  margin-bottom: 1rem;

  .-menu {
    display: none;
  }

  .-list {
    font-size: 1.15rem;
    font-weight: 500;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline;
        border-left: 1px solid $color-grey-400;

        &:last-child {
          border-right: 1px solid $color-grey-400;
        }

        a {
          display: inline;
          padding: 0 1rem;
        }

        &:first-of-type {
          border-left: 0;

          a {
            padding-left: 0;
          }
        }

        &:last-of-type {
          border-right: 0;
        }
      }
    }
  }
}

@media (max-width: $screen-small) {

  .nav-dropdown-mobile {
    position: relative;
    margin-bottom: 0;

    .-menu {
      font-size: 1.2rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      padding: .5rem 1rem;
      border-bottom: 1px solid $color-grey-300;
      background-color: $color-white;

      align-items: center;

      .button {
        //z-index: 10; //why would this even need a z-index?
        padding: 8px 12px;
      }
    }

    .-list {
      position: absolute;
      z-index: 10;
      width: 100%; //calc(100% - 1rem);
      padding: 0;
      border-radius: 5px;
      background-color: $color-white;
      box-shadow: 0 3px 10px transparentize($color-black, .9);

      &.-slide-down {
        overflow: hidden;
        max-height: 500px;
        transition: all .5s ease-in-out;

        &.-collapsed {
          max-height: 0;
        }
      }

      // &:not(.-collapsed) {
      //   margin-top: -.4rem;
      // }

      ul {
        li {
          display: block;
          border-right: 0;
          border-bottom: 1px solid $color-grey-200;
          border-left: 0;

          &:hover {
            background-color: $color-grey-100;
          }

          a {
            display: block;
            padding: 1rem !important; //fuck it
          }
        }
      }
    }
  }
}