//
// Button bar CSS
// ---------------------------------------------------

.btn-bar {
  @extend %clear;

  &.ghost {
    > ul.btn-group {
      border: 0;

      > li > a, > li > .dropdown {
        @include rfb(padding, .3, 1);
        float: left;
        color: $color-blue;
        border: 0;
        background-color: $transparent-black-00;
      }

      > li > a.action-lone {
        min-width: 3em;
      }

      > li > a:hover {
        color: $color-blue;
        border: 0;
        background-color: $transparent-black-00;
      }

      > li:first-child > a, > li:first-child > .dropdown {
        @include rfb(padding-left, 0);
      }
    }
  }

  > ul.btn-group {
    @extend %clear;
    float: left;
    margin: 0 .3em;
    padding: 0;
    padding-left: 0 !important;
    list-style: none;
    border: 1px solid $color-blue-300;
    border-radius: 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.right {
      float: right;
    }

    > li {
      float: left;
      margin-left: 0 !important;
    }

    > li > a, > li > .dropdown {
      @include rfb(padding, .3, 1);
      float: left;
      color: $color-blue;
      border: 0;
      border-left: 1px solid $color-blue-300;
      background-color: $color-blue-100; //#e5edf8

      &.action-lone {
        min-width: 3em;
      }

      &:hover {
        color: $color-white;
        background-color: $color-blue;
      }
    }

    > li:first-child > a, > li:first-child > .dropdown {
      border-left: 0;
      border-radius: 5px 0 0 5px;
    }

    > li:last-child > a , > li:last-child > .dropdown {
      // border-left-width: 1px;
      border-radius: 0 5px 5px 0;
    }

    > li.dropdown > a {
      @extend .action-more;
    }
  }
}

// the loading button bar thing
a.button.delta.loading {
  padding-left: 35px;
  background: $color-grey-800 no-repeat url('../img/flickr.gif') 10px 50%;
}

.button-bar a.loader {
  display: none;
  padding-left: 35px;
  background: $color-blue-100 no-repeat url('../img/btnbar-loading.gif') 10px 50%;
}

.button-bar a.loader:hover {
  cursor: default;
  color: $color-blue;
}

.button-bar.loading a {
  display: none;
}

.button-bar.loading a.loader {
  display: inline;
  border-right: 1px solid $color-blue-300;
  border-radius: 5px;
}
