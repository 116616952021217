//
// MEDIA
// ------------------------------------------------
.media {
  @extend %clear;
  @include rfb(margin-bottom, 1);
  @include rfb(padding-bottom, 1);
  position: relative;
  display: block;
  border-bottom: $border-default;

  .media-browser & {
    @include rfb(padding, 1);
    margin-bottom: 0;

    &.active {
      @include rfb(padding-left, 1);
      @include rfb(padding-right, 1);
      position: relative;
      background: $color-link;

      a, .media-meta, .media-date {
        color: $color-white;
      }

      a, a:visited {
        text-decoration: none;
      }

      .vacancy-saved-state:hover {
        color: $color-white;
      }

      .meta {
        color: $color-muted;
      }
    }

    &.active, &.before-active {
      border: 0;
    }

    &:first-of-type {
      border-radius: 2px 2px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 2px 2px;
    }

    &:last-child {
      border: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.media-header {
  position: relative;
}

.media-place {
  margin-bottom: 0;
  color: $default-text-color;
}

.media-date {
  color: $color-muted;
}

.media-logo {
  @include rfb(max-width, 5.5);
  @include rfb(max-height, 2.75);
  @include tabletLarge() {
    @include rfb(top, 2);
    position: absolute;
    right: 0;
  }
  width: auto;
}

.media-list-title {
  @include font-size(14, 24);
  @include rfb(margin-bottom, 1);
  padding-top: 0;
}

.media-actions {
  @include tabletLarge() {
    @include font-size(14, 0);
    @include rfb(padding, 1);
    float: right;
    width: auto;
  }
  position: relative;
  z-index: 20;
  width: 100%;

  &.single {
    float: right;
    width: auto;
  }
}

.media-meta {
  @include font-size(14, 24);
  display: block;
  margin-bottom: 0;
  color: $default-text-color;
}

.media-list-header {
  @include rfb(margin-bottom, 1);
  border-bottom: $border-default;
}

.media-list .media:last-child {
  border: 0;
}

.media-action {
  float: right;

  a {
    cursor: pointer;
    text-decoration: none;
    color: $color-grey-800;
  }

  &.light a {
    color: $color-grey;
  }
}

.media-title {
  @include font-size(16, 24);
  @include tabletLarge() {
    @include font-size(18.4, 24);
  }
  font-weight: normal;
  position: relative;
  margin: 0;
  margin-bottom: 0;

  a {
    @include font-size(16);
    color: $color-link;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: $color-blue;
    }
  }

  .media-browser & {
    @include font-size(16, 24);
  }
}