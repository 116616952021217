//sass-lint:disable class-name-format
.tag {
  @include font-size(12, 0);
  @include rfb(padding, .15, .4375, .1875); //=> SVE: changed this for better padding was rfb(padding, .1875, .4375, .25)
  @include rfb(margin-right, .5);
  font-weight: 500;
  line-height: 1;
  position: relative;
  top: -1px; //=> SVE: added this for better positioning
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  color: $color-white;
  border-radius: 2px;
  background-color: $color-grey-600;
  box-shadow: 0 0 0 1px transparentize($color-indigo, .85) inset;
}

.tag-inverse {
  color: $color-grey-600;
  background-color: $color-white;
}

.tag-neutral,
.tag-white {
  @extend .tag;
  color: $color-blue;
  background-color: $color-white;
  box-shadow: 0 0 0 1px $color-blue inset;
}

.tag-certified {
  @extend .tag;
  // text-transform: uppercase; Why?
  background-color: $light-color-primary;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-assignment {
  @extend .tag;
  text-transform: uppercase;
  background-color: $color-turquoise;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-service {
  @extend .tag;
  text-transform: uppercase;
  background-color: $color-blue;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-succes {
  @extend .tag;
  background-color: $color-green-600;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-info {
  @extend .tag;
  background-color: $color-blue-900;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-warning {
  @extend .tag;
  background-color: $color-warning;
  // &:hover {
  //   color: $color-white;
  // }
}

.tag-danger {
  @extend .tag;
  background-color: $color-red-700;
  // &:hover {
  //   color: $color-white;
  // }
}


.tag-warning-inverse {
  @extend .tag;
  color: $color-red-700;
  background-color: $color-white;
  box-shadow: 0 0 0 1px $color-red-700 inset;
}

.tag-pdf {
  @extend .tag;
  background-color: $color-orange;
}

.tag-3 {
  @extend .tag;
  background-color: $color-red-700;
}

.tag-2 {
  @extend .tag;
  background-color: $color-orange;
}

.tag-1 {
  @extend .tag;
  background-color: $color-yellow;
}

.tag-multiple {
  @extend .tag;
  background-color: $color-grey-600;
}

.tag-ola {
  @extend .tag;
  background-color: $color-green-600;
}

.tag-js {
  @extend .tag;
  font-size: .5rem;
  margin-right: 0;
  background-color: $color-blue-900;
}

/**
 * Range and specific Projectinfo stamps
 */
.stamp-IN_AANVRAAG {
  @extend .tag;
  background-color: $color-blue-900;
}

.stamp-GOEDGEKEURD {
  @extend .tag;
  background-color: $color-green-a500;
}

.stamp-GECONTRACTEERD {
  @extend .tag;
  background-color: $color-yellow;
}

.stamp-DVL_GESTART {
  @extend .tag;
  background-color: $color-warning;
}

.stamp-DVL_GESTOPT {
  @extend .tag;
  background-color: $color-grey-600;
}

.stamp-AFGESLOTEN {
  @extend .tag;
  background-color: $color-grey-700;
}

.stamp-GEANNULEERD {
  @extend .tag;
  background-color: $color-red-300;
}

.stamp-GEWEIGERD {
  @extend .tag;
  background-color: $color-red-700;
}

.stamp-selectall {
  @extend .tag;
  color: $color-black;
  background-color: $color-white;
}

// stamps on projectonderdelen in Gantt chart
.block-display-gantt-chart {

  .stamp-IN_AANVRAAG,
  .stamp-GOEDGEKEURD,
  .stamp-GECONTRACTEERD,
  .stamp-DVL_GESTART,
  .stamp-DVL_GESTOPT,
  .stamp-AFGESLOTEN,
  .stamp-GEANNULEERD,
  .stamp-GEWEIGERD,
  .stamp-selectall {
    @include rfb(margin-left, 1);
    @include rfb(margin-top, -.1);
    @include font-size(8, 0);
    display: block;
  }
}