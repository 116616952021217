/**
 * Overkill mixin, don't touch or the world might explode!
 */

$input-border: $color-grey-300;
$input-hover-border: $color-grey-600;
$shadow: transparentize($color-grey-a500, .85);//transformed

@mixin search-mixin($button-background, $button-background-hover, $input-background, $button-text, $search-height, $border-size, $radius) {
  @include clearfix();

  .form-input {
    @include font-size(14);
    @include rfb(padding, .5, 0);
    font-family: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
    font-weight: 200;
    display: inline;
    width: 0;
    height: $search-height;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-transition: all .25s cubic-bezier(.7, 0, .3, 1);
    -moz-transition: all .25s cubic-bezier(.7, 0, .3, 1);
    -o-transition: all .25s cubic-bezier(.7, 0, .3, 1);
    transition: all .25s cubic-bezier(.7, 0, .3, 1);
    border: 0 none;
    background: $input-background;

    &.focused {
      @include rfb(padding, 0, .5);
      @include rfb(margin-right, .5);
      width: 10rem;
      border: $border-size solid $input-border;

      @media (min-width: $screen-small) {
        width: 10rem;
      }
    }
  }

  .form-submit {
    display: none;
  }
}
/* consulent zoekbalk
-----------------------*/
@mixin application-search-mixin($button-background, $button-background-hover, $input-background, $button-text, $search-width, $search-height, $border-size, $radius) {
  @include clearfix();
  width: $search-width;

  .form-input {
    @include rfb(padding, 0, 1);
    font-family: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
    font-weight: 200;
    display: block;
    float: left;
    width: $search-width - ($search-height);
    height: $search-height;
    border: $border-size solid $input-border;
    border-radius: $radius 0 0 $radius;
    background: $input-background;

    &:hover {
      border: $border-size solid $input-hover-border;
      box-shadow: $shadow 0 0 2px 1px;
    }
  }

  .form-submit {
    display: block;
    width: $search-height;
    height: $search-height;
    transition: all .1s;
    color: $button-text;
    border: 0;
    border-radius: 0 $radius $radius 0;
    background: $button-background;

    &:hover {
      background: $button-background-hover;
    }

    &::before {
      font-family: 'icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      display: inline-block;
      content: char-from-code($icon-search);
      vertical-align: middle;
      text-decoration: inherit;
      text-transform: none;

      speak: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

@mixin regular-search-mixin($button-background, $button-background-hover, $input-background, $button-text, $search-width, $search-height, $border-size, $radius) {
  @include clearfix();
  width: ($search-width / 1.75);

  @media (min-width: $tablet-large) {
    width: ($search-width / 1.5);
  }

  @media (min-width: $screen-small) {
    width: $search-width;
  }

  .form-input {
    @include rfb(padding, 0, 1);
    font-family: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
    font-weight: 200;
    display: block;
    float: left;
    width: $search-width - ($search-height);
    height: $search-height;
    border: $border-size solid $input-border;
    border-radius: $radius 0 0 $radius;
    background: $input-background;

    @media (min-width: $tablet-large) {
      width: ($search-width / 1.5) - ($search-height);
    }

    @media (min-width: $screen-small) {
      width: $search-width - ($search-height);
    }

    &:hover {
      border: $border-size solid $input-hover-border;
      box-shadow: $shadow 0 0 2px 1px;
    }
  }

  .form-submit {
    display: block;
    width: $search-height;
    height: $search-height;
    transition: all .1s;
    color: $button-text;
    border: 0;
    border-radius: 0 $radius $radius 0;
    background: $button-background;

    &:hover {
      background: $button-background-hover;
    }

    &::before {
      font-family: 'icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      display: inline-block;
      content: char-from-code($icon-search);
      vertical-align: middle;
      text-decoration: inherit;
      text-transform: none;

      speak: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
