@mixin flexbox($flex: flex) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: $flex;
}

@mixin flex($values) {
  flex: $values;

  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
}

@mixin flex-direction($direction) {
  flex-direction: $direction;

  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  flex-wrap: $wrap;

  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
  flex-flow: $flow;
  -webkit-flex-flow: $flow;
  -ms-flex-flow: $flow;
}

@mixin justify-content($justify) {
  justify-content: $justify;

  -webkit-justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex-basis($basis) {
  -webkit-flex-basis: $basis;
  flex-basis: $basis;
}

@mixin flex-order($order) {
  -webkit-order: $order;
  order: $order;
}