// Deprecated? Test and see where this is used? aka is something broken 10/07/2017
//09/08 turns out is used for burger version off big slide down menu in mijn loopbaan
//24/02/2019 the old collapse/collapsing dropdown has been removed (v 3.6.1) since the last place where it was used has been updated (VDAB burger mijn-loopbaan dropdown)

// The spanking new CSS only huisstijl slideDown from our design pair programming team JJ-Cool and LV-Ray!!! 
// *THOUSAND ROUNDS OF THUNDEROUS EAR-DEAFENING APPLAUSE*
// Add hs-slide-down and collapsed to the closed element, toggle the collapsed class.

/*18/10 sitdown sesh : so what we know so far is that we need the max-height to have a functional slide down BUT this has 2 downsides
a) when there is a bigger element you have a problem because not everything is shown and you need to overrule this
b) there is a slight delay on small components (like the regular menu dropdown) when closing it (because the animation start from the bottom which would be the max-height en thus too far down for a small element)
for now we can use this but in the future we mightt need to make a component from this with JS
*/

/*24/02/2019 update: there is now a new animation hs-slide-over that doesnt have the former problem and can be used in a multitude of situations instead of the flawed one ( see further down) */

.hs-slide-down {
  position: relative;
  overflow: hidden;
  max-height: 500px; //we NEED this
  transition: all .5s ease-in-out;

  &.collapsed {
    max-height: 0; 
    margin: 0 !important; 
    padding: 0 !important;
    //fix for use as accordion with a panel (the padding from the panel-bpdy interferes with max-height:0 and thus has no overflow to hide)
    &.panel-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  
  //fix for usage with existing (but jquery dependent) dropdown : overrule display none as starting position
  &.dropdown-menu {
    display: block;
  }
  
  ul {
    input[type=radio] {
      margin-left: .1rem;
    }
  }
}

/*
* This animation uses a different format: it triggers no reflow so it shouldn't be used when the dom needs to readjust,* but it does work with an auto height instead of hardcoding maxheights
* hence the name: it slides *over* other dom elements instead of repositioning them
*/

.hs-slide-over {
  position: absolute;
  overflow: hidden;
  transition: transform .15s ease-in-out;
  transform: scaleY(1);
  transform-origin: top;

  &.collapsed {
    transform: scaleY(0);
  }
}