$color-steps: $color-primary;
$color-steps-hover: $light-color-primary;
$color-arrow: $form-container-bg;
$arrow-curve: 30px;
$arrow-width: 1px;
$steps-padding-left: 55px;
$previous: $color-primary;
$current: $color-orange; //#C80043
$next: $color-blue-300;

@mixin steps-mixin($color, $text-color: '$white') {
  color: $text-color;
  background-color: $color;
  &::after {
    border-left-color: $color;
  }
}

#orientering {
  .steps li {
    .previous, .current, .next {
      &:hover {
        cursor: default;
      }
    }
  }
}

.steps {
  display: table;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.steps li {
  display: table-cell;
  width: 5%;
}

.steps li a {
  position: relative;
  display: block;
  padding: 10px 0;
  padding-left: $steps-padding-left;
  text-decoration: none;
  color: $color-white;
  outline: 0;
  background: $color-steps;
}

.steps li a::after {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  margin-top: -50px;
  content: ' ';
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: $arrow-curve solid $color-arrow;
}

.steps li a::before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: $arrow-width;
  content: ' ';
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: $arrow-curve solid $color-arrow;
}

.steps li:first-child a {
  padding-left: $steps-padding-left - $arrow-curve;
  border-top-left-radius: 5px;
}

.steps li:last-child a {
  padding-right: $arrow-curve;
  border-top-right-radius: 5px;
}

.steps li .previous {
  @include steps-mixin($previous);
  white-space: nowrap;

  &:hover {
    cursor: default;
  }
}


.steps li .current {
  @include steps-mixin($current);
  white-space: nowrap;

  &:hover {
    cursor: default;
  }
}

.steps li .next {
  @include steps-mixin($next);
  white-space: nowrap;

  &:hover {
    cursor: default;
  }
}

.steps-bottom-nav {
  @extend .clearfix;
  @include rfb(padding-top, 3);

  .nav-back {
    float: left;
    width: 33.33333333333%;
    text-align: left;
  }

  .nav-result {
    float: left;
    width: 33.33333333333%;
    text-align: center;
  }

  .nav-go {
    float: left;
    width: 33.33333333333%;
    text-align: right;
  }
}
