// Colors
$border-default: 1px solid $transparent-black-10;
$default-text-color: $color-grey-900;
$default-bg-color: #f8f8f8; //sass-lint:disable-line no-color-hex

$color-primary: $color-blue;
$color-primary-light: rgba($color-blue-300, .25);
//$color-secondary: #77bc50;
$color-primary-muted: lighten($color-blue, 15%);//#69aad9;
$color-link: $color-blue-a500;
$color-visited: #796694; //sass-lint:disable-line no-color-hex
$color-muted: $color-grey-700;
$color-default: $color-grey-900;
$color-success: $color-green-600;
$color-info: lighten($color-blue-a500, 30%);//#b8dbf9;
$color-warning: $color-orange-a500;
$color-danger: $color-red-700;
$color-dark: $color-indigo;


$form-container-bg: $color-blue-100;

$light-color-primary: lighten($color-primary, 10%);
$light-color-dark: lighten($color-dark, 10%);

//navbar-colors
$nav-light-grey: lighten($color-grey-900, 15%);
$nav-lighter-grey: $color-grey-300;
$nav-border: $nav-lighter-grey;
$nav-dark-blue: $color-indigo;

// Default Styling

// Transition
$transition-default: cubic-bezier(.17, .67, .83, .67);

// Typography
$brand-font-regular: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
$font-size-base: 16px;
$font-size-h1: floor(($font-size-base * 2.5)); // ~40px
$font-size-h2: floor(($font-size-base * 1.75)); // ~28px
$font-size-h3: ceil(($font-size-base * 1.45)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * .85)); // ~12px
$font-path: '../fonts/';

$line-height-base: 1.428571429;
$line-height-computed: floor(($font-size-base * $line-height-base));

$headings-color: $color-grey-a500;
$headings-font-family: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
$headings-font-weight: normal;
$headings-line-height: 1.1;
