//
// PROGRESS
// ------------------------------------------------
//

.progress {
  @include rfb(height, 1);
  overflow: hidden;
  border-radius: 2px;
  background-color: $color-grey-200;
  box-shadow: inset 0 1px 1px $transparent-black-10;
}

.progress-bar {
  @include font-size(12, 12);
  float: left;
  width: 0;
  height: 100%;
  transition: (width .6s ease);
  text-align: center;
  color: $color-white;
  background-color: $color-blue-a500;
  box-shadow: inset 0 -1px 0 $transparent-black-15;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, $transparent-white-15 25%, transparent 25%, transparent 50%, $transparent-white-15 50%, $transparent-white-15 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: $color-green;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, $transparent-white-15 25%, transparent 25%, transparent 50%, $transparent-white-15 50%, $transparent-white-15 75%, transparent 75%, transparent);
}

//sass-lint:disable-all
.progress-bar-info {
  background-color: #5bc0de;
}
.progress-bar-warning {
  background-color: #f0ad4e;
}
.progress-bar-danger {
  background-color: #d9534f;
}
// sass-lint:enable-all

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, $transparent-white-15 25%, transparent 25%, transparent 50%, $transparent-white-15 50%, $transparent-white-15 75%, transparent 75%, transparent);
}


.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, $transparent-white-15 25%, transparent 25%, transparent 50%, $transparent-white-15 50%, $transparent-white-15 75%, transparent 75%, transparent);
}


.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, $transparent-white-15 25%, transparent 25%, transparent 50%, $transparent-white-15 50%, $transparent-white-15 75%, transparent 75%, transparent);
}

.progress-bar-neutral {
  background-color: $color-grey;
}



/* progress bar with steps that looks like a subway (which is still better than a battery Carl */

.progress-steps {
  display: flex;
  width: 100%;
  height: 1em;
  margin: 0;

  align-items: center;

  & .-step {
    max-width: 1em;
    height: 1em;
    border: 2px solid $color-grey-100;
    border-radius: 50%;
    background-color: transparentize($color-black, .85);

    flex-grow: 1;

    &.-current {
      background-color: $color-blue;
      box-shadow: 0 0 0 2px $color-blue;
    }
  }

  & .-bar {
    height: .07em;
    background-color: transparentize($color-black, .85);

    flex-grow: 1;
  }

  & .-done {
    background-color: $color-blue;
  }
}

/* end progress steps bar*/