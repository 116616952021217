// **
//  * A mixin which helps you to add depth to elements according to the Google Material Design spec:
//  * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
//  *
//  * Please note that the values given in the specification cannot be used as is. To create the same visual experience
//  * the blur parameter has to be doubled.
//  *
//  * Adapted from a LESS version at https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
//  *
//  * Original Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
//  *
//  * Example usage:
//  *
//  * .card {
//  *     width: 95px;
//  *     height: 95px;
//  *     background: #f4f4f4;
//  *     -webkit-transition: all 250ms;
//  *     -moz-transition: all 250ms;
//  *     transition: all 250ms;
//  *     @include box_shadow(1);
//  *     &:hover {
//  *         @include box_shadow(3);
//  *         -webkit-transform: translateY(-5px);
//  *         -moz-transform: translateY(-5px);
//  *         transform: translateY(-5px);
//  *     }
//  * }
//  *
//  */

@mixin box_shadow ($level) {
  @if $level==1 {
    box-shadow: 0 1px 3px transparentize($color: $color-black, $amount: .88), 0 1px 2px transparentize($color: $color-black, $amount: .76);
  } @else if $level==2 {
    box-shadow: 0 3px 6px transparentize($color: $color-black, $amount: .84), 0 3px 6px transparentize($color: $color-black, $amount: .77);
  } @else if $level==3 {
    box-shadow: 0 10px 20px transparentize($color: $color-black, $amount: .81), 0 6px 6px transparentize($color: $color-black, $amount: .77);
  } @else if $level==4 {
    box-shadow: 0 14px 28px transparentize($color: $color-black, $amount: .75), 0 10px 10px transparentize($color: $color-black, $amount: .78);
  } @else if $level==5 {
    box-shadow: 0 19px 38px transparentize($color: $color-black, $amount: .7), 0 15px 12px transparentize($color: $color-black, $amount: .78);
  } @else if $level==6 {
    box-shadow: 0 3px 10px transparentize($color: $color-black, $amount: .9);
  }
}

.shadow-05 {
  box-shadow: 0 3px 10px transparentize($color: $color-black, $amount: .9);
}
.shadow-1 {
  box-shadow: 0 1px 3px transparentize($color: $color-black, $amount: .88), 0 1px 2px transparentize($color: $color-black, $amount: .76);
}
.shadow-2 {
  box-shadow: 0 3px 6px transparentize($color: $color-black, $amount: .84), 0 3px 6px transparentize($color: $color-black, $amount: .77);
}
.shadow-3 {
  box-shadow: 0 10px 20px transparentize($color: $color-black, $amount: .81), 0 6px 6px transparentize($color: $color-black, $amount: .77);
}
.shadow-4 {
  box-shadow: 0 14px 28px transparentize($color: $color-black, $amount: .75), 0 10px 10px transparentize($color: $color-black, $amount: .78);
}
.shadow-5 {
  box-shadow: 0 19px 38px transparentize($color: $color-black, $amount: .7), 0 15px 12px transparentize($color: $color-black, $amount: .78);
}