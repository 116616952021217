@import '../variables/colors_new';

$temp-blue: $color-blue;
$temp-light-blue: $color-blue-300;
$temp-lighter-blue: $color-blue-100; //#e5edf8
$temp-dark-blue: $color-indigo;
$temp-grey-blue: $color-grey-800;
$temp-light-grey: $color-grey-300;
$temp-very-light-grey: $color-grey-200;
$temp-grey: $color-grey-700;
$temp-mid-grey: $color-grey-300;
$temp-dark-grey: $color-grey-900;
$temp-orange: $color-orange;
$temp-white: $color-white;
$temp-black: $color-black;

@media print {
  * {
    color: $temp-black;
    background-color: $temp-white;
    box-shadow: none;
    text-shadow: none;
  }

  .subnavbar-application {
    display: none;
  }

  .no-print {
    display: none;
  }

  .flanders-header {
    display: none;
  }

  .navbar {
    display: none;
  }

  .footer-container {
    display: none;
  }

  .flanders-footer {
    display: none;
  }

  .head-action {
    display: none;
  }

  img {
    display: none;
  }

  @page {
    margin: .5cm;
  }

  body {
    font: 10pt;
    color: $temp-black !important;
  }

  html {
    font-size: 10pt;
    color: $temp-black !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 14pt;
    color: $temp-black !important;
  }

  .block-display-head h2 {
    font-size: 10pt;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: $temp-black !important;
  }

  label {
    color: $temp-black !important;
  }

  label::after {
    content: ' :';
  }

  .block-display-head, .block-display-content, .block-display-table {
    border-color: $temp-light-grey !important;
    border-radius: 0 !important;
  }

  a {
    color: $temp-black !important;
  }

  .block-display-action, .block-dispaly-meta {
    display: none;
  }

  tr:nth-of-type(even) {
    background-color: $temp-black !important;
  }

  thead {
    border-bottom: 1px solid $temp-light-grey;
    background-color: $temp-black !important;

    th {
      font-weight: 500 !important;
      color: $temp-black !important;
    }
  }

  tr {
    border-bottom: 1px solid $temp-light-grey !important;
  }

  tr:last-child {
    border-bottom: 0 !important;
  }

  .icon-vdab {
    color: $temp-black !important;
  }

  .action-go, .action-back {
    display: none;
  }

  .tooltip-help {
    display: none;
  }

  form {
    display: none;

    &.form-print {
      display: block !important;
    }
  }

  button, .button, .lone-button {
    display: none;
  }

  .table-lines td {
    border: 0 !important;
  }

  .btn-bar, .button-bar {
    display: none;
  }

  .lone-cell {
    display: none;
  }

  th:empty {
    display: none;
  }

  td:empty {
    display: none;
  }

  .stamp, .stamp-danger, .stamp-pdf {
    color: $temp-grey;
    border: 0 !important;
    border-radius: 0 !important;
    background: none;
    box-shadow: none !important;
  }

  th a.table-sortable-settings::after, .table-sortable-sorted-up a::after, .table-sortable-sorted-down a::after {
    display: none !important;
  }

  .show-more {
    display: none;
  }

  .panel-top-actions {
    display: none;
  }

  .action-edit, .action-menu, .controledienst-settings {
    display: none;
  }

  .inline-editable-value a:after {
    content: none !important;
  }

  // VEO print behaviors
  .veo-actions, .block--web-social-block-social-sharing, .veo-subnav, .veo-subnav-mobile {
    display: none;
  }

  // Experimental for panels because they are not defined yet
  .panel, .title-panel, .panel-group {
    border: 0 !important;
  }

  .read-label {
    margin-top: 10px !important;
    margin-bottom: 10px;
  }

  // xeditable.css overule for CONTROLEDOSSIER
  .editable-click, a.editable-click {
    border-bottom: 0;
  }

  .editable-empty, .editable-empty:hover, .editable-empty:focus, a.editable-empty, a.editable-empty:hover, a.editable-empty:focus {
    font-style: normal;
    text-decoration: none;
    color: $color-black;
  }

  // Opleidingengids
  .text-fade {
    height: auto;
    &:before {
      background: none;
    }
    .text-fade-button {
      display: none;
    }
  }

  // Competent
  .competent {
    .sidebar, .revision-history, .revision-compare {
      display: none;
    }
  }

  // Remove maps from print
  map {
    display: none;
  }

  // Remove navbars from print
  .newnav-flanders, .newnav-navbar, .newnav-footer {
    display: none;
  }

  // Remove links from printable vacatures in vind een job
  .panel.panel-accordion.-panel-custom-banner, nav.pagination-centered, #vej-save-action {
    display: none;
  }

  // ********REMINDER, ADD NEW PRINT CSS HERE OR IT WILL NOT WORK, DUMDUM!****************
}