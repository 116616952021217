/* classes used for different coloring of the mainnavbar depending on environment (as asked by IVI-604)*/
//sass-lint:disable-all

.rel1ldv, .mlb1ldv {
  background-color: #e7298a; //sass-lint:disable-line no-color-hex 
}

.rel1abt, .mlb1abt {
  background-color: #c39e03; //sass-lint:disable-line no-color-hex 
}

.rel1cbt {
  background-color: #beaed4; //sass-lint:disable-line no-color-hex 
}

.rel1trn {
  background-color: #7fc97f; //sass-lint:disable-line no-color-hex 
}





