//
// MODAL
// is this used anywhere??
// ------------------------------------------------

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  overflow-y: scroll;
  outline: 0;
  background-color: transparent;

  -webkit-overflow-scrolling: touch; //sass-lint:disable-line no-misspelled-properties

  &.fade .modal-dialog {
    transition: (transform .3s $transition-default);
    transform: (translate(0, -25%));
  }

  &.in .modal-dialog {
    transform: (translate(0, 0));
  }
}

.modal-dialog {
  @include rfb(margin, .5);
  @include tabletLarge() {
    @include rfb(width, 37.5); // 600px
    @include rfb(margin, 3.125, auto); // 50px
  }
  position: relative;
  width: auto;

}

.modal-content {
  position: relative;
  border-radius: 2px;
  outline: none;
  background-color: $color-white;
  background-clip: padding-box;
}

.modal-header {
  @include rfb(padding, 1);
  @include tabletLarge() {
    @include rfb(padding, 1.5, 1.5, 1.5, 1.5);
  }
  border-bottom: $border-default;
  border-radius: 2px 2px 0 0;
  background-color: $color-grey-300;

  .close {
    @extend .icon;
    @extend .icon-close;
    @include rfb(padding, 1.5, 1.5, 1.5, 1.5);
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    overflow: hidden;
    width: 4em;
    color: $color-black;

    &:before {
      margin-right: 3em;
    }

    &:hover {
      color: $color-blue-a500;
    }

    span {
      display: inline-block;
      overflow: hidden;
      text-indent: -9000px;
    }
  }
}

.modal-body {
  @include rfb(padding, 1, 1, 0, 1);
  @include tabletLarge() {
    @include rfb(padding, 1.5, 1.5, .5, 1.5);
  }
  position: relative;
}

.modal-footer {
  @include rfb(padding, 1, 1, 0, 1);
  @include tabletLarge() {
    @include rfb(padding, 1.5, 1.5, 1.5, 1.5);
  }
  border-top: $border-default;
}

.modal-title {
  @include font-size(16, 24);
  font-weight: normal;
  margin: 0;
  color: $color-grey-a500;
}

.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-grey-a500;

  &.fade {
    opacity: 0;

    filter: alpha(opacity=0);
  }

  &.in {
    opacity: .5;

    filter: alpha(opacity=50);
  }
}

.modal-content, .show-site-search .site-search-holder {
  box-shadow: 0 0 25px transparentize($color-grey-a500, .7);
}

// temporary modal styles ProjectInfo
.big-dialog .modal-dialog {
  width: 70%;
  min-width: 70%;
}
