$navbar-background: $color-primary;
$navbar-background-hover: $color-primary-light;
$navbar-link: $color-white;
$navbar-height: 86px;
$navbar-mobile-height: 56px;
$navbar-font-size: 18;
$navbar-application-height: 50px;
$navbar-application-mobile-height: 50px;
$navbar-application-font-size: 14;
$navbar-arrow-size: 8px;

@mixin align-vertically() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin navbar-css($mixin-navbar-height, $mixin-navbar-mobile-height, $mixin-padding-lr, $mixin-font-size) {
  @include font-size($mixin-font-size);
  position: relative;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: $mixin-navbar-mobile-height;
  padding: 0;
  background-color: $navbar-background;

  @media (min-width: $tablet-large) {
    height: $mixin-navbar-height;
  }

  /* .navbar-wrapper
  -----------------------*/
  .navbar-wrapper {
    @include rfb(margin, 0, auto);
    @include clearfix();
    position: relative;
    max-width: $container;
    margin-right: auto;
    margin-left: auto;
  }

  /* .navbar-right
  -----------------------*/
  .navbar-right {
    @include clearfix();
    @include rfb(padding, 0, 1);
    z-index: 1;
    display: block;
    float: right;

    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
  }

  /* .username
  -----------------------*/
  .username {
    display: none;
    color: $navbar-link;

    @media (min-width: $tablet-large) {
      display: inline;
    }

  }

  .icon::before {
    color: $navbar-link;
  }

  /* .navbar-item-text
  -----------------------*/
  .navbar-item-text {
    display: none;
    color: $color-white;

    &::before {
      margin-right: .5rem;
      color: $navbar-link;
    }

    @media (min-width: $screen-small) {
      display: inline;
    }
  }

  /* .navbar-search
  -----------------------*/
  .navbar-search {
    @include rfb(margin, auto, 0);
    display: none;
    float: left;
    height: $mixin-navbar-mobile-height;
    border-right: 1px solid $navbar-background-hover;
    border-left: 1px solid $navbar-background-hover;

    &:hover {
      cursor: pointer;
      background: $navbar-background-hover;
    }

    @media (min-width: $tablet-large) {
      display: block;
      height: $mixin-navbar-height;
    }

    .navbar-search-component {
      @include align-vertically();
      line-height: 1;
      display: block;
      padding: .8rem 1.6rem;

      label:hover,
      form:hover {
        cursor: pointer;
      }

      label::before {
        color: $color-white;

        @media (min-width: $tablet-large) {
          margin-right: .5rem;
        }
      }

      @media (min-width: $tablet-large) {
        @include search-mixin($color-white, $color-white, $color-white, $color-default, 36px, 0, 50px);
      }

      @media (min-width: $screen-small) {
        @include search-mixin($color-white, $color-white, $color-white, $color-default, 36px, 0, 50px);
      }
    }
  }

  /* .navbar-search-mobile
  -----------------------*/
  .navbar-search-mobile {
    @include rfb(margin, auto, 0);
    display: block;
    float: left;
    border-right: 1px solid $navbar-background-hover;
    border-left: 1px solid $navbar-background-hover;

    &:hover {
      cursor: pointer;
      background: $navbar-background-hover;
    }

    label:hover,
    form:hover {
      cursor: pointer;
    }

    @media (min-width: $tablet-large) {
      display: none;
    }

    >a {
      @include rfb(padding, 1);
      line-height: 1;
      display: inline-block;

      @media (min-width: $tablet-large) {
        @include rfb(padding, .8, $mixin-padding-lr);
      }
    }

    a::before {
      color: $navbar-link;

      @media (min-width: $tablet-large) {
        @include rfb(margin-right, .5);
      }
    }

    .navbar-search-form {
      display: none;
      float: left;
      margin: -.3rem 1rem -.3rem 0;

      .form-submit {
        display: none;
      }
    }
  }

  /* .navbar-application-search
  -----------------------*/
  .navbar-application-search {
    @include rfb(padding, 0, 1);
    float: right;
    height: $mixin-navbar-mobile-height;
    margin-bottom: 0;

    @media (min-width: $tablet-large) {
      height: $mixin-navbar-height;
    }

    .navbar-application-search-component {
      @include align-vertically();
      display: none;

      @media (min-width: $tablet-large) {
        @include application-search-mixin($color-white, $color-white, $color-white, $color-default, 200px, 30px, 0, 50px);
        display: block;
      }

      input.form-input:focus {
        outline: 0;
        background: $color-yellow-100;
      }

      button.form-submit:focus {
        outline: 0;
        background: $color-yellow-200;
      }
    }
  }

  /* .navbar-info
  -----------------------*/
  .navbar-info {
    line-height: $mixin-navbar-mobile-height;
    display: none;
    float: left;
    height: $mixin-navbar-mobile-height;
    white-space: nowrap;

    @media (min-width: $tablet-large) {
      line-height: $mixin-navbar-height;
      height: $mixin-navbar-height;
    }

    @media (min-width: $screen-small) {
      display: block;
    }

    > a {
      position: relative;
      color: $navbar-link;
    }

    &::before {
      @include rfb(margin-left, .5);
      font-family: 'icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      display: inline-block;
      content: char-from-code($icon-arrow-right);
      vertical-align: middle;
      text-decoration: inherit;
      text-transform: none;
      color: $navbar-link;

      speak: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  /* .navbar-logo
  -----------------------*/
  .navbar-logo {
    @include rfb(padding, 0, 1);
    display: inline-block;
    float: left;
    height: 100%;

    span {
      display: block;
      padding: ($mixin-navbar-mobile-height / 2);
      background-color: transparent;
      background-image: url('//www.vdab.be/sites/web/themes/webresponsive/img/new-vdab-logo-white.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @media (min-width: $tablet-large) {
        padding: ($mixin-navbar-height / 2);
      }
    }
  }

  /* .navbar-items
  -----------------------*/
  .navbar-items {
    line-height: $mixin-navbar-mobile-height;
    z-index: 1;
    display: block;
    float: left;
    height: $mixin-navbar-mobile-height;
    margin-bottom: 0;
    padding: 0;
    text-align: right;
    background: transparent;

    @media (min-width: $tablet-large) {
      line-height: $mixin-navbar-height;
      height: $mixin-navbar-height;
    }
  }

  .navbar-item {
    @include rfb(margin, auto, 0);
    position: relative;
    display: inline-block;
    float: left;
    height: 100%;
    border-left: 1px solid $navbar-background-hover;

    a:not(.dropdown-link) {
      //sigh okay so because this a-tag was using a direct desc selector I have to do this weirdass workaround since the element CAN be inside the necessary wrapper of a dropdown and was therefor no longer targeted. HOWEVER this styling should not be applied to the links inside the dropdown itself and thus this idiotic selector was born
      @include rfb(padding, 1);
      line-height: 1;
      display: inline-block;
      color: $navbar-link;

      @media (min-width: $tablet-large) {
        @include rfb(padding, .8, $mixin-padding-lr);
      }
    }

    a::before {
      color: $navbar-link;

      @media (min-width: $tablet-large) {
        @include rfb(margin-right, .5);
      }
    }

    label::before {
      @media (min-width: $tablet-large) {
        @include rfb(margin-right, .5);
      }
    }

    &:hover {
      background-color: $navbar-background-hover;
    }

    &.active {
      background-color: $navbar-background-hover;
    }

    &.active::after {
      @include triangle($navbar-link, $navbar-arrow-size);
    }

    &:last-child {
      border-right: 1px solid $navbar-background-hover;
    }
  }
}

/* Header .navbar
---------------------------------------------*/
header.navbar {
  @include navbar-css($navbar-height, $navbar-mobile-height, 1.6, $navbar-font-size);

  /* Header .navbar .navbar-application
  ---------------------------------------------*/
  &.navbar-application {
    @include navbar-css($navbar-application-height, $navbar-application-mobile-height, 1, $navbar-application-font-size);
  }
}