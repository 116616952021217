// REM FALLBACK
// - - - - - - - - - - - - - - - - - - - -
// Add CSS properties in rems & pixels on an element.
//
// Examples:
// ---------
// .selector {
//   @include rfb(padding, 3, 1, 2, 4);
// }
//
// .selector {
//   @include rfb(margin-top, 2.75);
// }
//
// .selector {
//   @include rfb(max-width, 10);
//   @include rfb(margin, auto, 2);
// }
//
// .selector {
//   @include rfb(margin, 3, 1, !important);
// }

@mixin rfb($property, $values...) {
  $max: length($values);
  $px-values: '';
  $rem-values: '';
  $important: false;

  @if nth($values, $max) == '!important' {
    $important: true;
    $values: remove($values, '!important');
    $max: $max - 1;
  }

  @for $i from 1 through $max {
    @if type-of(nth($values, $i)) != number {
      $px-value: nth($values, $i);
      $px-values: #{$px-values + $px-value};

      $rem-value: nth($values, $i);
      $rem-values: #{$rem-values + $rem-value};
    } @else {
      $px-value: strip-unit(nth($values, $i));
      $px-values: #{$px-values + $px-value * $font-size-base};
      $rem-value: strip-unit(nth($values, $i));
      $rem-values: #{$rem-values + $rem-value}rem;
    }

    @if $i < $max {
      $px-values: #{$px-values + ' '};
      $rem-values: #{$rem-values + ' '};
    }
  }

  @if $important {
    $px-values: $px-values + ' !important';
    $rem-values: $rem-values + ' !important';
  }
  //sass-lint:disable-block no-duplicate-properties
  #{$property}: $px-values;
  #{$property}: $rem-values;
}
