/**
 * VARIABLES
 */
$tabs-background: $color-primary;
$tabs-background-hover: darken($color-primary, 10%);
$tabs-background-active: $tabs-background-hover;
$tabs-border-color: $transparent-black-15;
$tabs-bottom-border-color: $transparent-black-10;
$tabs-link: $color-white;
$tabs-border-radius: 5px;
$tabs-font-weight: 400;
$tabs-padding-top-bottom: .4em;
$tabs-padding-left-right: 1.8em;
$tabs-top-border-radius: 5px;
$tabs-font-size-dm: 12;
$tabs-top-border-radius-dm: 4px;
$tabs-padding-top-bottom-dm: 0;
$tabs-padding-left-right-dm: .6em;

/**
 * Consulenten
 */
body.dm {
  ul.tabs {
    @include font-size($tabs-font-size-dm);
  }

  ul.tabs a {
    padding: $tabs-padding-top-bottom-dm $tabs-padding-left-right-dm;
    border-top-left-radius: $tabs-top-border-radius-dm;
    border-top-right-radius: $tabs-top-border-radius-dm;
  }
}

@media all and (max-width: $screen-small) {
  body.dm {
    ul.tabs a {
      border-radius: 0;
    }
  }
}

/**
 * Burger
 */
ul.tabs {
  @include rfb(margin-bottom, 1);
  display: table;
  overflow: hidden;
  width: 100%;
  padding: 0;
  table-layout: fixed;
  list-style: none;
  text-align: center;
  border-bottom: 1px solid $tabs-background;
}

ul.tabs > li {
  position: relative;
  float: left;
}

ul.tabs ul {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}

ul.tabs a {
  font-weight: $tabs-font-weight;
  line-height: 30px;
  display: block;
  padding: $tabs-padding-top-bottom $tabs-padding-left-right;
  cursor: pointer;
  text-decoration: none;
  color: $color-white;
  border-right: 1px solid $tabs-border-color;
  border-top-left-radius: $tabs-top-border-radius;
  border-top-right-radius: $tabs-top-border-radius;
  background-color: $tabs-background;

  &:hover {
    background: $tabs-background-hover;
  }
}

ul.tabs li {
  list-style: none;
}

ul.tabs li.active {
  a {
    background: $tabs-background-active;
  }
}

@media all and (max-width: $screen-small) {
  ul.tabs {
    width: 100%;
    height: auto;
    border-bottom: 0;
    border-radius: $tabs-border-radius;
  }

  ul.tabs > li {
    float: none;
    width: 100%;
    box-shadow: none;
  }

  ul.tabs a {
    line-height: 40px;
    padding: $tabs-padding-top-bottom $tabs-padding-left-right;
    border-bottom: 1px solid $tabs-border-color;
    border-radius: 0;
  }

  ul.tabs ul {
    position: relative;
  }
}
