ul.list-meta {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-left: 1em;
    text-indent: .5em;
  }

  li:before {
    padding-right: .75em;
    content: '•';
    color: $color-muted;
  }
}

// For E-ID page
ol.big-list {
  font-size: 1.25rem;
  line-height: 1.7rem;
  margin-bottom: 1.5rem;
  li {
    margin-bottom: .8rem;
  }
}
