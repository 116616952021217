.alert {
  @include rfb(margin-bottom, 1);
  @include rfb(padding, 1, 1);
  position: relative;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 0 1px transparentize($color-indigo, .85) inset;

  &:before {
    @include rfb(top, 1.25);
    @include rfb(left, 1.25);
    position: absolute;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
    color: inherit;

    &:visited {
      color: inherit;
    }

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    font-size: 22px;
    margin-top: 0;
    color: inherit;
  }
}

.alert-success {
  @extend .alert;
  @include icon(status);
  @include rfb(padding, 1, 3);
  color: $color-green-900;
  background: $color-lightgreen-200;
}

.alert-info {
  @extend .alert;
  @include icon(info);
  @include rfb(padding, 1, 3);
  color: darken($color-info, 65%);
  background: $color-info;
}

.alert-warning {
  @extend .alert;
  @include icon(warning);
  @include rfb(padding, 1, 3);
  color: $color-orange-700;
  background: $color-orange-300;
}

.alert-danger {
  @extend .alert;
  @include icon(warning);
  @include rfb(padding, 1, 3);
  color: $color-danger;
  background: $color-red-200;
}

// Error message in form auto adjusts to correct style.
.form-container {
  .alert-success, .alert-info, .alert-warning, .alert-danger {
    margin-top: -1em;
    margin-right: -2.4em;
    margin-left: -2.4em;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.alert-fixed-container {
  @include rfb(top, 1);
  @include rfb(left, 1);
  @include rfb(right, 1);
  @include tabletLarge() {
    @include rfb(top, 3.25);
  }
  @include screen() {
    @include rfb(max-width, 37.5);
    @include rfb(margin-left, -18.75);
    right: auto;
    left: 50%;
    width: 100%;
  }
  position: fixed;
  z-index: 1050;
  border: 0;

  &.wide {
    @include screen() {
      max-width: 960px;
      margin-left: -480px;
    }
  }

  .alert {
    &.in {
      z-index: 1010;
    }

    button.close {
      @include rfb(right, .75);
      position: absolute;
    }
  }
}

.fixed.alert {
  @include rfb(top, 1);
  @include rfb(left, 1);
  @include rfb(right, 1);
  @include tabletLarge() {
    @include rfb(top, 3.25);
  }
  @include screen() {
    @include rfb(max-width, 37.5);
    @include rfb(margin-left, -18.75);
    right: auto;
    left: 50%;
    width: 100%;
  }
  position: fixed;
  z-index: 1050;
  box-shadow: 0 0 0 1px transparentize($color-grey-a500, .075), 0 0 10px 0 transparentize($color-grey-a500, .85);

  &.in {
    z-index: 1010;
  }

  button.close {
    @include rfb(right, .75);
    position: absolute;
  }

  &.wide {
    @include screen() {
      max-width: 960px;
      margin-left: -480px;
    }
  }
}

.message-close {
  @include font-size(10, 0);
  @include rfb(top, .95);
  @include rfb(right, 1.25);
  position: absolute;
  cursor: pointer;
}

// Mijn loopbaan inbox alerts

.inbox-alert {
  @include rfb(padding, 1, 1.5);
  @include rfb(margin-bottom, 1);
  position: relative;
  border-top: 1px solid $transparent-black-10;
  border-right: 1px solid $transparent-black-10;
  border-bottom: 1px solid $transparent-black-10;
  border-left: 2em solid $color-grey-700;
  background: $color-white;
  &:after {
    @extend .icon-after;
    position: absolute;
    top: 1em;
    left: -1.5em;
    content: none;
    color: $color-white;
  }
  h3 {
    @include rfb(margin, 0, 0, .3);
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  p {
    margin: 0;
  }
}

.inbox-task {
  @extend .inbox-alert;
  border-left-color: $color-red;

  a.action-go {
    color: $color-red;
  }

  &:after {
    content: char-from-code($icon-check);
  }

  h3 {
    color: $color-red;
  }
}

.inbox-tip {
  @extend .inbox-alert;
  border-left-color: $color-green-600;

  a.action-go {
    color: $color-green-600;
  }

  &:after {
    content: char-from-code($icon-bulb);
  }
  h3 {
    color: $color-green-600;
  }
  .tip-title {
    font-weight: 500;
  }
}

.inbox-message {
  @extend .inbox-alert;
  border-left-color: $color-blue-a500;

  a.action-go {
    color: $color-blue-a500;
  }

  &:after {
    content: char-from-code($icon-envelope);
  }
  .message-list {
    margin-top: 10px; 
    padding-left: 10px; 
    list-style: none;
    .active-message {
      font-weight: 500;
    }
  }
}

.inbox-vacature {
  @extend .inbox-alert;
  border-left-color: $color-yellow;

  a.action-go {
    color: $color-blue-a500;
  }

  &:after {
    content: char-from-code($icon-star);
  }
  .job-list {
    margin-top: 10px; 
    padding-left: 20px;
  }
}

.inbox-calendar {
  @extend .inbox-alert;
  border-left-color: $color-red;

  a.action-go {
    color: $color-yellow;
  }

  &:after {
    content: char-from-code($icon-date);
  }
  h3 {
    color: $color-red;
  }
  .calender-date {
    font-weight: 500;
  }
}

.inbox-general {
  @extend .inbox-alert;
  border-left-color: $color-grey-300;

  a.action-go {
    color: $color-grey-300;
  }
}

.indication-ok {
  color: $color-green-900;
  background: transparentize($color-lightgreen-400, .5);
  
}

.indication-nok {
  background: $color-red-300;
  // background: rgb(251, 208, 208);
}

.indication-neutral {
  background: transparentize($color-grey-700, .8);
  // background: rgb(145, 151, 161);
}

.success-font-color {
  color: $color-green-900;
}

// fit an alert inside another element
.alert-wide {
  margin-bottom: 0;
  border-radius: 0;
}
