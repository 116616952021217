

/**
 * Triangle that is used on bottom of navbar li
 */
@mixin triangle($triangle-color, $triangle-size) {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -$triangle-size;
  content: '';
  -webkit-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  -moz-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  -o-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  border-right: $triangle-size solid transparent;
  border-bottom: $triangle-size solid $triangle-color;
  border-left: $triangle-size solid transparent;
}

/**
 * Triangle that is used on the left of navbar li
 */
@mixin triangle-right($triangle-color, $triangle-size) {
  position: absolute;
  left: 0;
  content: '';
  -webkit-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  -moz-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  -o-transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  transition: bottom .25s cubic-bezier(.7, 0, .3, 1);
  border-top: $triangle-size solid transparent;
  border-bottom: $triangle-size solid transparent;
  border-left: $triangle-size solid $triangle-color;
}
