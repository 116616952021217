.flanders-header, .flanders-footer {
  @include tabletLarge() {
    @include font-size(14, 36);
    display: block;
    color: $color-grey-700;
    background-color: $color-grey-a500;

    .col {
      padding-right: 0;
      padding-left: 0;
    }
  }
  display: none;
}

.flanders-header {
  @include tabletLarge() {
    font-weight: 600;
    color: $color-grey-700;
  }
  a {
    color: $color-grey-600;
  }
}

.flanders-footer {
  .disclaimer {
    @include rfb(margin-left, 1);
    @include rfb(padding-left, 1);
    border-left: 1px solid $color-grey-a500;
  }

  .language {
    text-align: right;

    a {
      margin-left: 7px;
    }
  }

  a {
    color: $color-white;

    &:hover {
      text-decoration: underline;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

//
// Flanders Header
// --------------------------------------------------
.top-vlaanderen {
  display: block;
  text-decoration: none;
}

.top-vlaanderen-logo {
  @include rfb(height, 2.25);
  @include rfb(width, 2.25);
  position: relative;
  display: block;
  float: left;
  background: $color-grey-600 url('https://www.vdab.be/sites/web/themes/webresponsive/img/vvm-logo-mark-dark.svg') .64688em 50% no-repeat; // @todo 'em'

  &:after {
    @include rfb(border-width, 2.25, 0, 0, .8125);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: '';
    border-style: solid;
    border-color: transparent;
    border-top-color: $color-grey-a500;
  }
}
