// Helpwidget
.help-widget-closed {
  font-weight: 500;
  position: fixed;
  z-index: 9999999;
  right: 0;
  bottom: 10rem;
  padding: 1rem 1.5rem;
  color: $color-white;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  background: $color-turquoise-500;
  -webkit-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
  -moz-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
  box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
  &:hover {
    color: $color-white;
    background: $color-turquoise-a500;
  }
  &:visited {
    color: $color-white;
  }
}

.help-widget-open {
  position: fixed;
  z-index: 9999999;
  right: 0;
  bottom: 10rem;
  width: 20rem;
  .help-widget-title {
    font-weight: 500;
    display: block;
    padding: 1rem 3rem;
    color: $color-white;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background: $color-turquoise-500;
    -webkit-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
    -moz-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
    box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
    &:hover {
      color: $color-white;
      background: $color-turquoise-a500;
    }
    &:after {
      @extend .icon-after;
      position: absolute;
      top: 1.25rem;
      right: 1.5rem;
      content: char-from-code($icon-close);
      color: $color-white;
    }
  }
  ul.help-widget-content {
    margin-bottom: 0;
    margin-left: 1.5rem;
    padding: 1rem 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background: $color-white;
    -webkit-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
    -moz-box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
    box-shadow: -5px 5px 10px -3px transparentize($color-black, .8);
  }
  li {
    font-weight: 500;
    position: relative;
    display: block;
    padding-bottom: 1rem;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: color 0 ease-in;
    transition: none;
    color: $color-black;
    background: $color-white;
    &.-bottom-line {
      margin-bottom: 1rem;
      border-bottom: 1px solid $color-grey-300;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom-left-radius: 1.5rem;
    }
    &.-chat-icon:after {
      @extend .icon-after;
      position: absolute;
      top: .25rem;
      right: 0;
      content: char-from-code($icon-chat);
      color: $color-turquoise-500;
    }
    &.-tel-icon:after {
      @extend .icon-after;
      position: absolute;
      top: .25rem;
      right: 0;
      content: char-from-code($icon-phone);
      color: $color-turquoise-500;
    }
    &.-mail-icon:after {
      @extend .icon-after;
      position: absolute;
      top: .25rem;
      right: 0;
      content: char-from-code($icon-mail);
      color: $color-turquoise-500;
    }
    &.-location-icon:after {
      @extend .icon-after;
      position: absolute;
      top: .25rem;
      right: 0;
      content: char-from-code($icon-marker);
      color: $color-turquoise-500;
    }
    .-infoline {
      font-size: .9rem;
      font-weight: 400;
      display: block;
      margin-top: -.2rem;
      color: $color-grey-700;
    }
    a {
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: color 0 ease-in;
      transition: none;
      color: $color-black;
      &:hover {
        color: $color-blue;
        .-infoline {
          color: $color-blue;
        }
      }
    }
  }
}

// make helpwidget smaller when screen size is smaller than container
@media (max-width: 1430px) {
  .help-widget-closed {
    padding: .5rem .75rem;
    &:after {
      padding-left: .5rem;
      content: '?';
      color: $color-white;
    }
    span {
      position: absolute;  
      overflow: hidden;  
      clip: rect(0 0 0 0);  
      width: 1px;  
      height: 1px;  
      margin: -1px;  
      padding: 0;  
      border: 0;
    }
  }
}


@media (max-width: $screen-small) {
  .help-widget-open {
    bottom: 0;
    width: 90%;
    border-bottom-left-radius: 0;
    ul.help-widget-content {
      padding-bottom: 1.5rem;
      border-bottom-left-radius: 0;
    }
  }
}