.accordion-text {
  display: inline-block;
  overflow: hidden;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accordion-collapse-button {
  @extend .action-more;
  float: right;
}

.accordion-collapse-button-open {
  @extend .action-less;
  float: right;
}