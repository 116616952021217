// Table styling

table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate !important; // to avoid weird border rendering bug in webkit browsers

  .action-lock {
    color: $transparent-black-20;
  }

  &.table-border-bottom {
    border-bottom: 1px solid $transparent-black-05;
  }

  &.table-row-hover {
    tr:hover {
      cursor: pointer;
      background-color: $color-blue-200;
    }
  }
  .row-passive {
    opacity: .5;
  }

}


td {
  position: relative;
  border-top: 1px solid $transparent-black-05;
}

th {
  font-weight: 500;
  color: $color-grey-700;
}

th, td {
  @include rfb(padding, .3, 0, .3, 0);
  font-weight: normal;
  padding: 8px;
  padding-left: 0;
  text-align: left;
}

tr.table-inject td.table-infopanel {
  @include rfb(padding, 1);
  background: $color-grey-100;
}

th.col-small {
  width: .1em;
  white-space: nowrap;
}

thead.table-sortable tr > th.col-small {
  min-width: 0;
}

th.col-medium {
  width: 10rem;
  white-space: nowrap;
}

// to overwrite standard vertical center align
td.align-baseline {
  vertical-align: baseline;
}
th.align-baseline {
  vertical-align: baseline;
}

// For all icon collumns.
.lone-cell, .checkbox-cell {
  width: 3.5em;
}

.slider-cell {
  width: 14em;
}

thead.table-sortable {
  tr th {
    min-width: 100px;
    white-space: nowrap;

    a:after {
      @extend .icon-after;
      padding-left: .3em;
      // Need different icon for this!
      content: char-from-code($icon-arrow-down);
      vertical-align: middle;
      color: $transparent-black-15;
    }

    a:hover:after {
      @extend .icon-after;
      padding-left: .3em;
      content: char-from-code($icon-arrow-down);
      vertical-align: middle;
      color: $color-blue;
    }
  }

  th.table-sortable-sorted-down {
    min-width: 100px;

    a:after {
      @extend .icon-after;
      padding-left: .3em;
      content: char-from-code($icon-arrow-down);
      vertical-align: middle;
      color: $color-blue;
    }

    a:hover:after {
      content: char-from-code($icon-arrow-up);
    }
  }

  th.table-sortable-sorted-up {
    min-width: 100px;

    a:after {
      @extend .icon-after;
      padding-left: .3em;
      content: char-from-code($icon-arrow-up);
      vertical-align: middle;
      color: $color-blue;
    }
  }

  th:last-child {
    min-width: 116px;
  }

  th a.table-sortable-settings:after {
    @extend .icon-after;
    position: absolute;
    right: 1rem;
    display: none;
    padding-top: .3em;
    content: char-from-code($icon-cog);
    vertical-align: middle;
    color: $color-blue;
  }

  th a.table-sortable-settings:hover:after {
    @extend .icon-after;
    position: absolute;
    right: 1rem;
    padding-top: .3em;
    content: char-from-code($icon-cog);
    vertical-align: middle;
    color: $color-blue;
  }

  th a.table-sortable-settings:last-child:after {
    display: inline;
  }
}

.table-lines {
  table {
    border-collapse: separate !important; // to avoid weird border rendering bug in webkit browsers
  }

  th, td {
    padding: 8px;
    padding-left: 0;
    text-align: left;

    // a block inside a table
    &.table-block {
      @include rfb(padding-left, 1);
      border: 1px solid $transparent-black-05;
      border-bottom: 0;
      background: $color-grey-100;

      &.last-td {
        border-bottom: 1px solid $transparent-black-05;
      }
    }
  }

  td {
    border-top: 1px solid $transparent-black-10;
  }
}

.table-sortable-settings-form {
  height: auto;
}

/*specific for a table in which you interate over tbody's (instead of tr's) because you need the accordion effect per tr
* this will fix the color scheme for tr's inside tbody's
*/
table.accordion-table tbody:nth-of-type(2n) tr {
  background-color: transparentize($color-blue, .95);
}

.block-display {
  .block-display-table {
    border: 1px solid $transparent-black-10;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    background-color: $color-white;

    th.table-actions:last-child {
      min-width: 40px;
    }

    .vertical-align-middle td {
      vertical-align: middle;
    }
    
    td.table-action {
      @include rfb(padding-right, .3);
      width: 30px;
      min-width: 20px;
      max-width: 80px;

      &:last-child {
        @include rfb(padding-right, .7);
        padding-left: 0;
      }
    }

    &.table-row-hover {
      table tr:hover, table tr:nth-of-type(even):hover {
        background-color: transparentize($color-blue, .85);
      }
      table thead tr:hover {
        background-color: $transparent-black-00;
      }
    }
    // Same as hover but more clickable
    &.table-row-clickable {
      table tr:hover {
        cursor: pointer;
        color: $color-blue-a500;
        background-color: transparentize($color-blue, .85);
      }

      table thead tr:hover {
        background-color: $transparent-black-00;
      }
    }

    .action-lock {
      color: $transparent-black-20;
    }

    div.action-search {
      color: $transparent-black-20;
    }

    .icon-vdab {
      vertical-align: middle;
      color: $color-blue;
    }

    table {
      border: 0;

      thead {
        background: transparentize($color-blue, .85);

        th {
          @include rfb(padding, .5, 1);
          @include font-size($font-size-h5);
          font-weight: normal;
          color: $transparent-black-50;
        }
      }

      tr {
        border: 0;
      }

      tr:last-child {
        border: 0;
      }

      td {
        @include rfb(padding, .5, 1);
        position: relative;
        vertical-align: top;
        border: 0;
      }

      td:last-child {
        border: 0;
      }

      /* Zebra striping */
      tr:nth-of-type(even) {
        background-color: transparentize($color-blue, .95);
      }
    }

    table.table-row-hover {
      tr:hover {
        cursor: pointer;
        background-color: $color-blue-200;
      }
    }
  }
  .table-fixed-width {

    th, td {
      min-width: auto;
      white-space: nowrap;
    }

    @for $i from 1 through 10 {
      &.col-#{$i} td:nth-of-type(#{$i}) {
        width: 100%;
        white-space: initial;
      }

      &.col-#{$i}-normal td:nth-of-type(#{$i}) {
        white-space: normal;
      }

      &.col-#{$i}-25 td:nth-of-type(#{$i}) {
        width: 25%;
      }

      &.col-#{$i}-50 td:nth-of-type(#{$i}) {
        width: 50%;
      }

      &.col-#{$i}-75 td:nth-of-type(#{$i}) {
        width: 75%;
      }
    }

    .fixed-width {
      width: 100%;
      white-space: initial;
    }
  }
}



@media screen and (max-width: $screen-small) {
  // Class that makes tables responsive
  .table-responsive {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 1px solid $transparent-black-10;
    }

    td.non-vital {
      display: none;
    }

    td {
      padding-left: 10px;
      text-align: left;
      white-space: normal;
      /* Behave  like a 'row' */
      border: 0;
    }

    td:before {
      font-weight: normal;
      display: block;
      content: attr(data-title);
      text-align: left;
      /* Now like a table header */
      white-space: nowrap;
      color: $transparent-black-50;
    }
  }

  // Responsive
  .block-display {
    .block-display-table {
      table {
        td {
          position: relative;
          padding-left: 10px;
          text-align: left;
          white-space: normal;
          /* Behave  like a 'row' */
          border: 0;
          border-bottom: 1px solid $transparent-black-05;
        }

        td:before {
          font-weight: normal;
          display: block;
          text-align: left;
          /* Now like a table header */
          white-space: nowrap;
          color: $transparent-black-50;
        }

        tr {
          border-bottom: 1px solid $transparent-black-10;
        }

        tr:last-child {
          border-bottom: 1px solid $transparent-black-10;
        }
      }
    }
  }
}

.form-container {
  table {
    border-collapse: separate !important; // to avoid weird border rendering bug in webkit browsers
  }

  th, td {
    font-weight: normal;
    padding: 8px;
    padding-left: 0;
    text-align: left;
  }

  th {
    color: $color-grey-700;
  }

  td {
    border-top: 1px solid $transparent-black-05;
  }

  .table-border-bottom table {
    border-bottom: 1px solid $transparent-black-05;
  }
}

/* table-row to inject eg settings */
tr.table-inject td {
  padding: 0;

  .form-container {
    margin-bottom: 0;
    border-top: 0;
    border-bottom: 0;
  }
}

.table-group-actions {
  margin-top: .5em;
}

// For values in tables.
table td.table-value, table th.table-value {
  @include rfb(padding-right, 2);
  text-align: right;
}

table td.text-right, table th.text-right {
  @include rfb(padding-right, 2);
  text-align: right;
}

//for icons in tables (ex delete edit etc)
table td.table-center, table th.table-center {
  @include rfb(padding-right, 2);
  text-align: center;
}

tr.table-row-disabled {
  color: $color-muted !important;
  background-color: $color-grey-300 !important;

  &:hover {
    color: $color-muted !important;
    background-color: $color-grey-300 !important;
  }
}

// Special case for Toewijsingen / Partners screen in projectinfo
.block-display-content td.table-actions {
  width: 4rem;
}
