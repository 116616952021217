.svg-color-set-1 {
  path#fill-light {
    fill: $color-blue-300;
  }
  path#fill-dark {
    fill: $color-blue;
  }
}
.svg-color-set-2 {
  path#fill-light {
    fill: $color-indigo-300;
  }
  path#fill-dark {
    fill: $color-indigo;
  }
}

.svg-color-set-3 {
  path#fill-light {
    fill: $color-turquoise-300;
  }
  path#fill-dark {
    fill: $color-turquoise;
  }
}

.svg-image-invert {
  background-color: $color-blue;
  &.svg-color-set-1 {
    path#fill-light {
      fill: $color-blue-300;
    }
    path#fill-dark {
      fill: $color-white;
    }
    path#fill-black {
      fill: $color-white;
    }
    path#image-outline {
      fill: $color-blue;
    }
  }
}