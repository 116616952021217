.spinner {
  @include rfb(width, 1.375); // 22px
  @include rfb(height, 1.375); // 22px
  display: inline-block;
  animation: spin .5s infinite linear;
  background-image: url('../img/loading-dark.png');
  background-size: 22px;
}

.hs-modal .alert {
  background-color: $color-white;
}

.hs-modal .alert .spinner {
  margin-bottom: -6px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}