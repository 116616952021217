$footer-partners-title: $color-default;
$footer-background: $nav-lighter-grey;
$footer-bottom-background: $color-grey-300;
$footer-border: $color-primary;
$footer-text: $color-default;
$footer-primary: $color-primary;
$footer-light: $color-white;
$footer-dark: $color-dark;
$footer-link: $transparent-black-50;
$footer-link-hover: $color-default;

/* STICK TO BOTTOM
-----------------------------------*/
html {
  box-sizing: border-box;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

/* MAIN LAYOUT
---------------------------------------------------------------------*/
.footer {
  a {
    color: $footer-link;
  }

  a:hover {
    color: $footer-link-hover;
  }

  &.footer-application {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: $footer-background;
  }
}

/* FOOTER-TOP
-----------------------------------*/
.footer-top {
  @extend %clear;
}

/* FOOTER-MIDDLE
-----------------------------------*/
.footer-middle {
  @include rfb(padding-top, 4);
  border-top: 3px solid $footer-border;
  background: $footer-background;
}

/* FOOTER-BOTTOM
-----------------------------------*/
.footer-bottom {
  min-height: 100px;
  text-align: center;
  background: $footer-bottom-background;
  @media (max-width: $screen-small) {
    display: none;
  }
}

/* COMPONENTS
---------------------------------------------------------------------*/
/* FOOTER-CONTACT
-----------------------------------*/
.footer-contact {
  @include rfb(padding, .6, 1.2);
  float: left;
  width: auto;
  color: $footer-light;
  border-radius: (5px, 5px, 0, 0);
  background: $footer-primary;

  a {
    @include rfb(padding, .3, .5);
    color: $footer-light;
    border-radius: 5px;
    background: $footer-dark;
  }

  a:hover {
    color: $footer-dark;
    background: $footer-light;
  }
  @media (max-width: $screen-small) {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0;
  }
}

/* FOOTER-BUTTONS
-----------------------------------*/
.footer-buttons {
  @include rfb(margin-bottom, 0);
  float: right;
  width: auto;

  a {
    @include rfb(padding, .6, 1.2);
    @include rfb(margin-left, 1);
    @include rfb(margin-bottom, 0);
    display: inline-block;
    color: $footer-light;
    border-radius: (5px, 5px, 0, 0);
    background: $footer-dark;
    @media (max-width: $screen-small) {
      width: 100%;
      margin: 0 0 1rem 0;
      text-align: center;
    }
  }

  a:hover {
    color: $footer-light;
    background: $footer-primary;
  }

  @media (max-width: $screen-small) {
    float: none;
  }
}

/* FOOTER-NAV
-----------------------------------*/
.footer-nav {
  @include rfb(padding, 0, 2, 2, 0);
  //width: 14%;
  float: left;

  ul {
    @include rfb(padding, 0);
    @include rfb(margin, 0);
    list-style: none;

    li {
      @include rfb(padding, .2, 0, .2, 0);
      @include rfb(margin, 0);
      list-style: none;
      &.icon-social-facebook:before, &.icon-social-twitter:before, &.icon-social-linkedin:before, &.icon-social-googleplus:before, &.icon-youtube:before {
        margin-right: .8rem;
      }
    }
  }

  h2 {
    @include rfb(padding-bottom, .3);
    font-size: $font-size-base;
    font-weight: 500;
  }

}

/* FOOTER-DOORMAT
-----------------------------------*/
.footer-doormat {
  //@include rfb(margin, 3);
  //float: right;
  // width: 250px;
  // height: 250px;
  // background-image: url('../img/footer-doormat.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  text-align: center;
  img {
    @include rfb(margin-bottom, 1);
    max-width: 14rem;
    max-height: 14rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}

.footer-doormat-application {
  @include rfb(margin, 1, 1, 1, 0);
  float: left;
  height: auto;
  text-align: left;
  background: none;
}

.footer-doormat-circle {
  @include rfb(margin, 3, 6, 0, 0);
  float: right;
  width: 335px;
  height: 300px;
  border-radius: 50%;
  background-image: url('https://www.vdab.be/sites/web/files/foto/ervaringwerkt.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

/* FOOTER-SEARCH
-----------------------------------*/
.footer-search {
  @extend %clear;
  width: 100%;

  .footer-most-searched {
    @include rfb(padding-top, .4);
  }

  @media (max-width: $screen-small) {
    .search-component {
      width: 100%;
    }
    input[type='text'] {
      width: 87%;
    }
  }
}

/* FOOTER-PARTNERS-TITLE
-----------------------------------*/
.footer-partners-title {
  @include rfb(padding-top, .4);
  font-size: 80%;
  text-align: center;
  text-transform: uppercase;
  color: $footer-partners-title;
}
