// NEW bar chart for Projectinfo
.bar-chart-pi {
  .bar-chart {
    padding: .75rem 1rem;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
      background: $transparent-black-05;
      .bar-chart-title {
        color: $color-blue;
      }
      .bar-chart-title > span.action-warning {
        opacity: 1;
      }
    }
    &.not-clickable {
      .bar-chart-title {
        color: $transparent-black-50;
      }
      &:hover {
        cursor: auto;
        background: $transparent-black-00;
        .bar-chart-title > span.action-warning {
          opacity: .75;
        }
        .bar-chart-title {
          color: $transparent-black-50;
        }
      }
    }
    .bar-chart-title {
      margin-bottom: 1.5rem;
      color: $color-blue-a500;
      border-bottom: 1px solid $transparent-black-10;
      span.tag {
        margin-left: .5rem;
        opacity: .75;
      }
      span.action-warning:before {
        color: $color-white;
      }
    }
    .scheduled {
      width: 100%;
      height: 1rem;
      color: $color-grey-900;
      span.sched-1, span.sched-2, span.sched-3 {
        font-size: .375rem;
        line-height: .5rem;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        height: 1rem;
        color: $color-white;
      }
      .sched-1 {
        background: none;
      }
      .sched-2 {
        position: relative;
        border-right: 2px solid $transparent-black-30;
        //sass-lint:disable-block no-duplicate-properties
        background: $transparent-white-00; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, $transparent-white-00 , $color-green); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(right, $transparent-white-00, $color-green); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(right, $transparent-white-00, $color-green); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to right, $transparent-white-00 , $color-green); /* Standard syntax */
        span {
          font-size: .75rem;
          font-weight: bold;
          position: absolute;
          overflow: visible;
          margin-top: -.9rem;
          white-space: nowrap; 
          color: $color-green;
        }
        &:after {
          @extend .icon-after;
          font-size: .6rem;
          position: absolute;
          right: -.3rem;
          bottom: -.6rem;
          content: char-from-code($icon-arrow-menu);
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          color: $transparent-black-80;
        }
        &:before {
          @extend .icon;
          @extend .icon-arrow-menu;
          font-size: .6rem;
          position: absolute;
          bottom: -.6rem;
          left: -.3rem;
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          color: $transparent-black-30;
        }
      }
      .sched-3 {
        background: $color-orange-700;
      }
    }
    .barchart {
      width: 100%;
      height: 2rem;
      color: $color-grey-900;
      span {
        font-size: .75rem;
        line-height: 1rem;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        height: 2rem;
        color: $color-white;
      }
      .bar-1 {
        border-right: 1px dashed $transparent-white-75;
        background: $color-blue;
        span {
          overflow: hidden;
          width: 100%;
          padding: .5rem 1rem;
          white-space: nowrap; 
          text-overflow: ellipsis;
        }
      }
      .bar-2 {
        background: $color-blue;
        //sass-lint:disable-block no-duplicate-properties
        background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-00 5px, $transparent-white-00 10px);
        background-image: -moz-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-00 5px, $transparent-white-00 10px);
        background-image: -webkit-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-00 5px, $transparent-white-00 10px);
        background-image: -o-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-00 5px, $transparent-white-00 10px);
        background-image: -ms-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-00 5px, $transparent-white-00 10px);
        span {
          overflow: hidden;
          width: 100%;
          padding: .5rem 1rem;
          white-space: nowrap; 
          text-overflow: ellipsis;
        }
        &.bar-empty:after {
          content: none;
        }
      }
      .bar-3 {
        position: absolute;
        width: 0%;
        padding: .5rem 1rem;
        white-space: nowrap;
        color: $transparent-black-30;
      }
    }
    .barchart-timeline {
      font-size: .75rem;
      margin-top: .5rem;
      border-top: 1px solid $transparent-black-30;
    }
  }
  .planned {
    width: 100%;
    height: 1rem;
    margin-bottom: 1rem;
    color: $color-grey-900;
    span.plan-1, span.plan-2, span.plan-3 {
      font-size: .375rem;
      line-height: .5rem;
      display: inline-block;
      float: left;
      box-sizing: border-box;
      height: 1rem;
      color: $color-white;
    }
    .plan-1 {
      background: $transparent-black-05;
    }
    .plan-2 {
      position: relative;
      border-right: 2px solid $transparent-black-50;
      border-left: 1px solid $transparent-black-05;
      //sass-lint:disable-block no-duplicate-properties
      background: $transparent-black-05; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(left, $transparent-black-05 , $transparent-black-20); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(right, $transparent-black-05, $transparent-black-20); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(right, $transparent-black-05, $transparent-black-20); /* For Firefox 3.6 to 15 */
      background: linear-gradient(to right, $transparent-black-05 , $transparent-black-20); /* Standard syntax */
      &:after {
        @extend .icon-after;
        font-size: .6rem;
        position: absolute;
        right: -.3rem;
        bottom: -.75rem;
        content: char-from-code($icon-arrow-up-bold);
        color: $transparent-black-50;
      }
      span {
        font-size: .75rem;
        font-weight: bold;
        position: absolute;
        right: .4rem;
        overflow: visible;
        margin-top: 1.2rem;
        white-space: nowrap; 
        color: $transparent-black-50;
      }
    }
    .plan-3 {
      position: relative;
      border: 2px dashed $transparent-black-15;
      border-left: 0;
      span {
        font-size: .65rem;
        font-weight: normal;
        position: absolute;
        right: 0;
        overflow: visible;
        margin-top: 1.2rem;
        white-space: nowrap; 
        color: $transparent-black-40;
      }
    }
  }
}