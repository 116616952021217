.hs-scroll-back {
  @extend .round-btn;
  @extend .gamma;
  @extend .icon;
  @extend .icon-arrow-up-bold;
  position: fixed;
  z-index: 10;
  top: 5vh;
  left: 45vw;
  width: 40px;
  height: 40px;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  border-radius: 20px;
  &:before {
    font-size: 1.7rem;
  }
}

@media (min-width: $tablet-large) {
  .hs-scroll-back {
    display: none;
  }

}