.icon-presentation {
  
  div {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin: 4px;
    padding-top: 11px;
    text-align: center;
    border: 1px solid $color-grey;
    border-radius: 3px;
  }

  a {
    color: $color-grey-a500;
  }

  a:visited {
    color: $color-grey-a500;
  }
}

.icon-error-after:after {
  content: char-from-code($icon-error);
}
