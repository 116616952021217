//$susy: (
//  container: 1300px,              // <length> | auto
//  columns: 12,                    // <number> | <list>
//  gutters: 32px/79px,             // <ratio>  -- 79px columns, 32px gutters
//  column-width: false,            // <length> | false/null
//  gutter-position: after,         // before | after | split | inside | inside-static
//  global-box-sizing: border-box,  // border-box | content-box
//  math: fluid,                    // fluid | static
//
//  // Debug settings
//  debug: (
//    image: hide,                  // show | hide | show-columns | show-baseline
//    color: rgba(#c33, .25),    // <color>
//    output: background,           // background | overlay
//    toggle: bottom left,          // right | left and top | bottom
//  ),
//
//  // Add custom settings here
//  use-custom: (
//    background-image: true,       // true | false
//    background-options: false,    // true | false
//    box-sizing: true,             // true | false
//    clearfix: false,              // true | false
//    rem: true,                    // true | false
//  )
//);

//@include border-box-sizing();

/*
* The $container variable belows replaces the susy container mixin.
* With Susy, you specify a container element for your grid elements by using the container() mixin. The selector that includes that mixin will then be assigned the max-width from the container value in $susy, centered with left and right margins set to auto, and given a clearfix.
*/
$container: 1300px;

// RESPONSIVE
$tablet-sidebar-columns: 4; // 4 out of 12
$screen-sidebar-columns: 3; // 3 out of 12

$tablet-small: 30em; // 480px
$tablet-large: 42.5em; // 680px
$screen-small: 60em; // 960px
$screen-medium: 72em; // 1152px
$screen-large: 81.25em; // 1300px

// Tablet + Desktop
@mixin tablet() {
  @include breakpoint($tablet-small) {
    @content;
  }
}

// Tablet Large + Desktop
@mixin tabletLarge() {
  @include breakpoint($tablet-large) {
    @content;
  }
}

// Mobile + Tablet
@mixin tabletMax() {
  @include breakpoint($screen-small - (1 / 16) max-width) {
    @content;
  }
}

// Mobile Only
@mixin mobile() {
  @include breakpoint($tablet-large - (1 / 16) max-width) {
    @content;
  }
}

// Tablet Only
@mixin tabletOnly() {
  @include breakpoint($tablet-small ($screen-small - (1 / 16))) {
    @content;
  }
}

// Tablet Large Only
@mixin tabletLargeOnly() {
  @include breakpoint($tablet-large ($screen-small - (1 / 16))) {
    @content;
  }
}

// Desktop Only
@mixin screen() {
  @include breakpoint($screen-small) {
    @content;
  }
}

// Desktop Medium
@mixin screenMedium() {
  @include breakpoint($screen-medium) {
    @content;
  }
}

// Desktop Large
@mixin screenLarge() {
  @include breakpoint($screen-large) {
    @content;
  }
}
