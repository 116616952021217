.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-menu {
    @include flexbox();
    @include flex-direction(column);
    font-size: 14px;
    //position: absolute; //this is determined by the animation style
    z-index: 9;
    top: 100%;
    left: 0;
    min-width: 160px;
    margin: 2px 0 0;
    padding: .25rem 0;
    list-style: none;
    cursor: default; //the negate the effects of clickable topelements like a button
    text-align: left;
    border: 1px solid $color-grey-500;
    background-color: $color-white;
    background-clip: padding-box;
    box-shadow: transparentize($color-black, .75) 0 2px 4px 0;

    // Dropdown for menu aligned to right side
    &.right-align {
      right: 0;
      left: auto;
    }

    &.collapsed {
      padding: 0;
      border: 0;
    }

    li {
      &.-active {
        @include flex-order(-1);
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: .5rem;
      }

      a {
        line-height: 1.42857143;
        display: block;
        padding: .25rem 1rem;
        white-space: nowrap;
        color: $default-text-color;

        &:hover, &:focus {
          text-decoration: none;
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }

    .divider {
      overflow: hidden;
      height: 1px;
      margin: .25rem 1rem;
      background-color: $color-grey-300;
    }
  }
}


