// This file is generated by Gulp 
@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype');
		/* url('../fonts/icons.svg#icons') format('svg'); */
}

$icon-after: E001;
$icon-arrow-down-small: E002;
$icon-arrow-down: E003;
$icon-arrow-left-long: E004;
$icon-arrow-left: E005;
$icon-arrow-right: E006;
$icon-arrow-up: E007;
$icon-arrow: E008;
$icon-attachment: E009;
$icon-bulb: E00A;
$icon-chat: E00B;
$icon-check: E00C;
$icon-circle-add-outline: E00D;
$icon-circle-add: E00E;
$icon-close: E00F;
$icon-cog: E010;
$icon-color-pallete: E011;
$icon-comments: E012;
$icon-compare: E013;
$icon-copy-doc: E014;
$icon-copy: E015;
$icon-cross: E016;
$icon-cycle: E017;
$icon-date: E018;
$icon-edit: E019;
$icon-envelope: E01A;
$icon-error: E01B;
$icon-explore: E01C;
$icon-export: E01D;
$icon-extlink: E01E;
$icon-feed: E01F;
$icon-file: E020;
$icon-forward: E021;
$icon-group-add: E022;
$icon-group: E023;
$icon-help: E024;
$icon-home: E025;
$icon-info: E026;
$icon-link: E027;
$icon-list: E028;
$icon-lock-open: E029;
$icon-lock: E02A;
$icon-mail: E02B;
$icon-marker: E02C;
$icon-menu: E02D;
$icon-mobile-menu: E02E;
$icon-multitick-checked: E02F;
$icon-multitick: E030;
$icon-phone: E031;
$icon-plus: E032;
$icon-printer: E033;
$icon-radio: E034;
$icon-reload: E035;
$icon-reply: E036;
$icon-rss: E037;
$icon-search: E038;
$icon-settings: E039;
$icon-smartphone: E03A;
$icon-social-facebook: E03B;
$icon-social-googleplus: E03C;
$icon-social-linkedin: E03D;
$icon-social-twitter: E03E;
$icon-star: E03F;
$icon-status: E040;
$icon-time: E041;
$icon-user: E042;
$icon-vdab: E043;
$icon-warning: E044;
$icon-x: E045;
$icon-youtube: E046;
$icon-eye: E047;
$icon-study: E048;
$icon-certified: E049;
$icon-checkbox: E04A;
$icon-arrow-up-bold: E04B;
$icon-calendar: E04C;
$icon-comment: E04D;
$icon-education: E04E;
$icon-job: E04F;
$icon-knipperlicht: E050;
$icon-opdracht: E051;
$icon-registreer: E052;
$icon-startpakket: E053;
$icon-seal: E054;
$icon-checkbox-checked: E055;
$icon-history: E056;
$icon-bell-outline: E057;
$icon-shield-star: E058;
$icon-handshake: E059;
$icon-two-arrows-right-left: E05A;
$icon-road-variant: E05B;
$icon-book-open-page-variant: E05C;
$icon-knipperlicht-medium: E05D;
$icon-knipperlicht-low: E05E;
$icon-monitor-person: E05F;
$icon-subdirectory-arrow-right: E060;
$icon-view-module: E061;
$icon-view-stream: E062;
$icon-car: E063;
$icon-train: E064;
$icon-bike: E065;
$icon-ans-tractor: E066;
$icon-mouse-pointer-hand: E067;
$icon-house-person: E068;
$icon-screwdriver: E069;
$icon-flowers: E06A;
$icon-note-text-pencil: E06B;
$icon-trash: E06C;
$icon-arrow-menu: E06D;
$icon-eye-strike: E06E;
$icon-smallstar: E06F;
$icon-flag: E070;
$icon-flag2: E071;
$icon-exit: E072;
$icon-expand-down-small: E073;
$icon-expand-up-small: E074;
$icon-thumb-down: E075;
$icon-thumb-up: E076;
$icon-info-small: E077;
$icon-barcode-scan: E078;
$icon-region: E079;
$icon-threshold: E07A;
$icon-fa-calculator: E07B;
$icon-flash-circle: E07C;
$icon-sign: E07D;
$icon-delete-sign: E07E;
$icon-filter: E07F;
$icon-task: E080;
$icon-messages: E081;
$icon-tip: E082;
$icon-fa-suitcase-light: E083;
$icon-fa-info-circle-light: E084;
$icon-fa-coins-light: E085;
$icon-fa-compass-light: E086;
$icon-fa-wrench-light: E087;
$icon-fa-minus-circle: E088;
$icon-fu-calendar-pencil: E089;
$icon-mdi-phone-outgoing: E08A;
$icon-mdi-phone-incoming: E08B;
$icon-mdi-account-check: E08C;
$icon-mdi-account-minus: E08D;
$icon-mdi-account-remove: E08E;
$icon-mdi-email-alert: E08F;
$icon-mdi-calendar-remove: E090;
$icon-fu-envelope-closed-clock: E091;
$icon-fu-envelope-closed-cross-mark: E092;
$icon-mdi-playlist-edit: E093;
$icon-mdi-account-question: E094;
$icon-filter-remove: E095;
$icon-fu-calendar-check-mark-02: E096;
$icon-filter-full-remove: E097;
$icon-ellipsis: E098;
$icon-mdi-calendar-alert: E099;
$icon-fa-hands-helping: E09A;
$icon-fa-arrow-to-right: E09B;
$icon-mdi-instagram: E09C;
$icon-fa-folder-open: E09D;
$icon-mdi-account-off: E09E;
$icon-group-user: E09F;
$icon-videochat: E0A0;
$icon-save-alt: E0A1;
$icon-itsme: E0A2;
$icon-fa-image-regular: E0A3;
$icon-co-browse: E0A4;
$icon-gender: E0A5;
$icon-birthday: E0A6;
$icon-web: E0A7;
$icon-gender-male: E0A8;
$icon-gender-female: E0A9;
$icon-mdi-phone-declined: E0AA;

// Bootstrap Overrides
.icon:before,
[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"icons";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

@function char-from-code($iso-char) {
  @return unquote('\"\\#{$iso-char}\"');
}

// Icons
.icon-after:before {
	content: char-from-code($icon-after);
}
.icon-arrow-down-small:before {
	content: char-from-code($icon-arrow-down-small);
}
.icon-arrow-down:before {
	content: char-from-code($icon-arrow-down);
}
.icon-arrow-left-long:before {
	content: char-from-code($icon-arrow-left-long);
}
.icon-arrow-left:before {
	content: char-from-code($icon-arrow-left);
}
.icon-arrow-right:before {
	content: char-from-code($icon-arrow-right);
}
.icon-arrow-up:before {
	content: char-from-code($icon-arrow-up);
}
.icon-arrow:before {
	content: char-from-code($icon-arrow);
}
.icon-attachment:before {
	content: char-from-code($icon-attachment);
}
.icon-bulb:before {
	content: char-from-code($icon-bulb);
}
.icon-chat:before {
	content: char-from-code($icon-chat);
}
.icon-check:before {
	content: char-from-code($icon-check);
}
.icon-circle-add-outline:before {
	content: char-from-code($icon-circle-add-outline);
}
.icon-circle-add:before {
	content: char-from-code($icon-circle-add);
}
.icon-close:before {
	content: char-from-code($icon-close);
}
.icon-cog:before {
	content: char-from-code($icon-cog);
}
.icon-color-pallete:before {
	content: char-from-code($icon-color-pallete);
}
.icon-comments:before {
	content: char-from-code($icon-comments);
}
.icon-compare:before {
	content: char-from-code($icon-compare);
}
.icon-copy-doc:before {
	content: char-from-code($icon-copy-doc);
}
.icon-copy:before {
	content: char-from-code($icon-copy);
}
.icon-cross:before {
	content: char-from-code($icon-cross);
}
.icon-cycle:before {
	content: char-from-code($icon-cycle);
}
.icon-date:before {
	content: char-from-code($icon-date);
}
.icon-edit:before {
	content: char-from-code($icon-edit);
}
.icon-envelope:before {
	content: char-from-code($icon-envelope);
}
.icon-error:before {
	content: char-from-code($icon-error);
}
.icon-explore:before {
	content: char-from-code($icon-explore);
}
.icon-export:before {
	content: char-from-code($icon-export);
}
.icon-extlink:before {
	content: char-from-code($icon-extlink);
}
.icon-feed:before {
	content: char-from-code($icon-feed);
}
.icon-file:before {
	content: char-from-code($icon-file);
}
.icon-forward:before {
	content: char-from-code($icon-forward);
}
.icon-group-add:before {
	content: char-from-code($icon-group-add);
}
.icon-group:before {
	content: char-from-code($icon-group);
}
.icon-help:before {
	content: char-from-code($icon-help);
}
.icon-home:before {
	content: char-from-code($icon-home);
}
.icon-info:before {
	content: char-from-code($icon-info);
}
.icon-link:before {
	content: char-from-code($icon-link);
}
.icon-list:before {
	content: char-from-code($icon-list);
}
.icon-lock-open:before {
	content: char-from-code($icon-lock-open);
}
.icon-lock:before {
	content: char-from-code($icon-lock);
}
.icon-mail:before {
	content: char-from-code($icon-mail);
}
.icon-marker:before {
	content: char-from-code($icon-marker);
}
.icon-menu:before {
	content: char-from-code($icon-menu);
}
.icon-mobile-menu:before {
	content: char-from-code($icon-mobile-menu);
}
.icon-multitick-checked:before {
	content: char-from-code($icon-multitick-checked);
}
.icon-multitick:before {
	content: char-from-code($icon-multitick);
}
.icon-phone:before {
	content: char-from-code($icon-phone);
}
.icon-plus:before {
	content: char-from-code($icon-plus);
}
.icon-printer:before {
	content: char-from-code($icon-printer);
}
.icon-radio:before {
	content: char-from-code($icon-radio);
}
.icon-reload:before {
	content: char-from-code($icon-reload);
}
.icon-reply:before {
	content: char-from-code($icon-reply);
}
.icon-rss:before {
	content: char-from-code($icon-rss);
}
.icon-search:before {
	content: char-from-code($icon-search);
}
.icon-settings:before {
	content: char-from-code($icon-settings);
}
.icon-smartphone:before {
	content: char-from-code($icon-smartphone);
}
.icon-social-facebook:before {
	content: char-from-code($icon-social-facebook);
}
.icon-social-googleplus:before {
	content: char-from-code($icon-social-googleplus);
}
.icon-social-linkedin:before {
	content: char-from-code($icon-social-linkedin);
}
.icon-social-twitter:before {
	content: char-from-code($icon-social-twitter);
}
.icon-star:before {
	content: char-from-code($icon-star);
}
.icon-status:before {
	content: char-from-code($icon-status);
}
.icon-time:before {
	content: char-from-code($icon-time);
}
.icon-user:before {
	content: char-from-code($icon-user);
}
.icon-vdab:before {
	content: char-from-code($icon-vdab);
}
.icon-warning:before {
	content: char-from-code($icon-warning);
}
.icon-x:before {
	content: char-from-code($icon-x);
}
.icon-youtube:before {
	content: char-from-code($icon-youtube);
}
.icon-eye:before {
	content: char-from-code($icon-eye);
}
.icon-study:before {
	content: char-from-code($icon-study);
}
.icon-certified:before {
	content: char-from-code($icon-certified);
}
.icon-checkbox:before {
	content: char-from-code($icon-checkbox);
}
.icon-arrow-up-bold:before {
	content: char-from-code($icon-arrow-up-bold);
}
.icon-calendar:before {
	content: char-from-code($icon-calendar);
}
.icon-comment:before {
	content: char-from-code($icon-comment);
}
.icon-education:before {
	content: char-from-code($icon-education);
}
.icon-job:before {
	content: char-from-code($icon-job);
}
.icon-knipperlicht:before {
	content: char-from-code($icon-knipperlicht);
}
.icon-opdracht:before {
	content: char-from-code($icon-opdracht);
}
.icon-registreer:before {
	content: char-from-code($icon-registreer);
}
.icon-startpakket:before {
	content: char-from-code($icon-startpakket);
}
.icon-seal:before {
	content: char-from-code($icon-seal);
}
.icon-checkbox-checked:before {
	content: char-from-code($icon-checkbox-checked);
}
.icon-history:before {
	content: char-from-code($icon-history);
}
.icon-bell-outline:before {
	content: char-from-code($icon-bell-outline);
}
.icon-shield-star:before {
	content: char-from-code($icon-shield-star);
}
.icon-handshake:before {
	content: char-from-code($icon-handshake);
}
.icon-two-arrows-right-left:before {
	content: char-from-code($icon-two-arrows-right-left);
}
.icon-road-variant:before {
	content: char-from-code($icon-road-variant);
}
.icon-book-open-page-variant:before {
	content: char-from-code($icon-book-open-page-variant);
}
.icon-knipperlicht-medium:before {
	content: char-from-code($icon-knipperlicht-medium);
}
.icon-knipperlicht-low:before {
	content: char-from-code($icon-knipperlicht-low);
}
.icon-monitor-person:before {
	content: char-from-code($icon-monitor-person);
}
.icon-subdirectory-arrow-right:before {
	content: char-from-code($icon-subdirectory-arrow-right);
}
.icon-view-module:before {
	content: char-from-code($icon-view-module);
}
.icon-view-stream:before {
	content: char-from-code($icon-view-stream);
}
.icon-car:before {
	content: char-from-code($icon-car);
}
.icon-train:before {
	content: char-from-code($icon-train);
}
.icon-bike:before {
	content: char-from-code($icon-bike);
}
.icon-ans-tractor:before {
	content: char-from-code($icon-ans-tractor);
}
.icon-mouse-pointer-hand:before {
	content: char-from-code($icon-mouse-pointer-hand);
}
.icon-house-person:before {
	content: char-from-code($icon-house-person);
}
.icon-screwdriver:before {
	content: char-from-code($icon-screwdriver);
}
.icon-flowers:before {
	content: char-from-code($icon-flowers);
}
.icon-note-text-pencil:before {
	content: char-from-code($icon-note-text-pencil);
}
.icon-trash:before {
	content: char-from-code($icon-trash);
}
.icon-arrow-menu:before {
	content: char-from-code($icon-arrow-menu);
}
.icon-eye-strike:before {
	content: char-from-code($icon-eye-strike);
}
.icon-smallstar:before {
	content: char-from-code($icon-smallstar);
}
.icon-flag:before {
	content: char-from-code($icon-flag);
}
.icon-flag2:before {
	content: char-from-code($icon-flag2);
}
.icon-exit:before {
	content: char-from-code($icon-exit);
}
.icon-expand-down-small:before {
	content: char-from-code($icon-expand-down-small);
}
.icon-expand-up-small:before {
	content: char-from-code($icon-expand-up-small);
}
.icon-thumb-down:before {
	content: char-from-code($icon-thumb-down);
}
.icon-thumb-up:before {
	content: char-from-code($icon-thumb-up);
}
.icon-info-small:before {
	content: char-from-code($icon-info-small);
}
.icon-barcode-scan:before {
	content: char-from-code($icon-barcode-scan);
}
.icon-region:before {
	content: char-from-code($icon-region);
}
.icon-threshold:before {
	content: char-from-code($icon-threshold);
}
.icon-fa-calculator:before {
	content: char-from-code($icon-fa-calculator);
}
.icon-flash-circle:before {
	content: char-from-code($icon-flash-circle);
}
.icon-sign:before {
	content: char-from-code($icon-sign);
}
.icon-delete-sign:before {
	content: char-from-code($icon-delete-sign);
}
.icon-filter:before {
	content: char-from-code($icon-filter);
}
.icon-task:before {
	content: char-from-code($icon-task);
}
.icon-messages:before {
	content: char-from-code($icon-messages);
}
.icon-tip:before {
	content: char-from-code($icon-tip);
}
.icon-fa-suitcase-light:before {
	content: char-from-code($icon-fa-suitcase-light);
}
.icon-fa-info-circle-light:before {
	content: char-from-code($icon-fa-info-circle-light);
}
.icon-fa-coins-light:before {
	content: char-from-code($icon-fa-coins-light);
}
.icon-fa-compass-light:before {
	content: char-from-code($icon-fa-compass-light);
}
.icon-fa-wrench-light:before {
	content: char-from-code($icon-fa-wrench-light);
}
.icon-fa-minus-circle:before {
	content: char-from-code($icon-fa-minus-circle);
}
.icon-fu-calendar-pencil:before {
	content: char-from-code($icon-fu-calendar-pencil);
}
.icon-mdi-phone-outgoing:before {
	content: char-from-code($icon-mdi-phone-outgoing);
}
.icon-mdi-phone-incoming:before {
	content: char-from-code($icon-mdi-phone-incoming);
}
.icon-mdi-account-check:before {
	content: char-from-code($icon-mdi-account-check);
}
.icon-mdi-account-minus:before {
	content: char-from-code($icon-mdi-account-minus);
}
.icon-mdi-account-remove:before {
	content: char-from-code($icon-mdi-account-remove);
}
.icon-mdi-email-alert:before {
	content: char-from-code($icon-mdi-email-alert);
}
.icon-mdi-calendar-remove:before {
	content: char-from-code($icon-mdi-calendar-remove);
}
.icon-fu-envelope-closed-clock:before {
	content: char-from-code($icon-fu-envelope-closed-clock);
}
.icon-fu-envelope-closed-cross-mark:before {
	content: char-from-code($icon-fu-envelope-closed-cross-mark);
}
.icon-mdi-playlist-edit:before {
	content: char-from-code($icon-mdi-playlist-edit);
}
.icon-mdi-account-question:before {
	content: char-from-code($icon-mdi-account-question);
}
.icon-filter-remove:before {
	content: char-from-code($icon-filter-remove);
}
.icon-fu-calendar-check-mark-02:before {
	content: char-from-code($icon-fu-calendar-check-mark-02);
}
.icon-filter-full-remove:before {
	content: char-from-code($icon-filter-full-remove);
}
.icon-ellipsis:before {
	content: char-from-code($icon-ellipsis);
}
.icon-mdi-calendar-alert:before {
	content: char-from-code($icon-mdi-calendar-alert);
}
.icon-fa-hands-helping:before {
	content: char-from-code($icon-fa-hands-helping);
}
.icon-fa-arrow-to-right:before {
	content: char-from-code($icon-fa-arrow-to-right);
}
.icon-mdi-instagram:before {
	content: char-from-code($icon-mdi-instagram);
}
.icon-fa-folder-open:before {
	content: char-from-code($icon-fa-folder-open);
}
.icon-mdi-account-off:before {
	content: char-from-code($icon-mdi-account-off);
}
.icon-group-user:before {
	content: char-from-code($icon-group-user);
}
.icon-videochat:before {
	content: char-from-code($icon-videochat);
}
.icon-save-alt:before {
	content: char-from-code($icon-save-alt);
}
.icon-itsme:before {
	content: char-from-code($icon-itsme);
}
.icon-fa-image-regular:before {
	content: char-from-code($icon-fa-image-regular);
}
.icon-co-browse:before {
	content: char-from-code($icon-co-browse);
}
.icon-gender:before {
	content: char-from-code($icon-gender);
}
.icon-birthday:before {
	content: char-from-code($icon-birthday);
}
.icon-web:before {
	content: char-from-code($icon-web);
}
.icon-gender-male:before {
	content: char-from-code($icon-gender-male);
}
.icon-gender-female:before {
	content: char-from-code($icon-gender-female);
}
.icon-mdi-phone-declined:before {
	content: char-from-code($icon-mdi-phone-declined);
}