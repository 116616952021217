// Extend for all dialogbox icons
.dialogbox-icon {
  font-family: icons;
  font-size: 1.5rem;
  position: absolute;
  top: 2rem;
  left: .8rem;
  color: $color-white;
}

// Regular dialogbox
.dialogbox-default {
  @include rfb(padding, 2);
  border-top: 1px solid $color-blue;
  border-right: 1px solid $color-blue;
  border-bottom: 1px solid $color-blue;
  border-left: 3rem solid $color-blue;
  border-radius: 5px;
  background-color: $color-white;
  &::after {
    @extend .dialogbox-icon;
    content: char-from-code($icon-info);
    
  }
}

// Wargning dialogbox
.dialogbox-warning {
  @include rfb(padding, 2);
  border-top: 1px solid $color-orange-a500;
  border-right: 1px solid $color-orange-a500;
  border-bottom: 1px solid $color-orange-a500;
  border-left: 3rem solid $color-orange-a500;
  border-radius: 5px;
  background-color: $color-white;
  &::after {
    @extend .dialogbox-icon;
    content: char-from-code($icon-warning);
    
  }
}

// Danger dialogbox
.dialogbox-danger {
  padding: 2rem;
  border-top: 1px solid $color-red-900;
  border-right: 1px solid $color-red-900;
  border-bottom: 1px solid $color-red-900;
  border-left: 3rem solid $color-red-900;
  border-radius: 5px;
  background-color: $color-white;
  &::after {
    @extend .dialogbox-icon;
    content: char-from-code($icon-warning);
  }
}

// CSS background of dialogbox
.pico-overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: $transparent-black-50;
}

// CSS Dialogbox styling
.pico-content {
  position: fixed; 
  z-index: 10001; 
  top: 38.1966%; 
  left: 50%; 
  display: none; 
  overflow: auto; 
  box-sizing: border-box; 
  width: 90%; 
  max-width: 31rem; 
  max-height: 90%; 
  transform: translate(-50%, -38.1966%); 
  border-radius: 5px;
  box-shadow: $transparent-black-40 5px 5px 20px;
}
