@mixin font-face($font-family, $font-weight, $font-style, $src-filename) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;

    src: url('#{$font-path}#{$src-filename}.eot');
    src: url('#{$font-path}#{$src-filename}.eot?#iefix') format('embedded-opentype'), //sass-lint:disable-line no-duplicate-properties
    url('#{$font-path}#{$src-filename}.woff') format('woff'),
    url('#{$font-path}#{$src-filename}.ttf') format('truetype'),
    url('#{$font-path}#{$src-filename}.svg##{$src-filename}') format('svg');
  }
}
