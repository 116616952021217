/*******************************
LABELS
Only used to indicate component status in the huisstijl 
or as a (deprecated) alternative to tags.
*******************************/
.label {
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  margin: 0 .14285714em;
  padding: .5833em .833em;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;
  vertical-align: middle;
  text-transform: none;
  color: $transparent-black-60;
  border: 0 solid transparent;
  border-radius: .28571429rem;
  background-color: $color-blue-300;
  background-image: none;
  &.primary {
    color: $color-white;
    background-color: $color-primary;
  }

  // Component labels huisstijl
  &.javascript {
    margin-right: 5rem; // cheap hack I know :(
    cursor: help;
    color: $color-white;
    background-color: $color-blue-400;
  }

  &.stable {
    cursor: help;
    color: $color-white;
    background-color: $color-success;
  }

  &.beta {
    cursor: help;
    color: $color-white;
    background-color: $color-warning;
  }

  &.alpha {
    cursor: help;
    color: $color-white;
    background-color: $color-yellow;
  }

  &.esth-broken {
    cursor: help;
    color: $color-white;
    background-color: $color-burgundy;
  }

  &.func-broken {
    cursor: help;
    color: $color-white;
    background-color: $color-red-700;
  }

  &.deprecated {
    cursor: help;
    color: $color-white;
    background-color: $color-danger;
  }
  // Component labels huisstijl


  //OLD
  &.prototype {
    color: $color-white;
    background-color: $color-warning;
  }

  &.broken {
    color: $color-white;
    background-color: $color-danger;
  }
  //OLD

  &.low {
    color: $color-white;
    background-color: $color-warning;
  }

  &.middle {
    color: $color-white;
    background-color: $color-success;
  }

  &.kp {
    display: inline;
    margin-right: .3rem; 
    padding: .05rem .15rem;
    vertical-align: baseline;
    color: $color-white;
    background-color: $color-blue;
  }
  &.component-status {
    float: right;
    margin-top: -3.5em;
    &:hover {
      cursor: help;
    }
    .tlite {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.5em;
      background: $color-turquoise;
    }
  }
}

.label:first-child {
  margin-left: 0;
}

.label:last-child {
  margin-right: 0;
}

/*******************************
             States
*******************************/

/*-------------------
      Disabled
--------------------*/

.label.disabled {
  opacity: .5;
}

/*-------------------
        Hover
--------------------*/

a.labels .label:hover, a.label:hover {
  color: $transparent-black-80;
  border-color: $color-grey-300;
  background-color: $color-grey-300;
  background-image: none;
}

.labels a.label:hover:before, a.label:hover:before {
  color: $transparent-black-80;
}

/*-------------------
        Active
--------------------*/

.active.label {
  color: $transparent-black-95;
  border-color: $color-grey;
  background-color: $color-grey;
  background-image: none;
}

.active.label:before {
  color: $transparent-black-95;
  background-color: $color-grey;
  background-image: none;
}

/*-------------------
     Active Hover
--------------------*/

a.labels .active.label:hover, a.active.label:hover {
  color: $transparent-black-95;
  border-color: $color-grey;
  background-color: $color-grey;
  background-image: none;
}

.labels a.active.label:ActiveHover:before, a.active.label:ActiveHover:before {
  color: $transparent-black-95;
  background-color: $color-grey;
  background-image: none;
}

/*-------------------
      Visible
--------------------*/

.labels.visible .label, .label.visible {
  display: inline-block !important;
}

/*-------------------
      Hidden
--------------------*/

.labels.hidden .label, .label.hidden {
  display: none !important;
}


/*******************************
           Variations
*******************************/

/*-------------------
        Basic
--------------------*/

.basic.label {
  color: $transparent-black-90;
  border: 1px solid transparentize($color-grey-a500, .85);
  background: none $color-white;
  box-shadow: none;
}

/* Link */
a.basic.label:hover {
  text-decoration: none;
  color: $color-turquoise;
  background: none $color-white;
  box-shadow: 1px solid transparentize($color-grey-a500, .85);
}

/* Pointing */
.basic.pointing.label:before {
  border-color: inherit;
}


/*-------------------
     Horizontal
--------------------*/

.horizontal.labels .label, .horizontal.label {
  min-width: 3em;
  margin: 0 .5em 0 0;
  padding: .4em .833em;
  text-align: center;
}

/*-------------------
       Circular
--------------------*/

.circular.labels .label, .circular.label {
  line-height: 1em;
  min-width: 2em;
  min-height: 2em;
  padding: .5em !important;
  text-align: center;
  border-radius: 500rem;
}

.empty.circular.labels .label, .empty.circular.label {
  overflow: hidden;
  width: .5em;
  min-width: 0;
  height: .5em;
  min-height: 0;
  vertical-align: middle;
}

/*------------------
   Floating Label
-------------------*/

.floating.label {
  position: absolute;
  z-index: 100;
  top: -1em;
  left: 100%;
  margin: 0 0 0 -1.5em !important;
}

/*-------------------
        Sizes
--------------------*/

.mini.labels .label, .mini.label {
  font-size: .64285714rem;
}

.tiny.labels .label, .tiny.label {
  font-size: .71428571rem;
}

.small.labels .label, .small.label {
  font-size: .78571429rem;
}

.labels .label, .label {
  font-size: .85714286rem;
}

.large.labels .label, .large.label {
  font-size: 1rem;
}

.big.labels .label, .big.label {
  font-size: 1.28571429rem;
}

.huge.labels .label, .huge.label {
  font-size: 1.42857143rem;
}

.massive.labels .label, .massive.label {
  font-size: 1.71428571rem;
}