// Toggleselect (They see mee togglin', they hatin'...)

/*START
* JS enabled toggle select polyfill (which isnt rlly a polyfill yet)
*/

.toggle-select-small label, .toggle-select label, .hs-toggle-select-small label, .hs-toggle-select label { 
  @include rfb(padding, .3, 1);
  position: relative;
  display: inline-block;
  float: left;
  overflow: hidden;
  color: $transparent-black-60;
  border: 1px solid $color-blue-300;
  border-right: 0;
  background-color: $color-white;
  
  &:hover {
    color: $color-white;
    background-color: $color-blue;
  }

  &.toggle-warning {
    background-color: $color-warning;
  }

  &.toggle-succes {
    background-color: $color-green-a500;
  }

  &.toggle-warning:hover {
    background-color: darken($color-warning, 25%);
  }

  &.toggle-succes:hover {
    background-color: darken($color-green-a500, 25%);
  }
  
  &:first-of-type {
    border-left: 1px solid $color-blue-300 !important;
    border-radius: 5px 0 0 5px;
  }
  &:last-of-type {
    border-right: 1px solid $color-blue-300 !important;
    border-radius: 0 5px 5px 0;
  }
}


//TODO: small label, Jeroen? :)
//U vraagt, wij draaien. *PupUPUupUupUPUPUPUpUUUPPUpuupupuUUp*

.toggle-select-small label, .hs-toggle-select-small label { 
  @include rfb(padding, .15, .5);
  @include font-size($font-size-base*.9);
}

.toggle-select label input, .hs-toggle-select label input {
  position: absolute;
  left: -1000px;
  width: 1px;
  height: 1px;
}

label.toggle-select-active, label.hs-toggle-select-active {
  color: $color-white;
  background-color: $color-blue;
}

.toggle-select-small label input, .hs-toggle-select-small label input {
  position: absolute;
  left: -1000px;
  width: 1px;
  height: 1px;
}

/*END */