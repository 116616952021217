// CSS for blockdisplays
//sass-lint:disable class-name-format

.block-display {
  @include rfb(padding, 0);
  @include rfb(margin, 0, 0, 1, 0);
  clear: both;

  /**
   * Panel group override
   */

  .panel-group {
    margin: 0;
  }

  &.search-results {
    .block-display-content {
      background-color: $color-lightgreen-200;
    }

    .block-display-head {
      background-color: $color-green-700; //was #6a9129
    }
  }

  .block-display-subhead {
    @extend .clearfix;
    @include rfb(padding, .8, 1, .3, 1);
    width: 100%;
    border-bottom: 1px solid $transparent-black-05;
    background: transparentize($color: $color-yellow-700, $amount: .9);

    h5 {
      @include rfb(padding, .2, 1, .8, 0);
      @include rfb(margin, 0);
      float: left;
      .icon-subdirectory-arrow-right {
        margin-left: 1rem;
      }
    }

    .head-action {
      float: right;
    }

    // For PI toewijzingen/partners
    &.subhead-passive {
      opacity: .5;
    }

    &.subhead-error {
      background: $color-red-200;

      .subhead-error-message {
        @include icon(warning);
        @include rfb(padding, 1, 1.75);
        position: relative;
        clear: both;
        width: 100%;
        color: $color-danger;
        border: 0;
        border-top: 1px solid $color-red-300;
        background: $color-red-200;

        &:before {
          @include rfb(top, 1.25);
          @include rfb(left, 0);
          position: absolute;
        }

        a {
          font-weight: 700;
          text-decoration: underline;
          color: inherit;

          &:visited {
            color: inherit;
          }
        }

        h2 {
          font-size: 22px;
          margin-top: 0;
          color: inherit;
        }
      }
    }
  }

  .block-display-head {
    @extend .clearfix;
    width: 100%;
    border: 1px solid $transparent-black-10;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    background: transparentize($color-blue, .25);

    .block-display-pivot {
      color: $color-white;

      &:after {
        @extend .icon-after;
        padding-left: .3em;
        content: char-from-code($icon-arrow-down);
        vertical-align: middle;
      }

      &:hover {
        color: $color-grey-300;
      }
    }

    .block-display-remove {
      color: $color-white;

      &:after {
        @extend .icon-after;
        padding-left: .3em;
        content: char-from-code($icon-cross);
        vertical-align: middle;
      }

      &:hover {
        color: $color-grey-300;
      }
    }

    &.head-warning {
      border: 1px solid $color-orange-300;
      border-bottom: 1px solid $color-orange-300;
      background: $color-orange;

      .head-warning-message {
        @include icon(warning);
        @include rfb(padding, 1, 3);
        position: relative;
        clear: both;
        width: 100%;
        color: $color-orange-a500;
        border: 0 0;
        background: $color-orange-300;

        &:before {
          @include rfb(top, 1.25);
          @include rfb(left, 1.25);
          position: absolute;
        }

        a {
          font-weight: 700;
          text-decoration: underline;
          color: inherit;

          &:visited {
            color: inherit;
          }
        }

        h2 {
          font-size: 22px;
          margin-top: 0;
          color: inherit;
        }
      }
    }

    &.head-error {
      border: 0;
      background: $color-red-900;

      .head-error-message {
        @include icon(warning);
        @include rfb(padding, 1, 3);
        position: relative;
        clear: both;
        width: 100%;
        color: $color-danger;
        border: 0 0;
        background: $color-red-200;

        &:before {
          @include rfb(top, 1.25);
          @include rfb(left, 1.25);
          position: absolute;
        }

        a {
          font-weight: 700;
          text-decoration: underline;
          color: inherit;

          &:visited {
            color: inherit;
          }
        }

        h2 {
          font-size: 22px;
          margin-top: 0;
          color: inherit;
        }
      }
    }

    &.head-info {
      border: 1px solid $color-green-300;
      border-bottom: 1px solid $color-green-300;
      background: $color-green-700; //was #6a9129

      .head-info-message {
        @include icon(bulb);
        @include rfb(padding, 1, 3);
        position: relative;
        clear: both;
        width: 100%;
        color: $color-green-700;
        border: 0 0;
        background: $color-lightgreen-200;

        &:before {
          @include rfb(top, 1.25);
          @include rfb(left, 1.25);
          position: absolute;
        }

        a {
          font-weight: 700;
          text-decoration: underline;
          color: inherit;

          &:visited {
            color: inherit;
          }
        }

        h2 {
          font-size: 22px;
          margin-top: 0;
          color: inherit;
        }
      }
    }

    h2 {
      @include rfb(padding, .5, 1);
      @include rfb(margin, 0);
      @include font-size($font-size-h4);
      float: left;
      color: $color-white;
    }

    .head-action {
      float: right;

      span:not(.tlite) { //because SOMEONE *cough* verena *cough* wasn't specific enough when coding this, it causes a tooltip used inside a head-action to get unintended padding which fucks things up. :not pseudoclass to the rescue hooray, FML
        @include rfb(padding, .8, 1);
        color: $color-white;
      }

      .btn-bar {
        @include rfb(padding, .5, 1);
        border: 0;

        ul {
          border: 0;
        }

        a {
          color: $color-white;
          border-color: $transparent-white-25;
          background-color: $transparent-black-10;
        }
      }

      .header-input, .header-search, .header-barcode {
        position: relative;
        float: right;

        input[type='text'] {
          
          @extend .field-input-styling;
          @include rfb(margin, .35);
          @include rfb(padding-right, 1.7);
        }

        &:after {
          @extend .icon-after;
          @include rfb(margin-top, .91);
          @include rfb(margin-left, -1.85);
          position: absolute;
          color: $color-grey-300;
          background: none;
        }

        &.field-first:after {
          top: 0;
          right: 1.5em;
          margin-left: 0;
        }
      }

      .header-search {
        &:after {
          content: char-from-code($icon-search);
        }
      }
      
      .header-barcode {
        &:after {
          content: char-from-code($icon-barcode-scan);
        }
      }

      .header-icon-lock:before {
        @extend .icon;
        @extend .icon-lock;
        @include rfb(padding, .9);
        vertical-align: middle;
        color: $transparent-white-75;
      }

      .header-action-edit, .header-action-info, .header-action-filter, .header-action-add, .header-action-add-group, .header-action-export, .header-action-close, [class^='icon-'], [class^='action-'] {
        @include rfb(padding-right, .9);
        float: left;
        text-decoration: none;
        //color: $color-white;

        &:hover {
          text-decoration: underline;
        }
      }

      .header-action {
        @include rfb(padding, .9, .3, .9, .9);
        text-decoration: none;
        //color: $color-white;
      }

      // Suport any icon in head-action
      [class^='icon-']:before {
        @extend .icon;
        @extend .header-action;
      }

      // Support existing action-links in head-action
      // SVE _> added the :after because the actions were not aligned when  using after actions
      [class^='action-']:before,
      [class^='action-']:after {
        @extend .header-action;
      }

      // Legacy header-actions, use action-... or icon-...
      .header-action-export:before {
        @extend .icon;
        @extend .icon-export;
        @extend .header-action;
      }

      .header-action-edit:before {
        @extend .icon;
        @extend .icon-edit;
        @extend .header-action;
      }

      .header-action-info:before {
        @extend .icon;
        @extend .icon-info;
        @extend .header-action;
      }

      .header-action-filter:before {
        @extend .icon;
        @extend .icon-search;
        @extend .header-action;
      }

      .header-action-add:before {
        @extend .icon;
        @extend .icon-plus;
        @extend .header-action;
      }

      .header-action-add-group:before {
        @extend .icon;
        @extend .icon-group-add;
        @extend .header-action;
      }

      .header-action-close:before {
        @extend .icon;
        @extend .icon-close;
        @extend .header-action;
      }
    }
  }

  &:not(.block-display-neutral) .block-display-head .head-action {
    [class^='icon-'], [class^='action-'], [class^='header-action'] {
      color: $color-white;
    }
  }

  .block-display-action {
    @include rfb(padding, .5, 1);
    background: transparentize($color-blue, .85);
    // height: 40px; huh? why?

    a {
      @include rfb(padding-right, 1);
    }
  }

  .block-display-pagination {
    border-top: 1px solid $transparent-black-05;
    background: $color-grey-200;
  }

  .block-display-sections-container {
    border-top: 0;
    border-right: 1px solid $transparent-black-10;
    border-bottom: 1px solid $transparent-black-10;
    border-left: 1px solid $transparent-black-10;
    border-radius: 0 0 3px 3px;
    background-color: $color-white;

    .block-display-content, .block-display-table, .block-display-form {
      border: 0;
    }
  }

  .block-display-content {
    @include rfb(padding, 1);
    border-top: 0;
    border-right: 1px solid $transparent-black-10;
    border-bottom: 1px solid $transparent-black-10;
    border-left: 1px solid $transparent-black-10;
    border-radius: 0 0 3px 3px;
    background-color: $color-white;

    // for projectinfo
    .pi-budget-block {
      padding-bottom: 1rem;
      border-right: 1px solid $transparent-black-10;
      &:nth-child(3) {
        border: 0;
      }
      h3 {
        margin-top: 1rem;
      }
      &.budget-block-total {
        margin-top: -1rem;
        padding-top: 1rem;
        background: $color-grey-100;
      }
    }
    hr {
      margin: 0;
    }

    &.block-display-gantt-chart {
      @include rfb(padding, 0);
      overflow: auto;
      max-height: 600px;
    }

    // Read view for the Compas templatepicker
    &.block-display-templatepicker {
      table {
        th, td {
          @include rfb(padding, .7, .7, .7, 0);
        }

        td:last-child {
          @include rfb(padding-left, .7);
        }

        th:first-child {
          width: 20%;
        }

        td:first-child {
          text-align: right;
          color: $transparent-black-50;
        }

        td input[type='text'] {
          
          @extend .field-input-styling;
        }

        td input[type='text']:focus {
          border-color: $color-primary;
          outline: none;
        }

        td a {
          @include rfb(margin-top, .1);
          @include rfb(margin-left, .5);
        }
      }
    }

    &.block-display-compare {
      table {
        tr.compare-different {
          background: $color-orange-300;

          td:first-child {
            color: $color-orange;
            border-top: 1px solid $color-orange;
            border-bottom: 1px solid $color-orange;
            border-left: 1px solid $color-orange;
          }

          td {
            border-top: 1px solid $color-orange;
            border-bottom: 1px solid $color-orange;
          }

          td:last-child {
            border-top: 1px solid $color-orange;
            border-right: 1px solid $color-orange;
            border-bottom: 1px solid $color-orange;
          }
        }

        th, td {
          @include rfb(padding, .7, .7, .7, 0);
          vertical-align: top;
        }

        th:first-child {
          width: 20%;
        }

        td:first-child {
          text-align: right;
          color: $transparent-black-50;
        }

        td input[type='text'] {
          
          @extend .field-input-styling;
        }

        td input[type='text']:focus {
          border-color: $color-primary;
          outline: none;
        }

        td a {
          @include rfb(margin-top, .1);
          @include rfb(margin-left, .5);
        }

        td img {
          width: 100%;
        }
      }
    }
  }

  .block-display-row {
    @extend %clear;
    @include rfb(padding, .4);
    clear: left;
    width: 100%;
    height: inherit;

    &.row-title {
      h1, h2, h3, h4, h5, h6 {
        padding-left: 20%;
      }
    }

    &.row-inline-edit {
      input {
        margin-right: -5px;
      }

      select {
        @include rfb(margin-right, 1);
      }

      .block-display-label label {
        @include rfb(margin-top, .3);
        display: block;
      }

      a.action-submit, a.action-cancel {
        @include rfb(padding-top, .24);
        @include rfb(padding-bottom, .24);
        display: block;
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid $transparent-black-15;
        border-bottom: 1px solid $transparent-black-15;
        background-color: $color-grey-100;

        &:hover {
          background-color: $color-grey-300;
          box-shadow: none;
        }
      }

      a.action-submit {
        @include rfb(padding-left, .6);
        @include rfb(padding-right, .4);
        color: $color-green-700;
        border-left: 1px solid $transparent-black-15;
        box-shadow: $transparent-black-10 -1px -1px 3px inset;

        &.border-left-radius {
          border-radius: 3px 0 0 3px;
        }
      }

      a.action-cancel {
        @include rfb(padding-right, .4);
        @include rfb(padding-left, .6);
        color: $color-red-a500;
        border-right: 1px solid $transparent-black-15;
        border-left: 1px solid $transparent-black-15;
        border-radius: 0 3px 3px 0;
        box-shadow: $transparent-black-10 -1px -1px 3px inset;
      }
    }
  }

  .block-display-label {
    @include rfb(padding-right, 1);
    float: left;
    width: 20%;
    text-align: right;
    color: $transparent-black-50;
  }

  .block-display-field {
    @include rfb(margin-right, 1);
    float: left;
    width: 76%;
    word-wrap: break-word;
    color: $color-black;

    &.field-passive {
      color: $transparent-black-30;

      &:after {
        @extend .icon-after;
        padding-left: .3em;
        content: char-from-code($icon-warning);
        vertical-align: middle;
        color: $color-warning;
      }
    }

    &.field-editable {
      a:after {
        @extend .icon-after;
        padding-left: .3em;
        content: char-from-code($icon-edit);
        vertical-align: middle;
        color: $color-grey-300;
      }

      a {
        color: $color-black;

        &:hover:after {
          color: $color-blue;
        }
      }
    }
  }

  .block-display-form {
    color: $color-black;
    border: 1px solid $transparent-black-10;
    border-top: 0;
    border-radius: 0 0 3px 3px;

    .form-container {
      margin: 0;
      border: 0;
    }
  }

  .block-display-meta {
    @include rfb(padding, 0);
    @include font-size($font-size-h6);
    width: 100%;
    text-align: right;
    color: $color-grey-700;
  }
}

// Block display with neutral header
.block-display.block-display-neutral {
  .block-display-head {
    border: 1px solid $transparent-black-10;
    background-color: $color-grey-200;
    h2 {
      color: $transparent-black-70;
    }
  }
}

.block-display.block-display-white {
  .block-display-head {
    background-color: $color-white;
    h2 {
      color: $transparent-black-70;
    }
  }
}

// inline edit field
.inline-edit-label {
  @include rfb(margin, .8, .4, 0, 0);
  float: left;
  &.edit-label-stacked {
    margin: 0;
  }
}
.inline-edit-field {
  @include rfb(margin-bottom, .5);
  float: left;

  .form-field {
    width: auto;
  }
  textarea {
    width: 85%;
  }

  input {
    @extend .field-input-styling;
    
    margin-right: -5px;
  }

  ul > li > input[type='checkbox'] {
    margin-top: .3em;
    margin-right: .5em !important;
  }

  select {
    @include rfb(margin-right, .5);
    @include rfb(height, 2.1);
    @include rfb(padding, 0, .3, 0, .3);
    line-height: 20px;
    display: inline-block;
    float: left;
    min-width: none;
    border: 1px solid $transparent-black-15;
    border-radius: 2px;

    &::before, ::after {
      position: absolute;
      content: '';
      pointer-events: none;
    }

    &::after {
      font-size: .625em;
      line-height: 1;
      top: 50%;
      right: 1.2em;
      height: 1em;
      margin-top: -.5em;
      /*  Custom dropdown arrow */
      content: char-from-code($icon-arrow-down);
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      /*  Custom dropdown arrow cover */
      width: 2em;
      border-radius: 0 3px 3px 0;
    }
  }

  select::-ms-expand {
    /* for IE 11 */
    display: none;
  }

  select:focus {
    border-color: $color-primary;
    outline: none;
  }

  .block-display-label label {
    @include rfb(margin-top, .3);
    display: block;
  }

  a.action-submit, a.action-cancel, button[type='submit'] {
    @include rfb(padding-top, .24);
    @include rfb(padding-bottom, .24);
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid $transparent-black-15;
    border-bottom: 1px solid $transparent-black-15;
    background-color: $color-grey-100;

    &:hover {
      background-color: $color-grey-300;
      box-shadow: none;
    }
  }

  a.action-submit, button[type='submit'] {
    @include rfb(padding-left, .6);
    @include rfb(padding-right, .4);
    color: $color-green-700;
    border-left: 1px solid $transparent-black-15;
    box-shadow: $transparent-black-10 -1px -1px 3px inset;

    &.border-left-radius {
      border-radius: 3px 0 0 3px;
    }
  }

  a.action-cancel {
    @include rfb(padding-right, .4);
    @include rfb(padding-left, .6);
    color: $color-red-a500;
    border-left: 1px solid $transparent-black-15;
    box-shadow: $transparent-black-10 -1px -1px 3px inset;
  }
  a:last-child {
    border-right: 1px solid $transparent-black-15;
    border-radius: 0 3px 3px 0;
  }
  button[type='submit'] {
    border-right: 0;
  }
}

.inline-editable-value {
  a:after {
    @extend .icon-after;
    padding-left: .3em;
    content: char-from-code($icon-edit);
    color: $color-grey-300;
  }
  ul {
    margin-bottom: 0;
  }

  a {
    &:hover:after {
      color: $color-blue;
    }
  }
}

// padding fix for multicheckbox in meta block column
div.meta-block-column {
  div.form-field {
    &.field-checkboxmulti {
      padding-top: 0;
    }
  }
}

// Compas header in template read view above block display.
// Is here because compas does not have it's own css and this place seems like the most logical place to put it.
.compas-template-head {
  @extend .clearfix;

  h1 {
    @include rfb(margin, 0);

    .label {
      @include rfb(margin-left, .7);
      font-weight: 400;
      position: absolute;
      top: .25em;
      color: $color-orange;
      border: 1px solid $transparent-black-05;
      background-color: $color-orange-300;

      &:before {
        @extend .icon;
        @extend .icon-warning;
        padding-right: .3em;
        vertical-align: middle;
      }
    }
  }

  .compas-title-action {
    text-align: right;

    a {
      @include rfb(margin-left, 1);
    }
  }

  .col {
    @include rfb(padding, 0);
  }
}

.color-square {
  @include rfb(margin-top, .25);
  @include rfb(margin-right, .5);
  display: block;
  float: left;
  width: 1rem;
  height: 1rem;
  border: 1px solid $transparent-black-50;
  background: $transparent-black-50;
}

// Gantt chart inside blockdisplay (for projectinfo)
// 20 months showable (+1 for th's)

.gantt-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-grey-100;

  .gantt-chart-container {
    overflow: auto;
    background: $color-white;
  }

  h1 {
    @include rfb(margin-top, 2);
    @include rfb(margin-bottom, 2);
    @include rfb(margin-left, 1);
  }

  p {
    @include rfb(margin-top, 1);
    @include rfb(margin-left, 1);
  }

  a.button {
    @include rfb(margin-top, 2);
    @include rfb(margin-left, 1);
  }
}

//beautifull zebra striping of the planned subprojects in the gantt chart of projectinfo
.gantt-zebra-striping {
  //sass-lint:disable-block no-duplicate-properties
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-25 5px, $transparent-white-25 10px);
  background-image: -moz-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-25 5px, $transparent-white-25 10px);
  background-image: -webkit-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-25 5px, $transparent-white-25 10px);
  background-image: -o-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-25 5px, $transparent-white-25 10px);
  background-image: -ms-repeating-linear-gradient(-45deg, transparent, transparent 5px, $transparent-white-25 5px, $transparent-white-25 10px);
}

table.gantt-chart {
  table-layout: fixed;
  white-space: nowrap;

  &.fullScreen {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-yellow-100;
  }

  thead {
    th {
      color: $transparent-black-70;
      background: $color-grey-300;
    }

    th.topLevelDate {
      @include rfb(padding, .75, .75, 0, .75);
      font-weight: 500;
      border-left: 1px solid $color-grey;
    }

    th.subLevelDate {
      @include rfb(padding, 0, .75, .75, .75);
      font-weight: 400;
      border-left: 1px solid $color-grey;
    }

    th.topLabels {
      width: 21%;
      text-align: right;
      border-left: 0;
      background-color: $color-grey;
    }
  }

  tbody {
    &:nth-of-type(even) {
      background-color: transparentize($color-blue, .95);
    }

    tr {
      border-bottom: 1px solid $color-grey-200;
    }

    th {
      @include rfb(padding, .75);
      text-align: right;
      border-right: 1px solid $color-grey-300;

      a {
        display: block;
        float: right;
        overflow: hidden;
        width: 85%;
        margin-right: 4%;
        text-overflow: ellipsis;
      }

      .tooltip-info:before {
        margin-top: -.2em;
      }

    }

    td {
      @include rfb(padding, .5);
      border-left: 1px solid $color-grey-200;

      a {
        @include rfb(padding-right, .3);
        @include font-size(12, 0);
        font-weight: 500;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        text-indent: 5px;
        text-overflow: ellipsis;
        color: $color-white;
        border: 1px solid $transparent-black-10;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background: $color-grey;

        &.subproject-IN_AANVRAAG {
          background-color: $color-blue-900;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-GOEDGEKEURD {
          background-color: $color-green-a500;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-GECONTRACTEERD {
          background-color: $color-yellow;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-DVL_GESTART {
          background-color: $color-warning;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-DVL_GESTOPT {
          background-color: $color-grey-600;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-AFGESLOTEN {
          background-color: $color-grey-700;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-GEANNULEERD {
          background-color: $color-red-300;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &.subproject-GEWEIGERD {
          background-color: $color-red-700;

          &.date-planned {
            @extend .gantt-zebra-striping;
          }
        }

        &:hover {
          color: $color-white;
          border: 1px solid $transparent-black-30;
          background-color: $color-blue;
        }
      }
    }
  }
}

// gantt chart leggenda for planned dates
.subproject-legend {
  @extend .gantt-zebra-striping;
  @include font-size(12, 0);
  font-weight: 500;
  display: inline-block;
  width: 20%;
  white-space: nowrap;
  text-decoration: none;
  text-indent: 5px;
  color: $color-white;
  border: 1px solid $transparent-black-10;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: $color-green-a500;
}

@media (max-width: $screen-small) {
  // This makes your entire form go stacked on larger screens as well
  .block-display-row {
    @include rfb(padding-top, .3);
    @include rfb(padding-bottom, .3);

    .block-display-label {
      @include rfb(padding-right, 0);
      @include rfb(padding-bottom, 0);
      width: 100%;
      text-align: left;
    }

    .block-display-field {
      width: 100%;
    }
  }
}

/**
 * Block Display Override for when you use slats
 */
.block-display-slats {
  .block-display-content {
    @include rfb(padding, 2, 2, 1, 2);
  }
}

.block-display-cv {
  .block-display-content {
    border: $border-default;
    border-radius: 3px 3px 0 0;
  }

  .block-display-field {
    float: left;
    width: 60%;
  }

  .block-display-field-action {
    @include rfb(margin-right, 0);
    float: left;
    width: 18%;
    text-align: right;
  }

  .field-head, .field-action-head {
    margin-top: 22px;
  }

  @media (max-width: $screen-small) {
    // This makes your entire form go stacked on larger screens as well
    .block-display-row {
      @include rfb(padding-top, .3);
      @include rfb(padding-bottom, .3);

      .block-display-label {
        @include rfb(padding-right, 0);
        @include rfb(padding-bottom, 0);
        width: 100%;
        text-align: left;
      }

      .block-display-field {
        width: 100%;
      }

      .block-display-field-action {
        width: 100%;
        text-align: left;
      }

      .block-display-field-action-head {
        @include rfb(margin-top, 0);
        width: 100%;
        text-align: left;
      }
    }
  }

  .block-display-row {
    -webkit-transition: background-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out;
    -ms-transition: background-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;
  }

  .block-display-row-hover:hover {
    background-color: lighten($color-grey-700, 35%);
  }

  h3 {
    color: $color-indigo;
  }

  ul {
    @include rfb(padding, 0);
  }

  li {
    list-style-type: none;
  }

}

.block-display-row-disabled {
  .block-display-label {
    color: $transparent-black-50;
  }
  .block-display-field {
    color: $transparent-black-50;
  }
}

// Projectinfo: projecten openen in toewijzingen/partners
.project-table {
  padding: 0 1rem;
  background: $color-grey-100;
}
