//
// BUTTONS
// ------------------------------------------------
.button {
  @include font-smoothing();
  font-family: $brand-font-regular;
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  transition: (all .1s);
  border-radius: 5px;

  &:focus {
    outline: 0;
  }

  &+.btn {
    @include rfb(margin-left, .5);
  }

  &.mini {
    @include rfb(padding, .375, .75);
    @include font-size(14, 15);
    width: auto; //not necessary but used to overrule drupal calendar component which has an idiotic root class calls mini too
    margin: 0;
  }

  &.small {
    @include rfb(padding, .3, .75);
    margin: 0;
  }

  &.medium {
    @include rfb(padding, .5, 1.25);
    @include font-size(16, 28);
  }

  &.big {
    @include font-size(16, 36);
    @include rfb(padding, .5, 2);
    border-width: 1px;
    border-radius: 4px;
  }

  &.full-width {
    width: 100%;
    text-align: center;
  }
}

.button,
.round-btn {
  color: $color-white;
  border: 1px solid $color-blue;
  background: $color-primary;

  &:hover {
    color: $color-white;
    background: saturate(lighten($color-primary, 5%), 20%);
  }

  &:visited {
    color: $color-white;
  }


  &.shy {
    padding: 3px 8px 5px 8px;
    color: $color-white;
    border: 0;
    border-radius: 5px;
    background-color: $color-grey;

    &:hover {
      color: $color-white;
      background: lighten($color-primary-muted, 5%);
    }
  }

  &.alpha {
    color: $color-white;
    border-color: darken($color-primary-muted, 5%);
    background: $color-primary;

    &:hover {
      color: $color-white;
      background: lighten($color-primary-muted, 5%);
    }

    &:visited {
      color: $color-white;
    }
  }

  &.beta {
    color: $color-white;
    border-color: darken($color-primary-muted, 5%);
    background: $color-primary-muted;

    &:hover {
      color: $color-white;
      background: lighten($color-primary-muted, 5%);
    }

    &:visited {
      color: $color-white;
    }
  }

  &.gamma {
    color: $color-white;
    border-color: $color-indigo;
    background: $color-blue-700;

    &:hover {
      color: $color-white;
      background: lighten($color-blue-700, 5%);
    }

    &:visited {
      color: $color-white;
    }
  }

  &.delta {
    color: $color-white;
    border-color: $color-grey-a500;
    background-color: lighten($color-grey-900, 10%);

    &:visited {
      color: $color-white;
    }
  }

  &.rho {
    color: $color-blue;
    border-color: $color-blue-300;
    background: $color-blue-100; //#e5edf8

    &:hover {
      color: $color-white;
      background: $color-blue;
    }

    &:active {
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &.green {
    color: $color-white;
    border-color: $color-green-900; //was color #628725
    background-color: $color-lightgreen-600; //was color #6a9129

    &:hover {
      color: $color-white;
      background: saturate(lighten($color-lightgreen-600, 5%), 20%);
    }

    &:active {
      color: $color-white;
      background: saturate(lighten($color-lightgreen-600, 5%), 20%);
    }
  }

  &.orange {
    color: $color-white;
    border-color: $color-orange-700;
    background-color: $color-orange;

    &:hover {
      color: $color-white;
      background: saturate(lighten($color-orange, 5%), 20%);
    }

    &:active {
      color: $color-white;
      background: saturate(lighten($color-orange, 5%), 20%);
    }
  }

  &.consulent {
    //consulenten button VEJ en mijn vacatures
    color: $color-orange;
    border-color: $color-orange;
    background-color: $color-white;

    &:hover {
      color: $color-white;
      background: $color-orange;
    }

    &:active {
      color: $color-white;
      background: saturate(lighten($color-orange, 5%), 20%);
    }
  }

  &.white {
    color: $color-blue;
    border-color: transparentize($color-blue-a500, .8);
    background: $color-white;

    &:hover {
      color: $color-white;
      background: $color-blue;
    }

    &:active {
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &.danger {
    font-weight: 500;
    color: $color-white;
    border-color: darken($color-danger, 15%);
    background: darken($color-danger, 5%);

    &:hover {
      background: $color-danger;
    }
  }

  &.warning {
    font-weight: 500;
    color: $color-white;
    border-color: darken($color-warning, 15%);
    background: darken($color-warning, 5%);

    &:hover {
      background: $color-warning;
    }
  }

  &.ghost {
    @include rfb(padding-right, 0);
    @include rfb(padding-left, 0);
    color: $color-primary;
    border: 0;
    background: transparent;

    &:hover {
      color: $color-blue-900;
    }
  }

  &.passive {
    cursor: default;
    color: $color-grey-700;
    border-color: $color-grey;
    background: $color-grey-300;
  }

  &.secondary {
    color: $color-blue;
    border: 1px solid $color-blue;
    background: $color-white;

    &:hover {
      color: $color-white;
      background: $color-blue;
    }
  }

  &.danger-hover:hover {
    color: $color-white;
    border-color: $color-danger;
    background: $color-danger;
  }

  &.warning-hover:hover {
    color: $color-white;
    background: $color-warning;
  }

  &:disabled,
  &.disabled,
  &.disabled:hover,
  &:disabled:hover {
    cursor: not-allowed;
    color: $color-grey-700 !important;
    border-color: $color-grey !important;
    background: $color-grey-300 !important;
  }
}

button.close {
  height: auto;
  margin: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.round-btn {
  display: inline-block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  padding: 2px 6px;
  white-space: nowrap;
  border-radius: 15px;

  &:before {
    margin-right: 30px;
  }
}

.chip {
  border-radius: 50px;
}

.button span.icon {
  margin-right: 8px;
}

button.pill {
  @extend .action-delete-after;
  font-size: .875rem;
  position: relative;
  display: inline-block;
  padding: 0 .4rem;
  color: $color-grey-900;
  border: 1px solid $color-grey-500;
  border-radius: 25px;
  background-color: $color-grey-100;

  &:after {
    color: $color-grey-600;
  }

  &:hover {
    border-color: $color-grey-700;

    &:after {
      color: $color-grey-900;
    }
  }
}

/*
* Extra big buttons that are the prime focus & functionality of a page
* ex. login screen
*/

.prime-btn-wrapper {
  @include flexbox();
  @include justify-content(space-between);

  .button {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-basis(0);
    @include flex-grow(1);
    line-height: 1.15rem;
    margin: 0 1rem;
    text-align: center;

    [class^='icon-'] {
      font-size: 2.5rem;
      margin-right: 1rem;
      white-space: nowrap;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}