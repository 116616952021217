/**
 * The use of important on utilities
 * Force utility classes to be immutable by applying !important to their declarations.
 * http://csswizardry.com/2016/05/the-importance-of-important/
 * http://davidtheclark.com/on-utility-classes/
 */

/**
 * Margin utils
 */

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.m025 {
  margin: .25rem !important;
}

.mt025 {
  margin-top: .25rem !important;
}

.mr025 {
  margin-right: .25rem !important;
}

.mb025 {
  margin-bottom: .25rem !important;
}

.ml025 {
  margin-left: .25rem !important;
}

.m05 {
  margin: .5rem !important;
}

.mt05 {
  margin-top: .5rem !important;
}

.mr05 {
  margin-right: .5rem !important;
}

.mb05 {
  margin-bottom: .5rem !important;
}

.ml05 {
  margin-left: .5rem !important;
}

.m1 {
  margin: 1rem !important;
}

.mt1 {
  margin-top: 1rem !important;
}

.mr1 {
  margin-right: 1rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.ml1 {
  margin-left: 1rem !important;
}

.m15 {
  margin: 1.5rem !important;
}

.mt15 {
  margin-top: 1.5rem !important;
}

.mr15 {
  margin-right: 1.5rem !important;
}

.mb15 {
  margin-bottom: 1.5rem !important;
}

.ml15 {
  margin-left: 1.5rem !important;

}
.mt05- {
  margin-top: -.5rem !important;
}

.m1- {
  margin: -1rem !important;
}

.mt1- {
  margin-top: -1rem !important;
}

.mr1- {
  margin-right: -1rem !important;
}

.mb1- {
  margin-bottom: -1rem !important;
}

.ml1- {
  margin-left: -1rem !important;
}




/**
 * Pading utils
 */

.p0 {
  padding: 0 !important;
}

.p025 {
  padding: .25rem !important;
}

.pt025 {
  padding-top: .25rem !important;
}

.pr025 {
  padding-right: .25rem !important;
}

.pb025 {
  padding-bottom: .25rem !important;
}

.pl025 {
  padding-left: .25rem !important;
}

.p05 {
  padding: .5rem !important;
}

.p1 {
  padding: 1rem !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr05 {
  padding-right: .5rem !important;
}

.pl05 {
  padding-left: .5rem !important;
}

.pt05 {
  padding-top: .5rem !important;
}

.pt1 {
  padding-top: 1rem !important;
}

.pr1 {
  padding-right: 1rem !important;
}

.pb1 {
  padding-bottom: 1rem !important;
}

.pb05 {
  padding-bottom: .5rem !important;
}

.pl1 {
  padding-left: 1rem !important;
}

/**
 * Border utils
 */
.b0 {
  border: 0 !important;
}


.bt0 {
  border-top: 0 !important;
}

.br0 {
  border-right: 0 !important;
}

.bl0 {
  border-left: 0 !important;
}

.bb0 {
  border-bottom: 0 !important;
}

.b1 {
  border: 1px solid $transparent-black-10 !important;
}

.bt1 {
  border-top: 1px solid $transparent-black-10 !important;
}

.br1 {
  border-right: 1px solid $transparent-black-10 !important;
}

.bl1 {
  border-left: 1px solid $transparent-black-10 !important;
}

.bb1 {
  border-bottom: 1px solid $transparent-black-10 !important;
}

.b-dotted {
  border-width: 2px !important;
  border-style: dotted !important;
}

.bt-dotted {
  border-top-width: 2px !important;
  border-top-style: dotted !important;
}

.br-dotted {
  border-right-width: 2px !important;
  border-right-style: dotted !important;
}

.bb-dotted {
  border-bottom-width: 2px !important;
  border-bottom-style: dotted !important;
}

.bl-dotted {
  border-left-width: 2px !important;
  border-left-style: dotted !important;
}

/**
 * clearfix
 */

%clear {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

/**
 * Centering an element
 */
.col-centered {
  float: none !important;
  margin: 0 auto !important;
}

/**
 * Making an element invisible but still accesible
 */
.element-invisible {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; 
  &.element-focusable:active, &.element-focusable:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    height: auto;
  }
}

/**
* actually not displaying an item (ex. empty option in select)
*/

.element-hidden {
  display: none !important;
}

/**
for elements that need not be shown but keep their space in the DOM
! needs disabled attribute on it for accesibility
*/
.element-masked {
  visibility: hidden;
}

/*
* general class to be used for items standard not displaying on mobile screens, can be toggled by devs
*/
@media (max-width: $screen-small) {
  .mob-hidden {
    display: none;
  }
}

/**
 * Float Right
 */
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.float-none {
  float: none !important;
}

/*
* clear utils
*/

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}


/**
 * Table utils
 */
table.vam td {
  vertical-align: middle !important;
}
table .vertical-align-top td {
  vertical-align: top !important;
}

//SVE 3.4: added this because top align doesn't always nee to be on all cells in a row
table td.vertical-align-top {
  vertical-align: top !important;
}

/**
 * Form divider class
 */

/**
 * add this class to the element on the right
 */
.form-container.form-stacked .form-divider {
  border-top: 1px solid $color-grey-300;

  .form-row {
    .form-label {
      padding: 0;
      border: 0;
    }
  }
  @media (min-width: $screen-small) {
    @include rfb(padding-left, 3);
    border-top: 0;
    border-left: 1px solid $color-grey-300;
  }
}

/**
 * add this class to the element on the left
 */
.form-divider-first {
  @include rfb(padding-right, 3);
}

@media (max-width: $screen-small) {
  .form-container.form-stacked .form-divider {
    @include rfb(padding-left, 1);
    border-top: 1px solid $color-grey-300;

    .form-row {
      padding: 0;

      .form-label {
        padding: 0;
        border: 0;
      }
    }
  }

  .form-divider-first {
    @include rfb(padding-right, 1);
  }
}

/**
 * Preserving whitespace in non-input element
 */
.text-prewrap {
  white-space: pre-wrap;
}

/**
 * Removing wrap on text
 */
.text-nowrap {
  white-space: nowrap;
}

/**
 * Reset list
 * Where is this used? Seems depricated
 */
.reset-list, .reset-list ul {
  @include reset-list();
}

/* display-inline class to allow for example action links next to titles */
.display-inline {
  display: inline-block !important;
}

/* display-block class helps angular DOM components to act regulary so they dont mess up other css */
.display-block {
  display: block !important;
}

// Util class for text formatting
.pre-line {
  white-space: pre-line;
}

.line-height-150 {
  line-height: 150%;
}
.line-height-200 {
  line-height: 200%;
}

// Util class for VEO type ahead
.mark {
  font-weight: 500;
  color: $color-blue-a500;
}

/*
 * util class to handle long URLs in a limited space without breaking regular words into unlogical pieces 
 */
.wrap-text {
  overflow-wrap: break-word;
}

/*
* util class to have a list without any styling
*/
.list-none {
  list-style: none;
}

.uppercase {
  text-transform: uppercase;
}

/*
* util class to revert back to standard font size (for example, using regular text in h1)
*/
.font-size-normal {
  font-size: 1rem;
}
.font-weight-strong {
  font-weight: 700;
}

.font-clickable-title {
  font-weight: 500;
  cursor: pointer;
}

// Util to make bullet list align to left
ul.list-align, ol.list-align {
  padding-left: 1.2rem;
}

// Util to make list align to left when there are no bullets
ul.list-none, ol.list.none {
  padding-left: 0;
  list-style: none;
}

// Util for normalising text
.text-regular {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

//disable "look" for any element
.not-allowed {
  cursor: not-allowed !important;
  color: $color-muted !important;

  &:hover {
    text-decoration: none !important;
    color: $color-muted !important;
  }

  &:visited {
    color: $color-muted !important;
  }

  &:focus {
    outline: 0 !important;
  }
}

//specific disable look for blockdisplay
.block-display-head .not-allowed {
  opacity: .5 !important;
  color: $color-white !important;
}

//specific disable look for blockdisplay in panel (don't ask: #Steve-o put some weird stuff in IBO-plan)
.panel.panel-page .block-display-head .not-allowed {
  opacity: .5 !important;
  color: $color-muted !important;
}

// make element go full-width 
.full-width {
  width: 100%;
}

// Gigantic text
.giga-text {
  font-size: 10rem;
}

.no-focus {
  outline: none;
}

.underline-descender {
  text-underline-position: under;
}

/*
* Positioning shortcuts
*/
.pos-relative {
  position: relative;
}

.pos-top-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/*
* ACCESIBILITY HACKS
*/

//for buttons that are sneaky and pretend not to be there
button.ninja {
  padding: 0;
  color: inherit;
  border: 0;
  outline: 0;
  background-color: transparent;
}

