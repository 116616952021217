//
// PAGINATION
// ------------------------------------------------

.pagination {
  padding: 0;
  &.pagination-block {
    border: 1px solid $color-grey-300;
    background-color: $color-grey-200;
  }
  .pagination-label-hide {
    cursor: default;
    opacity: 0;
  }
}

.pagination a {
  color: $color-blue-a500;
  border: 0;
}

.pagination a:focus {
  border: 0;
}

.pagination li {
  display: none;
}

.pagination a, .pagination li > span {
  @include rfb(padding, 1);
  @include font-size(14);
  line-height: 1.5rem;
  display: block;
  border-radius: 2px;
}

.pagination ul {
  font-weight: 500;
  position: relative;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.pagination .prev, .pagination .next {
  display: inline-block;
  vertical-align: middle;
}

.pagination .prev span, .pagination .next span {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

@media (min-width: 42.5em) {
  .pagination li {
    display: inline-block;
    vertical-align: middle;
  }

  .pagination .prev a, .pagination .next a {
    @include rfb(padding-left, 1);
    @include rfb(padding-right, 1);
  }

  .pagination .prev {
    float: left;
  }

  .pagination .next {
    float: right;
  }
}

//Standalone pagination with text centered between prev and next - DEPRECATED 27/01/2019
.pagination-centered {
  margin-bottom: .5rem;
  text-align: center;
  
  .prev-mobile, .next-mobile {
    display: none;
  }
}

@media (max-width: $tablet-large) {
  .pagination-centered {
    .prev, .next {
      display: none;
    }    
    
    .prev-mobile, .next-mobile {
      display: inline-block;
    }
  }
}
//end standalone

//NEW PAGINATION CENTERED HTML + ARROW BUTTONS
.pagination-centered-arrows {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(flex-end);

  .prev-mobile, .next-mobile {
    display: none;
  }

  .center-link {
    @include flexbox(inline-flex);
    @include flex-direction(column);
    @include align-items(center);
    @include flex-grow(1);
  }
}

@media (max-width: $tablet-large) {
  .pagination-centered-arrows {

    .prev, .next {
      display: none;
    }

    .prev-mobile, .next-mobile {
      font-size: 1.25rem;
      line-height: 0;
      display: inline-block;
      margin: 0;
      padding: .25rem 0 .25rem .25rem;
      color: $color-blue;
      border: 1px solid $color-blue;
      border-radius: 5px;
      background: $color-white;

      &:hover {
        color: $color-white;
        background: $color-blue;
      }
    }
  }
}
//END NEW PAGINATION CENTERED HTML + ARROW BUTTONS

.pagination-simple .prev {
  float: left;
  border-right: 1px solid $color-grey-300;
}

.pagination-simple .prev, .pagination-simple .next {
  position: relative;
  width: 50%;
}

.pagination-complex li {
  display: none;
}

@media (min-width: 42.5em) {
  .pagination-complex li {
    display: inline-block;
  }
}

.pagination-complex .prev, .pagination-complex .next {
  width: auto;
}

.pagination-alphabetical {
  @include rfb(padding, .25, .5);
}

@media (min-width: 42.5em) {
  .pagination-alphabetical {
    @include rfb(padding-top, .5);
    @include rfb(padding-bottom, .5);
  }
}

@media (min-width: 60em) {
  .pagination-alphabetical {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.pagination-alphabetical ul {
  text-align: left;
}

@media (min-width: 60em) {
  .pagination-alphabetical ul {
    text-align: center;
  }
}

.pagination-alphabetical li {
  display: inline-block;
}

.pagination-alphabetical a, .pagination-alphabetical li > span {
  @include rfb(padding-top, .25, .5);
}

@media (min-width: 42.5em) {
  .pagination-alphabetical a, .pagination-alphabetical li > span {
    @include rfb(padding-top, .5);
    @include rfb(padding-bottom, .5);
  }
}

@media (min-width: 60em) {
  .pagination-alphabetical a, .pagination-alphabetical li > span {
    @include rfb(padding-top, 1);
    @include rfb(padding-bottom, 1);
  }
}
