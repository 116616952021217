/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
html {
  font-size: $font-size-base;
}

body {
  @include font-size($font-size-base);
  @include font-smoothing();
  font-family: $brand-font-regular;
  margin: 0;
  padding: 0;
  color: $color-default;
  background-color: $color-grey-100;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, h2, h3, .h1, .h2, .h3 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed / 2);
}

h4, h5, h6, .h4, .h5, .h6 {
  margin-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 2);
}

h1, .h1 {
  font-size: $font-size-h1;
}

h2, .h2 {
  font-size: $font-size-h2;
}

h3, .h3 {
  font-size: $font-size-h3;
}

h4, .h4 {
  font-size: $font-size-h4;
}

h5, .h5 {
  font-size: $font-size-h5;
}

h6, .h6 {
  font-size: $font-size-h6;
}

/*a reset for all header tags where they are only used for accesibility, but needs normal or different styling*/
h1.text-regular,
h2.text-regular,
h3.text-regular,
h4.text-regular,
h5.text-regular,
h6.text-regular {
  @include font-size($font-size-base);
  @include font-smoothing();
  font-family: $brand-font-regular;
  margin: 0;
  padding: 0;
  color: $color-default;
}

p {
  margin: 0 0 ($line-height-computed / 2);
}

strong, b {
  font-weight: 500;
}

small, .small {
  @include font-size(14, 24);
  //@include rfb(margin-bottom, 1); //why do we do this JJ-Cool? //removed by LV-Ray cause yolo
}

.mini {
  font-size: .75rem;
}

.meta {
  font-weight: normal;
  color: $color-muted;
}

.main-title {
  text-transform: uppercase;
  color: $color-primary;
}

.sub-title {
  font-size: .9rem;
  color: $color-grey-700;
}

.field-passive {
  color: $transparent-black-30;

  &:after {
    @extend .icon-after;
    padding-left: .3em;
    content: char-from-code($icon-warning);
    vertical-align: middle;
    color: $color-warning;
  }
}

.field-passive-black:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-warning);
  vertical-align: middle;
  color: $color-warning;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-line-through {
  text-decoration: line-through;
}

.status-danger {
  color: $color-danger !important;
}

.status-warning {
  color: $color-orange !important;
}

.status-success {
  color: $color-green !important;
}


