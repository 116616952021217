@mixin border() {
  border: 1px solid $color-red-a500;
}

.knipperlicht-ola {
  margin-top: 20px;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, $color-blue-200 .5rem, $transparent-black-00 .125rem);
  background-repeat: repeat-y;
  background-position: 28px 0;

  .-linecap {
    position: relative;
    left: 24px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $color-blue-200;

    &.-first {
      top: -8px;
    }
    &.-last {
      top: 0;
      margin-top: 16px;

      .-more {
        font-size: 1rem;
        position: relative;
        top: -4px;
        margin-left: 39px;
        white-space: nowrap;
      }
    }
  }

  .-item {
    display: flex;
    width: 100%;
    min-height: 54px;

    .-content {
      .-date {
        font-size: .8rem;
        margin-bottom: 4px;
        opacity: .7;
      }
      .-title {
        font-size: 1.25rem;
        margin-top: 12px;
        //margin-bottom: 8px;
      }
      .-description {
        font-size: .9rem;
        //todo
      }
    }

    .-ola-type {
      &-knipperlicht-hoog {
        background-color: $color-red;
        .-sub-bullet {
          //top: 0;
          //width: 26px;
          //height: 26px;
          //border-radius: 14px;
          //background-color: $color-grey-100;
          top: 0;
          width: 22px;
          height: 22px;
          border-radius: 14px;
          background-color: $color-blue-200;
        }
        span {
          @extend .icon-knipperlicht;
        }
      }
      &-knipperlicht-medium {
        background-color: $color-orange;
        .-sub-bullet {
          top: 0;
          width: 22px;
          height: 22px;
          border: 8px solid $color-blue-200;
          border-radius: 19px;
          background-color: $color-orange;
        }
        span {
          @extend .icon-knipperlicht-medium;
        }
      }
      &-knipperlicht-laag {
        background-color: $color-yellow;
        .-sub-bullet {
          top: 0;
          width: 8px;
          height: 8px;
          border-radius: 14px;
          background-color: $color-blue-200;
        }
        span {
          @extend .icon-knipperlicht-low;
        }
      }
      &-ola {
        background-color: $color-lightgreen-600;
      }
    }
  }
  .-bullet {
    position: relative;
    top: 0;
    left: 8px;
    display: flex;
    justify-content: space-around;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border: 8px solid $color-blue-200;
    border-radius: 42px;
    background-color: $color-blue;

    align-items: center;
    flex-shrink: 0;


    span {
      font-size: 1.2rem;
      position: relative;
      top: -2px;
      color: $color-white;
    }
  }
}

