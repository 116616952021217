//
// Action links
// ---------------------------------------------------

a.meta {
  color: $color-muted;

  &:visited {
    color: darken($color-muted, 15%);
  }

  &:hover {
    transition: (color .2s ease-out);
    color: darken($color-muted, 15%);
  }

  &:focus {
    color: $color-blue-700;
    outline: thin dotted;
  }
}

.back-link-container {
  a {
    float: right;
  }
}

.icon-after:after {
  font-family: 'icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;

  speak: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.action-lone {
  display: inline-block;
  overflow: hidden;
  width: 1em;
  white-space: nowrap;
}

.action-lone:before {
  margin-right: 5em;
}

.action-delete.action-lone {
  color: $color-red-900;
  &:hover {
    color: $color-red-700;
  }
}

.action-download:before {
  @extend .icon;
  @extend .icon-attachment;
  padding-right: .3em;
  vertical-align: middle;
}

.action-two-arrows-right-left:before {
  @extend .icon;
  @extend .icon-two-arrows-right-left;
  padding-right: .3em;
  vertical-align: middle;
}

.action-attachment:before {
  @extend .icon;
  @extend .icon-attachment;
  padding-right: .3em;
  vertical-align: middle;
}

.action-color-pallete:before {
  @extend .icon;
  @extend .icon-color-pallete;
  padding-right: .3em;
  vertical-align: middle;
}

.action-file:before {
  @extend .icon;
  @extend .icon-file;
  padding-right: .3em;
  vertical-align: middle;
}

.action-eye:before {
  @extend .icon;
  @extend .icon-eye;
  padding-right: .3em;
  vertical-align: middle;
}

.action-look:before {
  @extend .icon;
  @extend .icon-eye;
  padding-right: .3em;
  vertical-align: middle;
}

.action-look-strike:before {
  @extend .icon;
  @extend .icon-eye-strike;
  padding-right: .3em;
  vertical-align: middle;
}

.action-add:before {
  @extend .icon;
  @extend .icon-plus;
  padding-right: .3em;
  vertical-align: middle;
}

.action-edit:before {
  @extend .icon;
  @extend .icon-edit;
  padding-right: .3em;
  vertical-align: middle;
}

.action-filter:before {
  @extend .icon;
  @extend .icon-filter;
  padding-right: .3em;
  vertical-align: middle;
}

.action-filter-delete:before {
  @extend .icon;
  @extend .icon-filter-full-remove;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reload:before {
  @extend .icon;
  @extend .icon-reload;
  padding-right: .3em;
  vertical-align: middle;
}

.action-copy:before {
  @extend .icon;
  @extend .icon-copy-doc;
  padding-right: .3em;
  vertical-align: middle;
}

.action-user:before {
  @extend .icon;
  @extend .icon-user;
  padding-right: .3em;
  vertical-align: middle;
}

.action-warning:before {
  @extend .icon;
  @extend .icon-warning;
  padding-right: .3em;
  vertical-align: middle;
}


.action-menu:before {
  @extend .icon;
  @extend .icon-menu;
  padding-right: .3em;
  vertical-align: middle;
}

.action-bulb:before {
  @extend .icon;
  @extend .icon-bulb;
  padding-right: .3em;
  vertical-align: middle;
}

.action-mail:before {
  @extend .icon;
  @extend .icon-mail;
  padding-right: .3em;
  vertical-align: middle;
}

.action-certified:before {
  @extend .icon;
  @extend .icon-certified;
  padding-right: .3em;
  vertical-align: middle;
}

.action-study:before {
  @extend .icon;
  @extend .icon-study;
  padding-right: .3em;
  vertical-align: middle;
}

.action-threshold:before {
  @extend .icon;
  @extend .icon-threshold;
  position: relative;
  bottom: 2px;
  margin-right: 7px;
}


.action-attach:before {
  @extend .icon;
  @extend .icon-attachment;
  padding-right: .3em;
  vertical-align: middle;
}

.action-trash:before {
  @extend .icon;
  @extend .icon-cross;
  padding-right: .3em;
  vertical-align: middle;
}
.action-trashcan:before {
  @extend .icon;
  @extend .icon-trash;
  padding-right: .3em;
  vertical-align: middle;
}

.action-delete:before {
  @extend .icon;
  @extend .icon-trash; // should be trash icon JS 25/07/2020
  padding-right: .3em;
  vertical-align: middle;
}

.action-delete--red {
  color: $color-red-700;
  &:hover {
    color: $color-red;
  }
}

.action-cancel:before {
  @extend .icon;
  @extend .icon-x;
  padding-right: .3em;
  vertical-align: middle;
}

.action-compare:before {
  @extend .icon;
  @extend .icon-compare;
  padding-right: .5em;
  vertical-align: middle;
}

.action-submit:before {
  @extend .icon;
  @extend .icon-check;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reopen:before {
  @extend .icon;
  @extend .icon-arrow-up-bold;
  padding-right: .3em;
  vertical-align: middle;
}

.action-settings:before {
  @extend .icon;
  @extend .icon-cog;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reply:before {
  @extend .icon;
  @extend .icon-reply;
  padding-right: .3em;
  vertical-align: middle;
}

.action-print:before {
  @extend .icon;
  @extend .icon-printer;
  padding-right: .3em;
  vertical-align: middle;
}

.action-export:before {
  @extend .icon;
  @extend .icon-export;
  padding-right: .3em;
  vertical-align: middle;
}

.action-search:before {
  @extend .icon;
  @extend .icon-search;
  padding-right: .3em;
  vertical-align: middle;
}

.action-back:before {
  @extend .icon;
  @extend .icon-arrow-left;
  padding-right: .3em;
  vertical-align: middle;
}

.action-lock:before {
  @extend .icon;
  @extend .icon-lock;
  padding-right: .3em;
  vertical-align: middle;
}

.action-show:before {
  @extend .icon;
  @extend .icon-arrow-down;
  padding-right: .3em;
  vertical-align: middle;
}

.action-hide:before {
  @extend .icon;
  @extend .icon-arrow-up;
  padding-right: .3em;
  vertical-align: middle;
}

.action-add-group:before {
  @extend .icon;
  @extend .icon-group-add;
  padding-right: .3em;
  vertical-align: middle;
}

.action-info:before {
  @extend .icon;
  @extend .icon-info;
  padding-right: .3em;
  vertical-align: middle;
}

.action-date:before {
  @extend .icon;
  @extend .icon-date;
  padding-right: .3em;
  vertical-align: middle;
}

.action-facebook:before {
  @extend .icon;
  @extend .icon-social-facebook;
  padding-right: .3em;
  vertical-align: middle;
}

.action-twitter:before {
  @extend .icon;
  @extend .icon-social-twitter;
  padding-right: .3em;
  vertical-align: middle;
}

.action-linkedin:before {
  @extend .icon;
  @extend .icon-social-linkedin;
  padding-right: .3em;
  vertical-align: middle;
}

.action-star:before {
  @extend .icon;
  @extend .icon-star;
  padding-right: .3em;
  vertical-align: middle;
}
.action-cycle:before {
  @extend .icon;
  @extend .icon-cycle;
  padding-right: .3em;
  vertical-align: middle;
}
.action-list:before {
  @extend .icon;
  @extend .icon-list;
  padding-right: .3em;
  vertical-align: middle;
}
.action-time:before {
  @extend .icon;
  @extend .icon-time;
  padding-right: .3em;
  vertical-align: middle;
}

.action-history:before {
  @extend .icon;
  @extend .icon-history;
  padding-right: .3em;
  vertical-align: middle;
}

.action-quick-menu:before {
  @extend .icon;
  @extend .icon-ellipsis;
  vertical-align: middle;
}

.action-verslag {
  @extend .icon;
  @extend .icon-note-text-pencil;
  vertical-align: middle;
}

.action-jobs {
  @extend .icon;
  @extend .icon-job;
  vertical-align: middle;
}

.action-select-all:before {
  @extend .icon;
  @extend .icon-multitick;
  padding-right: .3em;
  vertical-align: middle;
}

.action-deselect-all:before {
  @extend .icon;
  @extend .icon-multitick-checked;
  padding-right: .3em;
  vertical-align: middle;
}

// icon after
.action-warning-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-warning);
  vertical-align: middle;
}

.action-more:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-right);
  vertical-align: middle;
}

.action-jobs-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-job);
  vertical-align: middle;
}

.action-show-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-down);
  vertical-align: middle;
}

.action-link:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-link);
  vertical-align: middle;
}

.action-less:after, .action-hide-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-up);
  vertical-align: middle;
}

.action-go:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-right);
  vertical-align: middle;
}
.action-go-long:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-after);
  vertical-align: middle;
}

// Gebruikersbeheer
.action-trash-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-cross);
  vertical-align: middle;
}

.action-trashcan-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-trash);
  vertical-align: middle;
}

.action-edit-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-edit);
  vertical-align: middle;
}

.action-look-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-eye);
  vertical-align: middle;
}

.action-delete-after:after {
  @extend .icon-after;
  @extend .icon-error-after; //TODO: waarom stond hier .icon-error-after???? @ELVIRA??
  padding-left: .3em;
  vertical-align: middle;
}

// for when the link text needs to be neutral and only the icon needs to light up
a.neutral-link {
  color: $default-text-color;
  &:after {
    color: $nav-light-grey;
  }
  &:before {
    color: $nav-light-grey;
  }
  &:hover {
    color: $default-text-color;
    &:after {
      color: $color-link;
    }
    &:before {
      color: $color-link;
    }
  }
}

.action-toggle {
  @include rfb(margin-bottom, .5);
  padding: 0;
  list-style: none;
  border-bottom: 1px solid $transparent-black-05;

  .float-right {
    a {
      @include rfb(padding-right, 0);
    }
  }
}

/* action-add-rem should be removed, in the future use only action-line */
.action-add-rem, .action-line {
  @include rfb(margin-bottom, .5);
  font-size: 1em;
  font-weight: 500;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  border-bottom: 1px solid $transparent-black-05;

  a {
    @include rfb(padding-right, 5);
  }

  .float-right {
    a {
      @include rfb(padding-right, 0);
      text-transform: none;
    }
  }

  &:last-child {
    @include rfb(margin-bottom, 0);
  }

  a.icon-circle-add {
    @include rfb(margin-right, .5);
    color: $color-lightgreen;

    &:hover {
      color: $color-lightgreen;
    }
  }

  a.icon-x {
    @include rfb(margin-right, .25);
    color: $color-red-900;

    &:hover {
      color: $color-red-700;
    }
  }
}

.delegate-rights {
  a {
    float: right;
    clear: both;
  }

  a:last-child {
    @include rfb(margin-top, 1);
  }

  a.action-trash-after {
    color: $color-grey-900;

    &:after {
      color: $color-grey-300;
    }

    &:hover:after {
      color: $color-blue;
    }
  }
}

$meta-block-action-link: $color-grey-800;

.block-actions {
  float: right;
  width: auto;
  color: $meta-block-action-link;
}

.block-action {
  @include rfb(margin-left, 1);
  float: right;

  a {
    cursor: pointer;
    text-decoration: none;
    color: $meta-block-action-link;
  }
}

// .project-kp:before{
//   @extend .icon;
//   @extend .icon-star;
//   vertical-align: baseline;
//   padding-right: 0.3em;
// }
