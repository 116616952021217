.col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
  &.centered {
    float: none;
  }
}

.gridrow {
  @extend .clearfix;
  @include rfb(margin-left, -1);
  @include rfb(margin-right, -1);
}

.small-1 {
  width: 8.33333%;
}

.small-2 {
  width: 16.66667%;
}

.small-3 {
  width: 25%;
}

.small-4 {
  width: 33.33333%;
}

.small-5 {
  width: 41.66667%;
}

.small-6 {
  width: 50%;
}

.small-7 {
  width: 58.33333%;
}

.small-8 {
  width: 66.66667%;
}

.small-9 {
  width: 75%;
}

.small-10 {
  width: 83.33333%;
}

.small-11 {
  width: 91.66667%;
}

.small-12 {
  width: 100%;
}

@include screen() {
  .large-1 {
    width: 8.33333%;
  }
  .large-2 {
    width: 16.66667%;
  }
  .large-3 {
    width: 25%;
  }
  .large-4 {
    width: 33.33333%;
  }
  .large-5 {
    width: 41.66667%;
  }
  .large-6 {
    width: 50%;
  }
  .large-7 {
    width: 58.33333%;
  }
  .large-8 {
    width: 66.66667%;
  }
  .large-9 {
    width: 75%;
  }
  .large-10 {
    width: 83.33333%;
  }
  .large-11 {
    width: 91.66667%;
  }
  .large-12 {
    width: 100%;
  }
}

@include screen() {
  %large-1 {
    width: 8.33333%;
  }
  %large-2 {
    width: 16.66667%;
  }
  %large-3 {
    width: 25%;
  }
  %large-4 {
    width: 33.33333%;
  }
  %large-5 {
    width: 41.66667%;
  }
  %large-6 {
    width: 50%;
  }
  %large-7 {
    width: 58.33333%;
  }
  %large-8 {
    width: 66.66667%;
  }
  %large-9 {
    width: 75%;
  }
  %large-10 {
    width: 83.33333%;
  }
  %large-11 {
    width: 91.66667%;
  }
  %large-12 {
    width: 100%;
  }
}

.container {
  @include rfb(padding, 0, 1);
  position: relative;
  max-width: $container;
  margin-right: auto;
  margin-left: auto;
}

// Old container, here to prevent the container apocalypse.
.container-padding {
  @include rfb(padding-top, 2);
  @include rfb(padding-bottom, 2);
  &.container-footer-application {
    @include rfb(padding-bottom, 5.5);
  } // For YOLO'ers who want to put their breadcrumb INSIDE the container
  &.container-breadcrumb {
    @include rfb(padding-top, .5);
    .breadcrumb ol {
      @include rfb(padding-bottom, 1);
    }
  }
}

// New way of using containers!
main {
  @include rfb(padding-top, 2);
  @include rfb(padding-bottom, 2);
  &.container-footer-application {
    @include rfb(padding-bottom, 5.5);
  } // For YOLO'ers who want to put their breadcrumb INSIDE the container
  &.container-breadcrumb {
    @include rfb(padding-top, .5);
    .breadcrumb ol {
      @include rfb(padding-bottom, 1);
    }
  }
}

@include breakpoint($tablet-small max-width) {
  .is-xsmall-hidden {
    display: none;
  }
}

@include breakpoint($tablet-large max-width) {
  .is-small-hidden {
    display: none;
  }
}

@include breakpoint($screen-small max-width) {
  .is-medium-hidden {
    display: none;
  }
}

@include tabletLarge() {
  .is-medium-exposed, .is-medium-exposed.is-hidden {
    display: block !important;
  }
}

@include screen() {
  .is-big-hidden {
    display: none !important;
  }
  .is-big-exposed, .is-big-exposed.is-hidden {
    display: block !important;
    height: auto !important;
  }
}

.main-section-title {
  @include font-size(16, 24);
  @include rfb(margin-bottom, 1);
  color: $default-text-color;
  a {
    font-weight: 500;
  }
}

.container:after, .container:before, .navbar:after, .navbar:before, .nav:after, .nav:before, .row:after, .row:before {
  display: table;
  content: ' ';
}

.nav:after, .navbar:after, .row:after {
  clear: both;
}

.column-list {
  @extend %clear;
  html.csscolumns & {
    @include tabletLarge() {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    @include screen() {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    @include screenLarge() {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }
  li {
    @include rfb(padding-left, 1);
    @include tabletLarge() {
      float: left;
      width: 50%;
      html.csscolumns & {
        position: relative;
        float: none;
        width: auto;
      }
    }
    @include screen() {
      width: 33.33333%;
    }
    @include screenLarge() {
      width: 25%;
    }
  }
}

// Google app edits for Anne Everars (Calender component)
thead.fc-head {
  padding: .5rem 0;
}

th.fc-day-header.fc-widget-header.fc-sun, th.fc-day-header.fc-widget-header.fc-sat {
  background: $color-grey-300;
}

.container, .col {
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }
}

/* typical call-to-action text underneath main title on content pages */
.intro-cta {
  font-size: 1.15rem;
  line-height: 1.75rem;
  width: 65%;
}

// Full width blocks on mobile to prevent double gutters
//useage: put on ELEMENT that needs to break out, must be INSIDE a container and either inside a gridrow + col or else without instead of gridrow (so NEVER inside a gridrow directly => causes a bug on mobile with extra horizontal space)
@media (max-width: $screen-small) {
  .mob-full-width {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  /* RESET mobile for Elements that break out of the container on dekstop */
  /* still needed with cha,ges of 16/12/19? */
  .section-full-width {
    .inner-section-full-width {
      width: auto;
    }
  }
}

/* media q for elements that are full-width on every screen size */
/* everything up to screen-large size only needs 1rem neg margin and auto width*/
@media (max-width: $screen-large) {
  body.full-width-scrollbug-helper {
    overflow-x: hidden;
  }

  .section-full-width {
    width: 100vw;
    margin-left: -1rem;

    .inner-section-full-width {
      width: auto;
      margin-right: auto;
      margin-left: auto;
      padding: 0 1rem;
    }
  }
}

/* everything larger than our 1300px container needs calculated margin en fixed width*/
@media (min-width: $screen-large) {
  body.full-width-scrollbug-helper {
    overflow-x: hidden;
  }

  .section-full-width {
    width: 100vw;
    //1268 px because largest screen is (1300px - 32px padding)
    margin-left: calc(((100vw - 1268px) / 2) * -1); 

    .inner-section-full-width {
      width: 1300px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 1rem;
    }
  }
}

