.icon-menu-newnav {
  @extend .icon;
  @extend .icon-mobile-menu;
}

.icon-menu-help {
  @extend .icon;
  @extend .icon-help;
}

.icon-menu-search {
  @extend .icon;
  @extend .icon-search;
}

.icon-menu-bigarrow {
  @extend .icon;
  @extend .icon-after;
}

.icon-menu-close {
  @extend .icon;
  @extend .icon-close;
}

// Flanders header
.newnav-flanders {
  @extend .clearfix;
  position: relative;
  width: 100%;
  background: $color-white;

  // consultant version of flanders header
  &.-consultant {
    padding-top: .5rem;
    padding-bottom: .5rem;

    a {
      font-size: .9rem;
      color: $color-orange;

      &:hover {
        color: $color-blue;
      }
    }
  }

  .flanders-right {
    position: relative;
    float: right;
    overflow: hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;

    .search-triangle {
      display: none;
    }
  }

  .flanders-link {
    font-weight: 500;
    position: relative;
    float: left;
    overflow: hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 3rem;
    color: $color-black;
    border-right: 0;
    background: $color-white url('https://www.vdab.be/sites/web/themes/webresponsive/img/vvm-logo-mark-dark.svg') center left no-repeat;

    &:hover {
      color: $color-blue;
    }

    &::after {
      position: absolute;
      display: inline-block;
      width: 1px;
      height: 3.3rem;
      margin: -1.1rem 0 -1.1rem -6.2rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey;
    }
  }

  a.flanders-context {
    font-size: .9rem;
    display: inline-block;
    margin-right: 2rem;
    color: $color-black;

    &.-werkgevers {
      @extend .icon;
      @extend .icon-job;
    }

    &.-werknemers {
      @extend .icon;
      @extend .icon-house-person;
    }

    &::after {
      display: inline-block;
      width: 1px;
      height: 3rem;
      margin: -1.1rem 0 -1.1rem 2rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey;
    }

    &:hover {
      color: $color-blue;
    }

    &:before {
      margin-right: .5rem;
      color: $color-grey;

      speak: none; // JAWS speak test
    }
  }

  a.flanders-help {
    @extend .icon;
    @extend .icon-help;
    font-size: .9rem;
    color: $color-black;

    &:hover {
      color: $color-blue;
    }

    &:before {
      margin-right: .5rem;
      color: $color-grey;

      speak: none; // JAWS speak test
    }

    &:after {
      display: inline-block;
      width: 20px;
      height: 3rem;
      margin: -1.1rem -1rem -1.1rem 2rem;
      content: '';
      transform: rotate(-20deg);
      border-left: 1px solid $color-grey;
      background: $color-grey-200;
    }
  }

  input {
    font-size: .9rem;
    display: inline-block;
    width: 300px;
    height: 2.54rem;
    margin-top: -.5rem;
    margin-right: -1px;
    margin-bottom: -.5rem;
    padding-right: 2rem;
    padding-left: 1rem;
    border: 0;
    outline: none;
    background: $color-grey-200;

    &::-webkit-input-placeholder {
      font-size: .9rem;
      color: $color-grey-900;
    }

    &:-ms-input-placeholder {
      font-size: .9rem;
      color: $color-grey-900;
    }

    &::-moz-placeholder {
      font-size: .9rem;
      color: $color-grey-900;
    }

    &::placeholder {
      font-size: .9rem;
      color: $color-grey-900;
    }
  }

  .search-button {
    @extend .icon;
    @extend .icon-search;
    position: absolute;
    top: .5rem;
    right: .6rem;
    padding: 0 6px 2px 6px;
    color: $color-white;
    border-radius: 2px;
    background-color: $color-blue;

    &:before {
      color: $color-white;
    }

    &:hover {
      background-color: $color-blue-900;
    }

    span {
      display: none;
    }
  }
}

// Flanders footer
.newnav-flanders-footer {
  @extend .clearfix;
  position: relative;
  width: 100%;
  background: $color-white;

  .flanders-link {
    font-weight: 400;
    position: relative;
    float: left;
    overflow: hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 3rem;
    color: $color-black;
    border-right: 0;
    background: $color-white url('https://www.vdab.be/sites/web/themes/webresponsive/img/vvm-logo-mark-dark.svg') center left no-repeat;

    &:hover {
      color: $color-blue;
    }

    &::before {
      position: absolute;
      left: 2rem;
      display: inline-block;
      width: 1px;
      height: 3.3rem;
      margin-top: -1rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey;
    }
  }

  .flanders-disclaimer {
    font-weight: 400;
    position: relative;
    float: left;
    overflow: hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 2.2rem;
    color: $color-black;
    border-right: 0;

    &:hover {
      color: $color-blue;
    }

    &::before {
      position: absolute;
      left: 1rem;
      display: inline-block;
      width: 1px;
      height: 3.3rem;
      margin-top: -1rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey;
    }
  }

  .float-right {
    overflow: hidden;
  }

  a.flanders-partners {
    @extend .icon;
    @extend .icon-handshake;
    font-size: .9rem;
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 2rem;
    color: $color-black;

    &::after {
      display: inline-block;
      width: 1px;
      height: 3.3rem;
      margin: -1.1rem 0 -1.1rem 2rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey;
    }

    &:hover {
      color: $color-blue;
    }

    &:before {
      margin-right: .5rem;
      color: $color-grey;
    }
  }

  a.flanders-context {
    font-size: .9rem;
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 2rem;
    color: $color-black;

    &.-werkgevers {
      @extend .icon;
      @extend .icon-job;
    }

    &.-werknemers {
      @extend .icon;
      @extend .icon-house-person;
    }

    &:hover {
      color: $color-blue;
    }

    &:before {
      margin-right: .5rem;
      color: $color-grey;
    }
  }

  .flanders-lang {
    float: left;
    overflow: hidden;
    padding: .5rem 2rem;

    &:before {
      display: inline-block;
      width: 1px;
      height: 3.3rem;
      margin: -1.1rem 1rem -1.1rem -1rem;
      content: '';
      transform: rotate(-20deg);
      background: $color-grey-500;
    }

    span {
      font-weight: 700;
    }

    a {
      color: $color-black;

      span {
        font-size: 22px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    span, a {
      padding: 0 .15rem;
    }
  }
}

// Main blue top navbar
.newnav-navbar {
  @extend .clearfix;
  z-index: 9999;
  background: $color-blue;

  .navbar-left {
    display: inline-block;
    float: left;
  }

  .navbar-logo {
    display: inline-block;
    float: left;
    width: 5rem;
    margin: .75rem 0;
    padding: 2rem;
    background-color: transparent;
    background-image: url('//www.vdab.be/sites/web/themes/webresponsive/img/new-vdab-logo-white.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    &:hover {
      opacity: .9;
    }

    span {
      // WCAG aa
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }

  .navbar-mainmenu {
    display: inline-block;
  }

  .navbar-mobilemenu {
    display: none;
  }

  a.navbar-mainlink {
    font-weight: 500;
    display: inline-block;
    margin: 2rem;
    margin-right: 0;
    padding-left: 2.25rem;
    color: $color-white;
    border-left: 1px solid $color-grey-400;

    &.-mlb {
      display: none;
    }

    &:nth-child(3) {
      padding-left: 0; // link that needs no border is 3th link
      border: 0;
    }

    // needed?
    &:hover {
      text-decoration: underline;
    }
  }

  a.navbar-homelink, a.navbar-close, a.navbar-sublink {
    display: none;
  }

  // this link is also in flanders header so not visible on desktop
  .navbar-context {
    display: none;
  }

  .navbar-rightmenu {
    float: right;

    ul:first-of-type {
      padding: 0;
    }

    // fix for dropdown menu loged in state
    ul.dropdown-menu.hs-slide-down {
      position: absolute;
      top: 4.5rem;
      right: 0;
    }
  }

  //SVE -> added id to inschrijven... and hide it
  /*#subscribe-block {
    display: none;
  }*/

  .navbar-profile {
    @extend .icon;
    @extend .icon-user;
    display: block;
    float: left;
    padding: 2rem;
    color: $color-white;
    background: $color-blue-900;

    &:hover {
      background: $color-blue-700;
    }

    &:before {
      margin-right: .5rem;
    }

    // Toggle class -user to show aanmelden or logged in state
    &.-user {
      @extend .action-show-after;

      &:after {
        padding-left: 1rem;
      }
    }
  }

  .navbar-headlink {
    @extend .icon;
    @extend .icon-menu-bigarrow;
    display: block;
    float: left;
    padding: 2rem;
    color: $color-white;
    background: $color-turquoise;

    &:hover {
      background: $color-turquoise-a500;
    }

    &:before {
      margin-right: .5rem;
    }
  }

  .navbar-headlink-mlb {
    @extend .icon;
    @extend .icon-menu-bigarrow;
    display: none; // for drupal
    float: left;
    padding: 2rem;
    color: $color-white;
    background: $color-blue;

    &:hover {
      background: $transparent-white-25;
    }

    &:before {
      margin-right: .5rem;
    }
  }

  // general menu item for consulent navbar
  .navbar-menuitem {
    display: block;
    float: left;
    padding: 2rem;
    color: $color-white;
    background: $color-blue;

    &:hover {
      background: $color-blue-700;
    }
  }
  // special menu item for vewn header
  .navbar-menuitem-menu {
    @extend .icon;
    @extend .icon-menu;
    display: block;
    float: left;
    padding: 2rem;
    color: $color-white;
    background: $color-blue;
    &:hover {
      background: $color-blue-700;
    }
    &:before {
      margin-right: .5rem;
    }
  }
  .navbar-search-mobile, .navbar-help-mobile {
    display: none;
  }
}

.newnav-subnavbar {
  position: relative;
  padding: 1rem 0;
  background: $color-white;

  .container {
    position: relative; // fix triangle positioning
  }

  .subnavbar-triangle {
    position: absolute;
    top: -1.5rem;
    right: 7.5%;

    .triangle-up {
      width: 0;
      height: 0;
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid $color-white;
      border-left: .5rem solid transparent;
    }

  }

  .subnavbar-left {
    border-right: 1px solid $color-grey-400;

    ul {
      -webkit-columns: 100px 2;
      /* Chrome, Safari, Opera */
      -moz-columns: 100px 2;
      /* Firefox */
      columns: 100px 2;
    }
  }

  .subnavbar-right {
    padding-left: 5rem;
  }

  .subnavbar-maintitle {
    font-size: 1.1rem;
    font-weight: 500;
    display: block;
    width: 100%;
    margin-bottom: .5rem;
  }

  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: .3rem;

      a {
        color: $color-grey-800;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Special overrule for drupal (mobile menu fix)
#block-web-navigation-block-new-header-login, #block-web-navigation-block-new-header-login div.content, #block-web-navigation-block-new-header-login div.content ul:first-of-type {
  display: inline;
}

#block-web-navigation-block-new-header-login ul:first-of-type li {
  display: inline;
  list-style: none;
}

.huisstijl-page .region-flanders-search {
  display: inline-block;
}

.huisstijl-page .region-flanders-search div, .huisstijl-page .region-flanders-search form {
  display: inline;
}

.huisstijl-page .navbar-rightmenu ul:first-of-type {
  padding: 0;
}

@media (max-width: $screen-small) {
  .newnav-flanders .flanders-right div.region-flanders-search {
    display: block;
  }
}

// New footer
.newnav-footer {

  //flex-shrink: 0; // for sticky footer
  .footer-personal {
    @extend .clearfix;
    padding: 1.5rem 0;
    border-top: 1px solid transparentize($color-black, .9);
    border-bottom: 3px solid $color-blue-900;
    background: $color-white;

    .footer-column {
      float: left;
      width: 20%;
      margin-top: .7rem;
    }

    .profile-placeholder {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 2.1rem;
      float: left;
      width: 2.5rem;
      height: 2.5rem;
      margin-top: -.5rem;
      margin-right: 1rem;
      text-align: center;
      color: $color-grey-700;
      border: 3px solid $color-white;
      border-radius: 100px;
      background: $color-grey-400;
    }

    .footer-profile-title {
      font-size: 1.1rem;
      font-weight: 500;
      display: block;
      color: $color-blue-900;
    }

    .footer-subtitle {
      font-size: 1.1rem;
      font-weight: 500;
      display: block;
      margin-bottom: .5rem;
      color: $color-blue-900;

      &:hover {
        cursor: default;
      }
    }

    .footer-link {
      display: block;
      margin-bottom: .25rem;
      color: $color-blue-900;

      &:hover {
        color: $color-blue-500;
      }
    }
  }

  .footer-toplinks {
    padding: 5rem 0 1.5rem;
    background: $color-white;

    a.footer-profile {
      @extend .icon;
      @extend .icon-user;
      padding: .8rem 1rem;
      color: $color-white;
      border-radius: 2px;
      background: $color-blue-900;

      &:before {
        margin-right: .5rem;
      }

      &:hover {
        background: $color-blue-700;
      }
    }

    a.footer-headlink {
      @extend .icon;
      @extend .icon-after;
      margin-left: 1rem;
      padding: .8rem 1rem;
      color: $color-white;
      border-radius: 2px;
      background: $color-turquoise;

      &:before {
        margin-right: .5rem;
      }

      &:hover {
        background: $color-turquoise-a500;
      }
    }
  }

  .footer-main {
    @extend .clearfix;
    padding: 1.5rem 0;
    background: $color-grey-300;

    .footer-column {
      float: left;
      width: 20%;
    }

    .footer-logo {
      display: inline-block;
      float: left;
      width: 5rem;
      padding: 2rem;
      background-color: transparent;
      background-image: url('//www.vdab.be/sites/web/themes/webresponsive/img/new-vdab-logo-blue.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;

      span {
        // WCAG aa
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }

      &:hover {
        opacity: .9;
      }
    }

    .footer-mainlink {
      font-size: 1.1rem;
      font-weight: 500;
      display: block;
      margin-bottom: .5rem;
      color: $color-blue-900;

      &:hover {
        color: $color-blue-500;
      }
    }

    .footer-link {
      display: block;
      margin-bottom: .25rem;
      color: $color-blue-900;

      &:hover {
        color: $color-blue-500;
      }

      &.-mail {
        @extend .icon;
        @extend .icon-mail;

        &:before {
          margin-right: .3rem;
        }
      }

      &.-call {
        @extend .icon;
        @extend .icon-phone;

        &:before {
          margin-right: .3rem;
        }
      }

      &.-chat {
        @extend .icon;
        @extend .icon-chat;

        &:before {
          margin-right: .3rem;
        }
      }
    }

    .footer-free {
      font-size: .75rem;
      color: $color-green-700;
    }

    .footer-social {
      margin: 1.5rem 0 1rem;
      color: $color-blue-900;

      a {
        margin-right: .6rem;
        color: $color-blue-900;

        span {
          // WCAG aa
          position: absolute;
          overflow: hidden;
          clip: rect(0 0 0 0);
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
        }

        &:hover {
          color: $color-blue-500;
        }
      }
    }
  }
}

// special werkgevers menu for vewn
.werkgevers-menu {
  background: $color-white;
  .top-menu {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .title-link {
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
    margin-top: 1rem;
  }
  .sub-link {
    display: block;
    margin-top: .5rem;
  }
  .sub-menu {
    margin-bottom: 1rem;
  }
  .bottom-link {
    @extend .action-go;
    font-size: 1.25rem;
    font-weight: 500;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

// Behavior on mobile
@media (max-width: $screen-small) {

  .newnav-navbar .navbar-rightmenu .navbar-menuitem-menu {
    display: none;
  }

  .newnav-navbar a.navbar-headlink-mlb {
    display: none;
  }

  .newnav-navbar a.navbar-mainlink.-mlb {
    display: block;
  }

  // Fix for dropdown menu on mobile
  .newnav-navbar .navbar-rightmenu ul.dropdown-menu.hs-slide-down {
    top: 1.5rem;
    right: -6rem;
  }

  // footer Behavior
  .newnav-footer .footer-main .footer-column, .newnav-footer .footer-personal .footer-column {
    width: 100%;

    &:not(:last-child) {
      display: none;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  // make footer stuff disapear with drupal blocks
  #block-web-footer-block-footer-info, #block-web-footer-block-footer-menu {
    display: none;
  }

  .newnav-flanders-footer .flanders-link, .newnav-flanders-footer .flanders-lang {
    padding-left: 1rem;
    background: none;

    &:before {
      width: 0;
    }
  }

  .newnav-flanders-footer .flanders-disclaimer {
    display: none;
  }

  .newnav-flanders-footer .float-right {
    display: none;
  }

  .newnav-footer .footer-toplinks {
    display: none;
  }

  .newnav-footer .footer-main .footer-mainlink {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .newnav-footer .footer-main .footer-social {
    margin-top: 1rem;
  }

  .newnav-flanders {
    .flanders-link {
      display: none;
    }

    .flanders-context.-werkgevers, .flanders-context.-werknemers {
      display: none;
    }

    .flanders-help {
      display: none;
    }

    .flanders-right {
      position: absolute;
      z-index: 9999;
      top: 3rem;
      display: none;
      overflow: visible;
      width: 100%;

      .region-flanders-search {
        // This is only for drupal injected elements 
        display: inline-block;
      }

      .region-flanders-search div, .region-flanders-search form {
        display: inline;
      }

      .search-text-mobile {
        display: block;
      }

      .search-triangle {
        position: absolute;
        top: -.5rem;
        right: 4rem;
        display: block;

        .triangle-up {
          width: 0;
          height: 0;
          border-right: .5rem solid transparent;
          border-bottom: .5rem solid $color-blue-900;
          border-left: .5rem solid transparent;
        }
      }
    }

    input {
      z-index: 9999;
      width: 100%;
      padding-right: 5.5rem;
      box-shadow: 0 5px 6px 0 $transparent-black-20;
    }

    .search-button {
      z-index: 9999;
      top: 0;
      right: 0;
      padding: .52rem 1.5rem;
      color: $color-white;
      border-radius: 0;
      background: $color-blue-900;
      box-shadow: 0 5px 6px 0 $transparent-black-20;

      &:before {
        display: none;
      }

      span {
        display: inline-block;
      }
    }
  }

  // removing text and showing the user icon and arrow down
  .newnav-navbar .navbar-profile {
    float: right;
    padding: 1rem 0 1rem .5rem;
    background: $color-blue;

    span {
      // WCAG aa
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    &:before {
      margin-right: 0;
      padding-left: 1rem;
      border-left: 1px solid $transparent-white-50;
    }

    &.-user:before {
      display: inline-block;
    }

    &:after {
      margin-left: -.5rem;
      padding-left: 0 !important; // No way around it :'(
    }

    &:hover {
      background: $color-blue;
    }
  }

  // general menu item for consulent navbar
  .newnav-navbar .navbar-menuitem {
    padding: 1rem .5rem;
  }

  // hiding the main links and putting the hamburger icon in its place

  .navbar-mobilemenu:before {
    @extend .icon-menu-newnav;
    padding: 1.25rem 0;
    color: $color-white;

    speak: none; //test
  }

  .newnav-navbar .navbar-mobilemenu {
    display: inline-block;
    float: left;

    span {
      // WCAG aa
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;

      speak: normal; //test
    }
  }

  .newnav-navbar .navbar-mainmenu {
    font-size: 1.25rem;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: scroll;
    border-right: 5rem solid $transparent-black-30;
    background: transparent;

    a.navbar-close {
      font-weight: 500;
      display: block;
      margin: 0;
      padding: 1rem 1.5rem;
      color: $color-white;
      background: $color-blue;

      span {
        // WCAG aa
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }

      &:before {
        @extend .icon-menu-close;
      }
    }

    a.navbar-mainlink, a.navbar-homelink {
      font-weight: 500;
      display: block;
      margin: 0;
      padding: 1rem 1.5rem;
      color: $color-white;
      border: 0;
      background: $color-blue;
    }

    .navbar-mainlinks {
      display: block;
      padding-bottom: 1rem;
      background: $color-blue;
    }

    .navbar-sublinks {
      display: block;
      overflow-y: unset;
      height: auto;
      margin-bottom: 3rem;
      background: $color-grey-200;
    }

    a.navbar-sublink {
      font-size: 1rem;
      display: block;
      padding: .5rem 1.5rem;
      color: $color-blue-900;
      border-bottom: 1px solid $color-grey-400;
    }

    a.navbar-context {
      font-weight: 500;
      position: fixed;
      right: 5rem;
      bottom: 0;
      left: 0;
      display: block;
      margin: 0;
      padding: .75rem 1rem .75rem 1.5rem;
      color: $color-blue-900;
      border-top: 1px solid $color-grey-400;
      background: $color-white;

      &:after {
        float: right;
        margin-top: .1rem;
      }
    }
  }

  .newnav-navbar .navbar-search-mobile {
    display: inline-block;
    padding: 1rem .5rem;
    color: $color-white;

    span {
      // WCAG aa
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    &:before {
      @extend .icon-menu-search;
      margin-right: 0;
    }

    &.-close:before {
      @extend .icon-menu-close;
    }
  }

  // .newnav-navbar .navbar-help-mobile {
  //   display: inline-block;
  //   padding: 1rem .5rem;
  //   color: $color-white;
  //   span {
  //     display: none;
  //   }
  //   &:before {
  //     @extend .icon-menu-help;
  //     margin-right: 0;
  //   }
  // }

  // making the vdab logo smaller and positioning it in the center
  .navbar-left {
    width: 50%;

    .navbar-logo {
      float: right;
      width: 3.2rem;
      margin-top: 1rem;
      margin-right: -1.6rem;
      padding: .75rem;
    }
  }

  .newnav-flanders.-consultant a {
    margin: 0 1rem;
  }

  .navbar-rightmenu .navbar-headlink {
    position: absolute;
    z-index: 999; //seriously fam? 999? just to make sure? why not 9999, then atleast it's over 9000
    top: 3.5rem;
    left: 0;
    width: 100%;
    padding: .5rem 1rem;
  }
}

.co-browse {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 58px;
  height: 58px;

  > span {
    position: absolute;
    right: -15px;
    bottom: -20px;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: $color-turquoise;
    //sass-lint:disable-all
    box-shadow: 0 3px 5px rgba(0, 0, 0, .17);
    //sass-lint:enable-all
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 9px 15px;
    color: $color-white;
  }
} 