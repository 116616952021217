.hs-modal {
  position: fixed;
  z-index: 100; 
  top: 0;
  left: 0;
  display: none;
  //overflow: scroll; why would this be needed?
  width: 100vw;
  max-width: initial;
  height: 100vh;
  background-color: transparentize($color-grey-a500, .5);

  &.modal-show {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
  }

  /* DEPRECATED because included in the modal-show class - 30/08/2019 */
  .modal-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    background-color: $color-grey-a500;
  }

  .modal-container {
    position: relative;
    max-width: 1200px;
    border-radius: 5px;

    flex-grow: .75;

    &.modal-small {
      max-width: 600px;
    }

    &.modal-medium {
      max-width: 900px;
    }
  }

  .btn-close {
    position: absolute;
    z-index: 1;
    top: -.5rem;
    right: -.5rem;
  }

  .modal-content {
    max-height: 90vh;

    &.-isform {
      background-color: $color-blue-100; //needed if there is a typeahead
    }

    &.-overflow-exception { //only needed with a wrong implementation of the concept of modals aka when business forces you to put way too much content in a modal => try to avoid!!!
      overflow: auto;
    }
  }


  .modal-content>* {
    margin: 0;
    padding: 0;
  }

}

//add to the body when modal is shown
.modal-open {
  overflow: hidden;
}

/* General overlay - can be used for everything
* Use: just add on any div
* default: hidden
*/

.hs-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: transparentize($color-grey-a500, .5);
}

@media (max-width: $screen-small) {
  .modal-container {
    max-width: 90vw;
  }
}