$subnavbar-application-background: $color-white;
$subnavbar-application-link: $color-default;
$subnavbar-application-link-hover: $color-link;
$subnavbar-application-height: 46px;
$subnavbar-application-font-size: 14;
// $subnavbar-application-border-color: $nav-lighter-grey;
// $subnavbar-application-border-color: $color-grey-700;
$subnavbar-application-border-color: $color-grey;
$subnavbar-application-padding-lr: 1;
$subnavbar-arrow-size: 8px;

.subnavbar-application {
  @include font-size($subnavbar-application-font-size);
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: $subnavbar-application-background;

  @media (min-width: $screen-small) {
    position: relative;
    height: $subnavbar-application-height;
    border-bottom: 1px solid $subnavbar-application-border-color;
  }
}

/* .subnavbar-wrapper
  -----------------------*/
.subnavbar-wrapper {
  background: $subnavbar-application-background;

  @media (min-width: $screen-small) {
    @include rfb(margin, 0, auto);
    position: relative;
    max-width: $container;
    margin-right: auto;
    margin-left: auto;
    background: none;
  }
}

/* .subnavbar-items (ul)
  -----------------------*/
.subnavbar-items {
  margin: 0;
  padding: 0;
  list-style: none;
  background: transparent;

  @media (min-width: $screen-small) {
    @include clearfix();
    line-height: $subnavbar-application-height;
    height: $subnavbar-application-height;
    text-align: right;
    background: transparent;
  }
}

/* .subnavbar-item (li)
  -----------------------*/
.subnavbar-item {
  position: relative;
  border-bottom: 1px solid $subnavbar-application-border-color;

  &.active::after {
    @include triangle-right($subnavbar-application-border-color, $subnavbar-arrow-size);
    line-height: $subnavbar-application-height;
    bottom: ($subnavbar-application-height/2) - $subnavbar-arrow-size;
  }

  @media (min-width: $screen-small) {
    line-height: $subnavbar-application-height;
    display: inline-block;
    float: left;
    height: 100%;

    &.active::after {
      @include triangle($subnavbar-application-border-color, $subnavbar-arrow-size);
      line-height: $subnavbar-application-height;
    }
  }
}

/* .subnavbar-item a
  -----------------------*/
.subnavbar-item a {
  @include rfb(padding, .8, $subnavbar-application-padding-lr);
  display: block;
  width: 100%;
  color: $subnavbar-application-link;

  @media (min-width: $screen-small) {
    @include rfb(padding, .8, $subnavbar-application-padding-lr);
    line-height: 1;
    display: inline-block;
  }

  &:hover {
    color: $subnavbar-application-link-hover;
  }
}
