
$subnavbar-background: $color-white;
$subnavbar-height: 312px;
$subnavbar-font-size: 16;
$subnavbar-heading-font-size: 21;
$subnavbar-border-color: $nav-border;
$subnavbar-padding-lr: .6;

/* .subnavbar
-----------------------*/
.subnavbar {
  @extend .clearfix;
  @include font-size($subnavbar-font-size);
  overflow: hidden;
  width: 100%;
  max-height: 500px;
  -webkit-transition: all .5s cubic-bezier(.7, 0, .3, 1);
  -moz-transition: all .5s cubic-bezier(.7, 0, .3, 1);
  -o-transition: all .5s cubic-bezier(.7, 0, .3, 1);
  transition: all .5s cubic-bezier(.7, 0, .3, 1);
  border-bottom: 1px solid $subnavbar-border-color;
  background-color: $subnavbar-background;

  &.collapsed {
    display: block;
    overflow: hidden;
    max-height: 0;
  }

  ul {
    @include rfb(padding, 0);
    @include rfb(margin, 0);
    list-style: none;
  }

  li {
    @include rfb(padding, 0);
    @include rfb(margin, 0);
    list-style: none;
  }
}

.subnavbar-nav > li > a {
  @include rfb(padding, .1, 1.3, .1, 1.3);
  position: relative;
  display: inline-block;
  color: $nav-light-grey;

  &::before {
    @include rfb(padding-left, .4);
    font-family: 'icons';
    font-weight: normal;
    font-style: normal;
    position: absolute;
    left: 0;
    display: inline-block;
    content: char-from-code($icon-arrow-right);
    vertical-align: middle;
    text-decoration: inherit;
    text-transform: none;

    speak: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    color: $color-link;
  }
}

/* .subnavbar-top
-----------------------*/
.subnavbar-top, .subnavbar-top-4 {
  @include rfb(padding-bottom, 1);
  border-bottom: 1px solid $subnavbar-border-color;
}

.subnavbar-top > ul > li {
  @include rfb(padding, 2, .5, .5, .5);
  float: left;
  width: 19.06672%;
  margin-top: O;
  margin-right: .93%;
  list-style: none;
  border-bottom: 0 none;
}

// Special case with only 4 subitems for extranet partners
.subnavbar-top-4 > ul > li {
  @include rfb(padding, 1.5, 1, 0);
  float: left;
  width: 25%;
  margin: 0;
  list-style: none;
  border-bottom: 0 none;
}

.subnavbar-top-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  list-style-image: none;
}

/* .subnavbar-bottom
-----------------------*/
.subnavbar-bottom {
  @include rfb(padding-bottom, 1);
}

.subnavbar-bottom > ul > li {
  @include rfb(padding, 1, .5, .5, .5);
  float: right;
  width: auto;
  margin-right: 0;
  list-style: none;
  border-bottom: 0 none;

  &.first {
    float: left;
  }
}

/* .subnavbar-title
-----------------------*/
a.subnavbar-title {
  @include font-size($subnavbar-heading-font-size);
  color: $nav-dark-blue;

  &:hover {
    color: $color-link;
  }
}

a.subnavbar-title::after {
  @include rfb(padding-left, .4);
  font-family: 'icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  content: char-from-code($icon-arrow-right);
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;

  speak: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// RESPONSIVE
@media (max-width: $screen-small) {
  .subnavbar-top ul > li > ul {
    display: none;
  }

  .subnavbar-top ul > li {
    @include rfb(padding, .5, 1);
    clear: left;
    width: 100%;
    border-bottom: 1px solid $subnavbar-border-color;
  }

  .subnavbar-top {
    @include rfb(padding-bottom, 0);
    border: 0;
  }

  .subnavbar-top-4 ul > li > ul {
    display: none;
  }

  .subnavbar-top-4 ul > li {
    @include rfb(padding, .5, 1);
    clear: left;
    width: 100%;
    border-bottom: 1px solid $subnavbar-border-color;
  }

  .subnavbar-top-4 {
    @include rfb(padding-bottom, 0);
    border: 0;
  }

  .subnavbar-bottom {
    @include rfb(padding-bottom, 0);
    border-top: 0;
  }

  .subnavbar-bottom ul > li {
    @include rfb(padding, .5, 1);
    clear: left;
    width: 100%;
    border-bottom: 1px solid $subnavbar-border-color;
  }
}
