/**
 * Slat
 */
.slat-header {
  @include rfb(padding-bottom, 0);
  @include rfb(margin-bottom, 1);
  color: $color-muted;
  border-bottom: 1px solid $transparent-black-10;
  background: $color-white;
}

.slat-inner {
  @include rfb(padding, .5, .7);
  @include rfb(margin, .5, 1);

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }

  .slat-inner-main {
    width: 80%;
  }

  .slat-inner-actions {
    float: right;

    a {
      @include rfb(margin-left, 1);
    }
  }
}

.slat {
  @include rfb(padding-bottom, 1);
  @include rfb(margin-bottom, 1);
  border-bottom: 1px solid $transparent-black-10;
  background: $color-white;
  &.slat-standalone {
    @include rfb(padding, 1, 1.5);
    border: 1px solid $transparent-black-10;
  }

  .slat-title {
    @include font-size(18.4px); //font-size Vind een job
    font-weight: 500;
    margin: 0;
  }

  .slat-description {
    width: 70%;
  }

  input {
    @include rfb(margin-right, .5);
  }

  img {
    float: right;
    width: auto;
  }

  &:not(.slat-standalone):last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
}
