// RESET LIST
// - - - - - - - - - - - - - - - - - - - -
// Remove/reset the browser default styling
// on list elements.
//
// This mixin is @included in the .reset-list class
// located in _classes.scss.
//
// Example:
// --------
// .ul {
//   @extend .reset-list;
// }
//
// This mixin will remove the list-bullets,
// so its advised to combine this with .arrow-list.
//
// Example:
// --------
// .ul {
//   @extend .reset-list;
//   @extend .arrow-list;
// }
//
@mixin reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  list-style-image: none;

  li.leaf, li.collapsed, i.expanded, li {
    list-style: none;
    list-style-type: none;
    list-style-image: none;
  }
}
