//NEW FULL WIDTH
.form-full-width input {
  width: 100%;
}

// CSS for angular datepicker
// Use of '!important' here is to fix the styling in the form-search forms
.field-date {
  // to fix datepicker positioning bug
  position: relative;

  // the disabled days before and after a month
  .text-muted {
    color: $transparent-black-15;
  }

  ul {
    @include rfb(margin, 0);
    @include rfb(padding, 0);
    background-color: $color-blue-100;
    //border-radius:10px;
  }

  thead {
    width: 600px;
    border-bottom: 1px solid $color-grey-300;
    background-color: $color-white;
  }

  thead tr:first-child:hover {
    color: $color-blue;
  }

  thead th {
    font-weight: normal;
    padding-left: 8px;
    text-align: center;
  }

  thead tr:first-child {
    th:first-child {
      text-align: left;
    }

    th:last-child {
      text-align: right;
    }
  }

  td {
    padding: 0 !important;
    border-right: 1px solid $color-grey-300;
  }

  tbody tr {
    border-bottom: 1px solid $color-grey-300;
  }

  tbody tr:last-child {
    border: 0;
  }

  thead button {
    border: 0;
    background-color: $color-white !important;
  }

  thead button i.glyphicon-chevron-left:before {
    @extend .icon;
    @extend .icon-arrow-left;
    vertical-align: middle;
    color: $color-blue;
  }

  thead button i.glyphicon-chevron-right:before {
    @extend .icon;
    @extend .icon-arrow-right;
    vertical-align: middle;
    color: $color-blue;
  }

  tbody button {
    @include rfb(padding, .5);
    border: 0;
    background-color: $color-blue-100 !important;
  }

  tbody button:hover {
    @include rfb(padding, .5);
    color: $color-white;
    background-color: $color-blue !important;
  }

  tbody button:disabled {
    color: $transparent-black-15;
    border: 0;
    background-color: $color-blue-100;
  }
}

// Back button on singular form page
.form-back {
  @include rfb(margin, 1, 1, 1, 0);
  font-size: $font-size-h4;
}

.form-back:before {
  @extend .icon;
  font-size: $font-size-h4;
  content: char-from-code($icon-arrow-left);
}

// Container containing a form
.form-container {
  @include rfb(padding, 1, 2.4, 1.5);
  @include rfb(margin-bottom, 0);
  clear: both;
  width: auto;
  border: 1px solid $transparent-black-10;
  background: $color-blue-100;

  // The template picker form for the Compas application
  &.form-templatepicker {
    .form-section {
      @include rfb(padding-bottom, 1);
      @include rfb(margin-bottom, 1);
      border-bottom: 1px solid $transparent-black-10;
    }

    .form-section:last-child {
      @include rfb(padding-bottom, 0);
      @include rfb(margin-bottom, 0);
      border-bottom: 0;
    }

    table {

      th, td {
        @include rfb(padding, .7, .7, .7, 0);
      }

      td:last-child {
        @include rfb(padding-left, .7);
      }

      th:first-child {
        width: 20%;
      }

      td:first-child {
        text-align: right;
      }

      td input[type='text'] {
        @extend .field-input-styling;
      }

      td input[type='text']:focus {
        border-color: $color-primary;
        outline: none;
      }

      td a {
        @include rfb(margin-top, .1);
        @include rfb(margin-left, .5);
      }
    }
  }

  hr {
    height: 0;
    border: 0;
    border-top: 1px solid $transparent-black-10;
    border-bottom: 1px solid $transparent-white-25;
  }

  &.form-margin {
    @include rfb(margin-bottom, 2);
    @include rfb(margin-top, 2);
  }

  &.form-confirmation {
    background: $color-orange-300;
  }

  &.form-border-bottom {
    border: 0;
    border-bottom: 1px solid $transparent-black-10;
  }

  &.form-border-none {
    border: 0;
  }

  &.form-warning {
    background: $color-orange-300;

    button {
      border-color: darken($color-yellow-1100, 5);
      background-color: $color-yellow-1100;

      &.ghost {
        background: none;
      }

      &.ghost:hover {
        background: none;
      }

      &:hover {
        background: saturate(lighten($color-yellow-1100, 5%), 20%);
      }

      &:disabled {
        color: $color-grey-700;
        border-color: $color-grey;
        background: $color-grey-300;
      }
    }
  }

  a.button.ghost {
    margin: 0 1rem;
    background: none;
  }

  &.form-search {
    background: $color-lightgreen-200;

    .form-buttons a.button, .form-buttons button {
      border-color: $color-green-900;
      background-color: $color-lightgreen-600;

      &.white {
        border-color: transparentize($color-blue-a500, .8);

        &:hover {
          color: $color-white;
          border-color: $color-blue;
          background: $color-blue;
        }
      }

      &.ghost {
        background: none !important;
      }

      &.ghost:hover {
        background: none !important;
      }

      &:hover, &:focus {
        background: saturate(lighten($color-lightgreen-600, 5%), 20%);
      }

      &:disabled {
        color: $color-grey-700;
        border-color: $color-grey;
        background: $color-grey-300;
      }
    }

    .field-options {
      @include rfb(padding-top, .4);

      ul {
        @include rfb(padding, 0);
        @include rfb(margin, 0);
        list-style: none;

        li {
          @include rfb(margin-bottom, 1.1);
          display: inline-block;
        }
      }

      a {
        @include rfb(padding, .5);
        border-radius: 3px;
        background: $transparent-white-50;
        //background:aliceblue;
      }
    }

    .form-has-options {
      .form-row {
        position: relative;

        .field-delete {
          position: absolute;
          right: 0;
        }

        &.form-option-row {
          display: none;
        }

      }

      a.add-extra-option {
        display: inline-block;
        margin: 0 .25rem .25rem 0;
        padding: 0 .5rem;
      }
    }
  }

  // This makes your entire form go stacked on larger screens as well
  &.form-stacked {
    .form-container {
      @include rfb(padding, 1);
    }

    .form-row {
      @include rfb(padding-top, .3);
      @include rfb(padding-bottom, .3);

      .row-error-message {
        padding-left: 0;
      }

      &.row-title {

        h1, h2, h3, h4, h5, h6, p, ul {
          @include rfb(padding-left, 0);
        }

        ul li {
          @include rfb(margin-left, 0);
        }
      }

      &.row-subtitle {

        h5, h6 {
          @include rfb(margin-left, 0);
          width: 100%;
          text-align: left;
        }
      }
    }

    .form-label, .form-field-left {
      @include rfb(padding-right, 0);
      @include rfb(padding-bottom, 0);
      font-size: .95rem;
      width: 100%;
      text-align: left;
      color: $transparent-black-60;

      &.label-small {
        width: 100%;
      }

      &.label-inline {
        width: auto;
      }
    }

    .form-field, .form-label-right {
      width: 100%;

      &.field-multiradio {
        &.field-radio-inline {
          input[type='radio'] {
            @include rfb(margin-top, .6);
            float: left;
          }
        }
      }

      textarea {
        width: 100%;
      }

      // 07/05 Fix for form-stacked with field-inline inside a modal
      // &.field-inline {
      //   @include rfb(padding-left, 1.5);
      // }

      &.field-first {
        width: 100%;
      }

      &.field-small {
        width: 30%;
      }
    }

    .form-field {
      padding-right: 0;
    }

    .form-footer {
      .form-buttons {
        @include rfb(padding-left, 0);
      }
    }
  }
}

// Form stacked without using form-container
.form-stacked {
  .form-container {
    @include rfb(padding, 1);
  }

  .form-row {
    @include rfb(padding-top, .3);
    @include rfb(padding-bottom, .3);

    &.row-title {

      h1, h2, h3, h4, h5, h6, p, ul {
        @include rfb(padding-left, 0);
      }

      ul li {
        @include rfb(margin-left, 0);
      }
    }

    &.row-subtitle {

      h5, h6 {
        @include rfb(margin-left, 0);
        width: 100%;
        text-align: left;
      }
    }

    &.row-text {
      margin-left: 0;
    }
  }

  .form-label, .form-field-left {
    @include rfb(padding-right, 0);
    @include rfb(padding-bottom, 0);
    font-size: .95rem;
    width: 100%;
    text-align: left;
    color: $transparent-black-60;

    &.label-small {
      width: 100%;
    }

    &.label-inline {
      width: auto;
    }
  }

  .form-field, .form-label-right {
    width: 100%;

    &.field-multiradio {
      &.field-radio-inline {
        input[type='radio'] {
          @include rfb(margin-top, .6);
          float: left;
        }
      }
    }

    textarea {
      width: 100%;
    }

    // 07/05 Fix for form-stacked with field-inline inside a modal
    // &.field-inline {
    //   @include rfb(padding-left, 1.5);
    // }

    &.field-first {
      width: 100%;
    }

    &.field-small {
      width: 30%;
    }
  }

  .form-footer {
    .form-buttons {
      @include rfb(padding-left, 0);
    }
  }
}

// A form row
.form-row {
  @extend .clearfix;
  @include rfb(padding, .4, 0);
  clear: left;
  width: 100%;
  height: inherit;
  color: $transparent-black-70;

  &.row-error {
    .row-error-message {
      @include rfb(padding-top, 0);
      clear: left;
      padding-left: 20%;
      color: $color-red-700;

      &:before {
        @extend .icon;
        @include rfb(margin-right, .2);
        content: char-from-code($icon-warning);
        color: $color-red-700;
        // @include rfb(margin-top, 0.56);
        // @include rfb(margin-left, -1.5);
        background: none;
      }
    }
  }

  &.row-header {
    width: 100%;

    .form-header-left {
      float: left;
      width: 50%;
      text-align: left;
    }

    .form-header-right {
      float: right;
      width: 50%;
      text-align: right;
    }
  }

  // A disabled row
  &.row-disabled {
    .form-label label {
      color: $transparent-black-30;
    }

    .form-field {

      input[type='text'], input[type='password'], input[type='date'], input[type='email'], input[type='search'] {
        color: $transparent-black-70;
        background: $transparent-black-05;

        &:disabled {
          color: $transparent-black-40;
          background: $transparent-black-05;
        }
      }

      // A disabled textarea is probably way to brutal, so use with caution.
      textarea {
        color: $transparent-black-70;
        background: $transparent-black-05;
      }

      // STILL NEEDS STYLING. Is a disabled select needed?
    }
  }

  // Hoofding template invoer voor Compas
  &.row-template-header {
    @include rfb(padding, 0);
    @include rfb(margin, 1, 0);
    height: auto;
    background: $transparent-black-00;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover; // Non-IE-compatible
    }
  }

  // Hint tekst veld in een form (readonly)
  &.row-hint {
    @include rfb(margin, .4, 0);
    @include rfb(padding, 0);
    width: auto;
    margin-left: 20%;
    border: 1px solid $transparent-black-10;
    border-top: 2px solid $color-lightgreen;
    background: $color-white;

    .hint-title {
      @include rfb(padding, 1);
      font-size: $font-size-base * 1.2;
      color: $transparent-black-50;
      background: $color-grey-100;

      &:before {
        @extend .icon;
        @extend .icon-info;
        font-size: $font-size-base * 1;
        padding-right: .5em;
        vertical-align: middle;
        color: $color-lightgreen;
      }
    }

    .hint-content {
      @include rfb(padding, 1);
    }
  }

  &.row-title {

    h1, h2, h3, h4, h5, h6, p, ul {
      padding-left: 20%;
    }

    p {
      margin-bottom: 0;
    }

    ul li {
      @include rfb(margin-left, 1.2);
    }
  }

  &.row-text {
    max-width: 80%;
    margin-left: 20%;
  }

  &.row-button a {
    margin-left: 20%;
  }

  &.row-subtitle {

    h5, h6 {
      @include rfb(padding-right, 1);
      @include rfb(padding-top, .7);
      @include rfb(padding-bottom, 0);
      @include rfb(padding-left, 0);
      font-weight: 500;
      line-height: 20px;
      float: left;
      width: 20%;
      margin: 0;
      text-align: right;
      border: 1px solid $transparent-black-00;
    }

    .optional {
      @include rfb(margin-left, 0);
      @include rfb(padding-top, .7);
      float: left;
      color: $color-muted;
    }
  }

  // Add options row
  &.row-options {
    border-radius: 3px;
  }

  .field-help-message {
    @include rfb(padding-top, 0);
    float: left;
    color: $color-muted;
  }

  .field-help-message:before {
    @extend .icon;
    @include rfb(margin-right, .5);
    @include rfb(margin-top, -.2);
    content: char-from-code($icon-info);
    color: $transparent-black-50;
  }

  .field-help-message-after {
    @include rfb(margin-left, 0);
    @include rfb(padding, .3, .5);
    float: left;
    color: $color-muted;
  }

  .field-text-link {
    @include rfb(padding-top, 0);
    float: left;
    color: $color-muted;
  }

  .field-text-link-after {
    @include rfb(margin-left, 0);
    @include rfb(padding, .3, .5);
    float: left;
    color: $color-muted;
  }

  .field-text-after {
    @include rfb(margin-left, 0);
    @include rfb(padding, .3, .5);
    float: left;
  }

  // Solve alignment and error message for checbox after field
  &.row-checkbox-after-field {
    .field-checkboxmulti {
      @include rfb(padding-top, .3);
    }

    .field-error-message {
      @include rfb(padding-top, 0);
      clear: left;
      padding-left: 20%;
      color: $color-red-700;
    }

    .field-error-message:before {
      @extend .icon;
      @include rfb(padding-right, .5);
      //@include rfb(margin-right, 0.5);
      content: char-from-code($icon-warning);
      color: $color-red-700;
      background: none;
    }

    .field-error-message-live {
      @include rfb(padding-top, 0);
      clear: left;
      padding-left: 20%;
      color: $color-orange-a500;
    }

    .field-error-message-live:before {
      @extend .icon;
      @include rfb(padding-right, .3);
      content: char-from-code($icon-warning);
      color: $color-orange-a500;
      // @include rfb(margin-top, 0.56);
      // @include rfb(margin-left, -1.5);
      background: none;
    }
  }

  // Highlight a row on hover
  &.row-slider:hover {
    background: $color-white;
  }
}

.form-compact .form-row {
  padding: 0;

  ul {
    margin: 0 0 .5rem 0;
  }

  li {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.form-compact.form-stacked .field-readonly {
  padding-top: 0;
}

.form-compact-05 .form-row {
  padding: 0 0 .5rem 0;

  ul {
    margin: 0 0 .5rem 0;
  }

  li {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

// The label of a form row
.form-label, .form-field-left {
  @include rfb(padding-right, 1);
  @include rfb(padding-top, .4);
  //line-height: 20px;
  float: left;
  width: 20%;
  text-align: right;
  //border: 1px solid $transparent-black-00; /* 24/01 testing if this can be commented out because together with the line-height it cancels eachother out*/

  // Make a form-label have the VDAB logo
  &.label-vdab {
    @include rfb(padding-top, .2);

    &:after {
      @extend .icon-after;
      font-size: 1.8em;
      font-weight: 300;
      content: char-from-code($icon-vdab);
      vertical-align: middle;
      color: $color-blue-900;
    }
  }

  // Label with a label
  &.label-label {
    @include rfb(padding-top, .05);

    .label {
      @include rfb(padding, .4);
    }
  }

  // Label and description for a progressbar
  &.label-progressbar {
    @include rfb(padding, 1, 1, 1, 0);
    width: 70%;
    text-align: left;

    p {
      margin-bottom: 0;
    }
  }

  // The label following a field (field-first)
  &.label-small {
    @include rfb(padding-right, .5);
    width: auto;
  }

  // A disabled label
  &.label-disabled {
    label {
      color: $transparent-black-30;
    }
  }

  // A label that's actually a subtitle
  &.label-subtitle {
    @include rfb(padding-bottom, 0);
    @include rfb(padding-top, .7);
    font-size: $font-size-base;
    font-weight: 500;
  }

  // An inline label before an inline field
  &.label-inline {
    @include rfb(padding-right, .5);
    width: auto;
  }

  // The slider css. STILL NEEDS TO BE DECIDED
  input[type=range] {
    width: 100%;
    margin: 18px 0;

    -webkit-appearance: none;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border: 1px solid $transparent-black-15;
    border-radius: 1.3px;
    background: $color-white;

    animate: .2s; //sass-lint:disable-line no-misspelled-properties
  }

  input[type=range]::-webkit-slider-thumb {
    width: 8px;
    height: 18px;
    margin-top: -7px;
    cursor: pointer;
    border: 1px solid $transparent-black-15;
    border-radius: 3px;
    background: $color-primary;

    -webkit-appearance: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $color-white;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border: 1px solid $transparent-black-15;
    border-radius: 1.3px;
    background: $color-white;

    animate: .2s; //sass-lint:disable-line no-misspelled-properties
  }

  input[type=range]::-moz-range-thumb {
    width: 8px;
    height: 18px;
    cursor: pointer;
    border: 1px solid $transparent-black-15;
    border-radius: 3px;
    background: $color-primary;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    color: transparent;
    border-width: 16px 0;
    border-color: transparent;
    background: transparent;

    animate: .2s; //sass-lint:disable-line no-misspelled-properties
  }

  input[type=range]::-ms-fill-lower {
    border: 1px solid $transparent-black-15;
    border-radius: 2.6px;
    background: $color-white;
  }

  input[type=range]::-ms-fill-upper {
    border: 1px solid $transparent-black-15;
    border-radius: 2.6px;
    background: $color-white;
  }

  input[type=range]::-ms-thumb {
    width: 8px;
    height: 18px;
    cursor: pointer;
    border: 1px solid $transparent-black-15;
    border-radius: 3px;
    background: $color-primary;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: $color-white;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: $color-white;
  }
}

// Styling of the input fields
.field-input-styling {
  @include rfb(padding, .4);
  float: left;
  border: 1px solid $transparent-black-15;
  border-radius: 3px;
}

.form-label-right {

  // Expanding a label-right (after a slider for example)
  &.label-expand {
    @include rfb(padding, 1, 1, 1, 0);

    a {
      color: $transparent-black-70;
    }

    .label-expand-description {
      @include rfb(margin, 0);
      float: left;
      width: 80%;
    }

    .label-expand-more {
      float: right;
      color: $transparent-black-00;
    }

    .label-expand-more:hover {
      float: right;
      color: $color-blue;
    }

    .label-expand-more:after {
      @extend .icon-after;
      @include rfb(margin-left, .3);
      content: char-from-code($icon-arrow-right);
      color: $color-blue;
    }

    .label-expand-less {
      float: right;
      color: $transparent-black-00;
    }

    .label-expand-less:hover {
      float: right;
      color: $color-blue;
    }

    .label-expand-less:after {
      @extend .icon-after;
      @include rfb(margin-left, .3);
      content: char-from-code($icon-arrow-down);
      color: $color-blue;
    }
  }

  &.label-expand:hover {

    //color:red;
    // background: $color-white;
    // cursor: pointer;
    // border-radius: 3px
    .label-expand-more {
      color: $color-blue;
    }

    .label-expand-less {
      color: $color-blue;
    }
  }

  .label-expand-detail {
    @include rfb(padding-top, 1);
    clear: both;
    overflow: hidden;
  }
}

// Optional does not work with fields with icons, this fixes that
.optional {
  @include rfb(margin-left, 0);
  @include rfb(padding, .4, .5, .4, 0);
  float: left;
  color: $color-muted;
}

// The field for a form row
.form-field, .form-label-right {
  @include rfb(padding-right, 1);
  float: left;
  width: 80%;

  /* nested formfields*/
  .form-field {
    width: 100%;
    margin: 0 0 .4rem 0;
    padding: 0;
  }

  // Make text input field clearable
  a.input-clear {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    width: 1.5em;
    margin: .25rem 0 -.65rem -1.4rem;
    white-space: nowrap;
    color: $color-grey-300;

    &:hover {
      color: $color-grey;
    }

    &:before {
      @extend .icon;
      @extend .icon-cross;
      padding-right: .3em;
      vertical-align: middle;
    }
  }

  /* complementary with the button.pill styling */
  &.field-pills {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      margin-right: .25rem;
      margin-bottom: .4rem;
    }
  }

  &.field-progressbar {
    @include rfb(padding, 1, 0, 1, 0);
    width: 30%;
  }

  input[type='time'] {
    @extend .field-input-styling;
  }

  input[type='time']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='number'] {
    @extend .field-input-styling;
  }

  input[type='number']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='password'] {
    @extend .field-input-styling;
  }

  input[type='password']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='date'] {
    @extend .field-input-styling;
  }

  input[type='date']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='email'] {
    @extend .field-input-styling;
  }

  input[type='email']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='tel'] {
    @extend .field-input-styling;
  }

  input[type='tel']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='text'] {
    @extend .field-input-styling;
  }

  input[type='text']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='search'] {
    @extend .field-input-styling;
    // Fix for input type search in safari

    -webkit-appearance: textfield;

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  input[type='search']:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='url'] {
    @extend .field-input-styling;
  }

  input[type='url']:focus {
    border-color: $color-primary;
    outline: none;
  }

  textarea {
    @extend .field-input-styling;
    line-height: 17px;
    width: 100%;
    //@include rfb(padding, 0.5, 1);
    resize: vertical;

  }

  textarea:focus {
    border-color: $color-primary;
    outline: none;
  }

  .textarea-meta {
    @include rfb(padding, 0);
    @include font-size($font-size-h6);
    width: 100%;
    text-align: right;
    color: $color-grey-700;
  }

  select {
    @include rfb(height, 2.1);
    @include rfb(padding, 0, .3, 0, .3);
    line-height: 20px;
    display: inline-block;
    float: left;
    min-width: none;
    border: 1px solid $transparent-black-15;
    border-radius: 2px;

    &::before, ::after {
      position: absolute;
      content: '';
      pointer-events: none;
    }

    &::after {
      font-size: .625em;
      line-height: 1;
      top: 50%;
      right: 1.2em;
      height: 1em;
      margin-top: -.5em;
      /*  Custom dropdown arrow */
      content: char-from-code($icon-arrow-down);
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      /*  Custom dropdown arrow cover */
      width: 2em;
      border-radius: 0 3px 3px 0;
    }
  }

  select::-ms-expand {
    /* for IE 11 */
    display: none;
  }

  select:focus {
    border-color: $color-primary;
    outline: none;
  }

  input[type='file'] {
    @include rfb(margin, .4, 0);
    float: left;
  }

  &.field-help-text {
    color: $transparent-black-50;

    .help-text:before {
      @extend .icon;
      @include rfb(margin-right, .5);
      content: char-from-code($icon-info);
      color: $transparent-black-50;
    }
  }

  &.field-starscore {
    margin-top: .25rem;

    a.star-passive {
      color: $color-grey-400;

      &:hover {
        color: $color-yellow-900;
      }
    }

    a.star-selected {
      color: $color-yellow-700;

      &:hover {
        color: $color-yellow-900;
      }
    }

    a.star-hover {
      color: $color-yellow-900;
    }

    a.icon-trash {
      margin-left: 1rem;
      color: $color-red-900;
    }
  }

  // BARCODE scan icon styling
  &.field-barcode-scan {
    // to fix dropdown positioning bug
    position: relative;

    input[type='text'] {
      @include rfb(padding-right, 1.7);
    }

    &:after {
      @extend .icon-after;
      @include rfb(margin-top, .56);
      @include rfb(margin-left, -1.5);
      position: absolute;
      content: char-from-code($icon-barcode-scan);
      color: $color-grey-300;
      background: none;
    }

    &.field-first:after {
      top: 0;

      @media (min-width: $screen-small) {
        right: 1.5em;
        margin-left: 0;
      }
    }
  }

  // Percentage icon styling
  &.field-percentage {
    position: relative;

    input[type='text'] {
      @include rfb(padding-right, 1.45);
    }

    &:after {
      @extend .icon-after;
      @include rfb(margin-top, .48);
      @include rfb(margin-left, -1.25);
      position: absolute;
      content: '%';
      color: $color-grey-300;
      background: none;
    }

    &.field-first:after {
      top: 0;

      @media (min-width: $screen-small) {
        right: 1.5em;
        margin-left: 0;
      }
    }
  }

  // Currency (euro) input styling
  &.field-currency {
    input[type='text'] {
      @include rfb(padding-left, 1.3);
    }

    &:before {
      // @extend .icon;
      @include rfb(margin-top, .35);
      @include rfb(margin-left, .5);
      font-weight: 300;
      position: absolute;
      display: block;
      content: '\20AC';
      color: $color-grey;
      background: none;
    }

    // &.field-first:before{
    // 	top:0;
    // 	right: 1.5em;
    // 	margin-left:0
    // }
  }

  //+32 invoer van enkel belgische nummer
  &.field-nat-number {
    input[type='text'] {
      @include rfb(padding-left, 2);
    }

    &:before {
      @include rfb(margin-top, .4);
      @include rfb(margin-left, .5);
      font-weight: 400;
      position: absolute;
      display: block;
      content: '+32';
      color: $color-grey-900;
      background: none;
    }
  }

  // Date icon styling
  &.field-date {
    input[type='text'] {
      @include rfb(padding-right, 1.7);
      background-image: url('../img/datepicker-icon.svg');
      background-repeat: no-repeat;
      background-position: right;
      background-size: 20px;
    }

    // Datepicker with a button
    &.date-button {
      a.action-date {
        float: left;
      }

      .action-date {
        @include rfb(margin-left, .5);
        @include rfb(margin-top, .1);
      }

      input[type='text'] {
        @include rfb(padding-right, .4);
      }

      &:after {
        content: none;
      }
    }
  }

  &.field-validated {
    input[type='text'] {
      border-color: $color-green-a500;
    }
  }

  &.field-validated:after {
    @extend .icon-after;
    @include rfb(margin-top, .56);
    @include rfb(margin-left, .5);
    content: char-from-code($icon-check);
    color: $color-green-a500;
    background: none;
  }

  // Live error veld. De error tijdens het typpen
  &.field-error-live {
    input[type='text'] {
      border-color: $color-orange-a500;
    }

    .field-error-message-live {
      @include rfb(margin-top, .3);
      //@include rfb(margin-left, 0);
      //@include rfb(padding, 0.4, 0.5);
      float: left;
      color: $color-orange-a500;
    }

    .field-error-message-live:before {
      @extend .icon;
      //@include rfb(margin-top, 0.56);
      @include rfb(margin-left, .5);
      @include rfb(margin-right, .3);
      content: char-from-code($icon-warning);
      color: $color-orange-a500;
      background: none;
    }
  }

  // First one of a field followed by smaller labels and fields (like an adress for example)
  &.field-first {
    width: auto;
    //select{
    //	min-width: 10em;
    //	option{
    //	}
    //}
  }

  // A small field following a small label
  &.field-small {
    width: auto;
  }

  // An inline field in between text or following a radio button/checkbox
  &.field-inline {
    @include rfb(margin-right, 0);
    display: inline-block;
    float: none;
    width: auto;

    input {
      float: none;
    }

    input[type='text'] {
      @include rfb(margin-right, -.4);
      @include rfb(margin-bottom, -.4);
    }

    select {
      @include rfb(margin-bottom, -.65);
    }

    // position for input-clear in field-inline
    a.input-clear {
      margin-top: 0;
      margin-left: -1rem;
    }
  }

  // A disabled field. The non clickable behavior is defined on the field itself by adding 'disabled'
  &.field-disabled {

    input[type='text'], input[type='password'], input[type='date'], input[type='email'] {
      color: $transparent-black-70;
      background: $transparent-black-05;

      &:disabled {
        color: $transparent-black-20;
        background: $transparent-black-05;
      }
    }

    // A disabled textarea is probably way to brutal, so use with caution.
    textarea {
      color: $transparent-black-70;
      background: $transparent-black-05;
    }

    // STILL NEEDS STYLING. Is a disabled select needed?
  }

  // Field with max 2 radio buttons (decide gender, yes or no, ...) next to each other
  &.field-radio {
    @include rfb(padding-top, .4);

    label {
      @include rfb(margin-right, 1em);
    }

    input[type='radio'] {
      @include rfb(margin-right, .2);
    }

    .field-delete {
      padding-top: 0;
    }
  }

  &.field-radio-image {
    $field-radio-image-background: lighten($color-grey-700, 35%);

    .field-radio-wrap {
      //style 1
      @include rfb(padding, 0, 1, 1, 1);
      display: block;

      //style 2
      // @include rfb(padding, 1);
    }

    img {
      //style 1
      @include rfb(padding, 1);

      //style 2
      // border-bottom: 1px solid darken($field-radio-image-background, 10%);
    }

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
    }

    li {
      @include rfb(margin, 0, 1, 1, 0);
      float: left;
      list-style-type: none;
      border: 1px solid darken($field-radio-image-background, 10%);
      border-radius: 2px;
      background: $field-radio-image-background;
    }
  }

  // Multiple radio buttons under each other
  &.field-multiradio {
    @include rfb(padding-top, .4);

    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, .5);
        margin-left: 1px;

        label {
          @include rfb(margin-left, 1.5);
          //@include rfb(margin-top, -0.07);
          //position: absolute;
          display: block;
        }
      }
    }

    input[type='radio'] {
      @include rfb(margin-right, .2);
      @include rfb(margin-top, .2);
      position: absolute;
    }

    .field-inline select {
      @include rfb(padding-top, .4);
    }

    .field-inline input[type='text'] {
      @include rfb(margin-left, 1.5);
      margin-top: -1rem;
    }

    .radio-text-first {
      margin-right: -1rem;
      margin-left: 1.5rem;
    }

    .radio-text-inline {
      margin-right: -1rem;
    }

    .radio-list {
      margin-left: 2.4em;
      list-style: disc;
    }
  }

  // Multiple radio buttons under each other with more than 2 lines of text in the label
  &.field-multiradio-text {
    @include rfb(padding-top, .4);

    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, .5);
        @include rfb(margin-top, 2);
        margin-left: 1px;

        label {
          @include rfb(margin-left, .3);
          @include rfb(margin-top, -.07);
          position: absolute;
        }

        &:first-child {
          @include rfb(margin-top, 0);
        }

        &:last-child {
          @include rfb(margin-bottom, 1.5);
        }
      }
    }

    input[type='radio'] {
      @include rfb(margin-right, .2);
    }

    .field-inline select {
      @include rfb(padding-top, .4);
    }

    .radio-list {
      margin-left: 2.4em;
      list-style: disc;
    }
  }

  // Field of many radio buttons organised in columns
  &.field-radiofield {
    @include rfb(padding-top, .4);

    -webkit-column-count: 4;
    /* Chrome, Safari, Opera */
    -moz-column-count: 4;
    /* Firefox */
    column-count: 4;

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, .5);
        // Weird fix for column allignment issue
        display: inline-block;
        width: 100%;
        margin-left: 1px;
      }
    }

    input[type='radio'] {
      @include rfb(margin-right, .2);
    }
  }

  &.field-radiofield-2c {
    @include rfb(padding-top, .4);

    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, .5);
        // Weird fix for column allignment issue
        display: inline-block;
        width: 100%;
        margin-left: 1px;
      }
    }

    input[type='radio'] {
      @include rfb(margin-right, .2);
    }
  }

  // Two checkboxes next to each other
  &.field-checkbox {
    @include rfb(padding-top, .4);

    label {
      @include rfb(margin-right, 1em);
    }

    input[type='checkbox'] {
      @include rfb(margin-right, .2);
    }
  }

  // Field of many checkboxes organised in columns (default 4cols)
  &.field-checkboxfield {
    @include rfb(padding-top, .4);

    -webkit-column-count: 4;
    /* Chrome, Safari, Opera */
    -moz-column-count: 4;
    /* Firefox */
    column-count: 4;

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, .5);
        // Weird fix for column allignment issue
        display: inline-block;
        width: 100%;
      }

      //added so inset also works in checkboxfield
      li.checkbox-inset {
        @include rfb(margin-left, 1.4);
        @include rfb(margin-top, -.3);
      }
    }

    input[type='checkbox'] {
      @include rfb(margin-right, .2);
    }
  }

  // Field of many checkboxes organised in 2 columns
  &.field-checkboxfield-2c {
    @extend .field-checkboxfield;

    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;

  }

  // Field of many checkboxes organised in 3 columns
  &.field-checkboxfield-3c {
    @extend .field-checkboxfield;

    -webkit-column-count: 3;
    /* Chrome, Safari, Opera */
    -moz-column-count: 3;
    /* Firefox */
    column-count: 3;
  }

  &.field-checkboxfield-5c {
    @extend .field-checkboxfield;

    -webkit-column-count: 5;
    /* Chrome, Safari, Opera */
    -moz-column-count: 5;
    /* Firefox */
    column-count: 5;
  }

  /*
  * A general fix for the wrapping of text with checkboxes that Carl has been hating on for 10000 years without doing anyhing about it except mentioning it every time: "I dont like that"
  targets every div that contains "field-checkbox" AND has a ul. fix doesn't seem necessary in situations without ul 
  */
  &[class*='field-checkbox'] ul {
    margin-left: 1.5rem !important;

    li {
      position: relative;
    }

    li input[type='checkbox'] {
      //position: absolute; //could fix the IE bug? doesn't seem needed in chrome?
      margin-top: .2rem;
      margin-left: -1.5rem;
    }


    /*sublist inside a li */
    li ul.-sublist {
      margin-left: 0 !important;

      li {
        margin: 0;
      }
    }

    /* annoying overrules for cases where a typehead is part of a checkbox list ex. dashboards > zoek taken/info */
    .awesomplete ul {
      margin-left: 0 !important;

      li {
        margin: 0;
      }
    }
    /* end superrobust(not) overrules */
  }



  // an optional alert text next to the input field. Add a div with class and text 'optional' after a field
  .optional {
    @include rfb(margin-left, 0);
    @include rfb(padding, .4, .5);
    float: left;
    color: $color-muted;
  }

  // optional alert under a textarea
  .textarea-meta .optional {
    font-size: 1rem;
    padding-top: 0;
    padding-left: 0;
  }

  // field with an error. Add a div with class 'field-error-message' and an error message inside this div after a field
  &.field-error {

    input[type='text'], input[type='email'], input[type='password'], input[type='search'], select, textarea {
      border-color: $color-red-700;
    }

    .textarea-meta {
      color: $color-red-700;
    }

    // Double icon for error on these fields causes problems. This removes initial icon and leaves only error message icon. (sadly the only way)
    &.field-typeahead, &.field-date, &.field-barcode-scan, &.field-percentage {
      &:after {
        @include rfb(margin-top, 0);
        @include rfb(margin-left, 0);
        content: none;
      }

      input[type='text'] {
        @include rfb(padding, .4);
      }
    }
  }

  .field-error-message {
    @include rfb(padding-top, 0);
    clear: left;
    color: $color-red-700;
  }

  .field-error-message:before {
    @extend .icon;
    @include rfb(margin-right, .2);
    content: char-from-code($icon-warning);
    color: $color-red-700;
    // @include rfb(margin-top, 0.56);
    // @include rfb(margin-left, -1.5);
    background: none;
  }

  // Weird, weirder, weirdest. This solves icon problems with field-typehaed combined with any other message inside.
  .field-help-message {
    @include rfb(padding-top, 0);
    clear: left;
    color: $color-muted;
  }

  .field-text-link {
    @include rfb(padding-top, 0);
    clear: left;
    color: $color-muted;
  }

  .field-message {
    @include rfb(padding-top, 0);
    float: left;
    clear: left;
  }

  .field-delete {
    @include rfb(padding-top, .35);
    float: right;
  }



  &.field-readonly {
    @include rfb(padding-top, .4);
    color: $color-black;

    /*comment to see if something breaks 10/02/2017*/
    //    a {
    //      float: left;
    //    }

    .optional {
      @include rfb(padding, 0, .5);
    }
  }

  // The styling of the options presented in the row-options row
  &.field-options {
    @include rfb(padding-top, .4);

    ul {
      @include rfb(padding, 0);
      @include rfb(margin, 0);
      list-style: none;

      li {
        @include rfb(margin-bottom, 1.1);
        display: inline-block;
        margin-right: .5rem;
      }
    }

    a {
      @include rfb(padding, .5);
      border-radius: 3px;
      background: $transparent-white-75;
    }
  }

  .field-label-disabled {
    color: $transparent-black-30;
  }
}



// Disabled on the fields
input[type='text']:disabled, input[type='password']:disabled, input[type='date']:disabled, input[type='email']:disabled, input[type='search']:disabled, select:disabled {
  background: $transparent-black-05;

  &::-webkit-input-placeholder {
    color: $transparent-black-70;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $transparent-black-20;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $transparent-black-20;
  }

  &:-ms-input-placeholder {
    color: $transparent-black-20;
  }
}

.fancy {
  .form-field {
    select {
      @include rfb(height, 2);
      @include rfb(margin, 0);
      background-color: $color-white;
      box-shadow: inset -1px -1px 3px $color-grey-300;
    }
  }
}

// style all placeholder text. Needs cleanup!
::-webkit-input-placeholder {
  color: $transparent-black-20;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $transparent-black-20;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $transparent-black-20;
}

:-ms-input-placeholder {
  color: $transparent-black-20;
}

// the form ends with submit or cancel, or other buttons
.form-footer {
  @extend .clearfix;
  @include rfb(padding, .4, 0);
  @include rfb(margin-top, .7);
  display: block;

  .form-buttons {
    padding-left: 20%;

    // Old class
    &.buttons-left-align {
      padding-left: 0;
    }

    // New and better class!
    &.buttons-left {
      padding-left: 0;
    }
  }

  button {
    @extend .button;
    @include rfb(margin-right, .7);
    background-color: $color-blue;
    // The 'annuleer' button, or the button that looks like text
  }
}

// Making a label passive
label.label-passive {
  color: $transparent-black-30;

  &:after {
    @extend .icon-after;
    padding-left: .3em;
    content: char-from-code($icon-warning);
    vertical-align: middle;
    color: $color-warning;
  }
}

// Media queries

@media (max-width: $screen-small) {
  .form-container {
    @include rfb(padding, 1);
  }

  .form-row {
    @include rfb(padding-top, .3);
    @include rfb(padding-bottom, .3);

    .row-error-message {
      padding-left: 0;
    }

    &.row-hint {
      width: 100%;
      margin-left: 0;
    }

    &.row-title {

      h1, h2, h3, h4, h5, h6, p, ul {
        @include rfb(padding-left, 0);
      }

      ul li {
        @include rfb(margin-left, 0);
      }
    }

    &.row-subtitle {

      h5, h6 {
        @include rfb(margin-left, 0);
        width: 100%;
        text-align: left;
      }
    }

    &.row-checkbox-after-field {
      .field-error-message {
        padding-left: 0;
      }

      .field-error-message-live {
        padding-left: 0;
      }
    }
  }

  .form-label, .form-field-left {
    @include rfb(padding-right, 0);
    @include rfb(padding-bottom, .1);
    width: 100%;
    text-align: left;

    &.label-small {
      width: 100%;
    }

    &.label-inline {
      width: auto;
    }
  }

  .form-field, .form-label-right {
    width: 100%;

    textarea {
      width: 100%;
    }

    &.field-first {
      width: 100%;
    }

    &.field-small {
      width: 30%;
    }
  }

  .form-field {
    padding-right: 0;
  }

  .form-footer {
    .form-buttons {
      @include rfb(padding-left, 0);
    }
  }
}

@media (max-width: $tablet-large) {
  .form-container {
    @include rfb(padding, 1);
  }

  .form-row {
    @include rfb(padding-top, .3);
    @include rfb(padding-bottom, .3);

    .row-error-message {
      padding-left: 0;
    }
  }

  .form-label, .form-field-left {
    @include rfb(padding-right, 0);
    @include rfb(padding-bottom, .1);
    width: 100%;
    text-align: left;

    &.label-small {
      width: 100%;
    }
  }

  .form-field, .form-label-right {
    width: 100%;

    textarea {
      width: 100%;
    }

    &.field-first {
      width: 100%;
    }

    &.field-small {
      width: 30%;
    }

    &.field-checkboxfield {
      -webkit-column-count: 3;
      /* Chrome, Safari, Opera */
      -moz-column-count: 3;
      /* Firefox */
      column-count: 3;
    }

    &.field-radiofield {
      -webkit-column-count: 3;
      /* Chrome, Safari, Opera */
      -moz-column-count: 3;
      /* Firefox */
      column-count: 3;
    }
  }
}

// Typeahead icon styling, regardless whether its used in a form or form-field
.field-typeahead {
  // to fix dropdown positioning bug
  position: relative;

  input[type='text'] {
    @include rfb(padding, .4);
    @include rfb(padding-right, 1.7);
    border: 1px solid $transparent-black-15;
    border-radius: 3px;
  }

  &:after {
    @extend .icon-after;
    @include rfb(margin-top, .56);
    @include rfb(margin-left, -1.5);
    position: absolute;
    content: char-from-code($icon-search);
    color: $color-grey-300;
    background: none;
  }

  &.field-first:after {
    top: 0;

    @media (min-width: $screen-small) {
      right: 1.5em;
      margin-left: 0;
    }
  }
}

//END TYPEAHEAD

//Inset checkbox styling
// Multiple checkboxes under each other
.field-checkboxmulti {
  @include rfb(padding-top, .4);

  -webkit-column-count: 1;
  /* Chrome, Safari, Opera */
  -moz-column-count: 1;
  /* Firefox */
  column-count: 1;

  ul {
    @include rfb(padding, 0);
    @include rfb(margin, 0);
    list-style: none;

    li {
      @include rfb(margin-bottom, .5);
    }

    li.checkbox-inset {
      @include rfb(margin-left, 1.5);
      @include rfb(margin-top, -.3);
    }
  }

  input[type='checkbox'] {
    @include rfb(margin-right, .5);
  }
}

//END inset checkbox

@media (max-width: $tablet-small) {
  .form-container {
    @include rfb(padding, 1);
    @include rfb(margin-bottom, 0);
  }

  .form-row {
    @include rfb(padding-left, 0);
    @include rfb(padding-right, 0);
    @include rfb(padding-top, .3);
    @include rfb(padding-bottom, .3);

    .row-error-message {
      padding-left: 0;
    }

    &.row-options {
      @include rfb(padding, 1);
    }

    &.row-hint {
      width: 100%;
      margin-left: 0;
    }
  }

  .form-label, .form-field-left {
    @include rfb(padding-bottom, 0);
    width: 100%;

    &.label-small {
      width: 100%;
    }
  }

  .form-field, .form-label-right {
    width: 100%;

    input[type='date'] {
      width: 100%;
    }

    input[type='text'], input[type='password'] {
      width: 100%;
    }

    input[type='url'] {
      width: 100%;
    }

    textarea {
      @include rfb(height, 6);
    }

    select {
      width: 100%;
    }

    input[type='range'], ::-moz-range-track, ::-ms-track {
      width: 100%;
    }

    &.field-first {
      width: 100%;
    }

    &.field-small {
      width: 80%;
    }

    &.field-inline {
      width: 50%;
    }

    &.field-radiofield {
      -webkit-column-count: 2;
      /* Chrome, Safari, Opera */
      -moz-column-count: 2;
      /* Firefox */
      column-count: 2;
    }

    //Field of multiple checkboxes
    &.field-checkboxfield {
      -webkit-column-count: 2;
      /* Chrome, Safari, Opera */
      -moz-column-count: 2;
      /* Firefox */
      column-count: 2;
    }

    .optional {
      @include rfb(margin-left, 0);
      @include rfb(padding, .2, 0);
      float: left;
      color: $color-muted;
    }
  }

  .form-footer {
    button {
      @include rfb(margin-top, 0);

      &.ghost {
        @include rfb(margin-top, 0);
      }

      &.beta {
        @include rfb(margin-top, .7);
      }
    }
  }
}



/* Overrule angular component */
.editable-controls {
  input {
    margin-right: -5px;
  }

  select {
    @include rfb(margin-right, 1);
  }

  button {
    @include rfb(padding-top, .24);
    @include rfb(padding-bottom, .24);
    @include rfb(padding-right, .4);
    line-height: 1em;
    display: block;
    float: left;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    white-space: nowrap;
    border-top: 1px solid $transparent-black-15;
    border-right: 0;
    border-bottom: 1px solid $transparent-black-15;
    background-color: $color-grey-100;
    box-shadow: $transparent-black-10 -1px -1px 3px inset;

    &:hover {
      background-color: $color-grey-300;
      box-shadow: none;
    }
  }

  button.ng-isolate-scope {
    @include rfb(padding-left, .5);
    color: $color-lightgreen-600;
    border-left: 1px solid $transparent-black-15;
    border-radius: 3px 0 0 3px;


    &:before {
      @extend .icon;
      @extend .icon-check;
      padding-right: .3em;
      vertical-align: middle;
    }
  }

  button.ng-isolate-scope.ghost {
    @include rfb(padding-right, .4);
    @include rfb(padding-left, .4);
    color: $color-red-a500;
    border-right: 1px solid $transparent-black-15;
    border-left: 1px solid $transparent-black-15;
    border-radius: 0 3px 3px 0;


    &:before {
      @extend .icon;
      @extend .icon-x;
      padding-right: .3em;
      vertical-align: middle;
    }
  }
}

// Allow form-field in row-subtitle to make subtitle part editable
div.row-subtitle>div.form-field {
  margin-top: .6rem;
}

// XEDITABLE CSS (Angular)

/*!
angular-xeditable - 0.1.9
Edit-in-place for angular.js
Build date: 2015-03-26
*/

.editable-wrap {
  display: inline-block;
  margin: 0;
  white-space: nowrap;
}

.editable-wrap .editable-controls, .editable-wrap .editable-error {
  margin-bottom: 0;
}

.editable-wrap .editable-controls>input, .editable-wrap .editable-controls>select, .editable-wrap .editable-controls>textarea {
  margin-bottom: 0;
}

.editable-wrap .editable-input {
  display: inline-block;
}

.editable-buttons {
  display: inline-block;
  vertical-align: top;
}

.editable-buttons button {
  margin-left: 5px;
}

.editable-input.editable-has-buttons {
  width: auto;
}

.editable-bstime .editable-input input[type=text] {
  width: 46px;
}

.editable-bstime .well-small {
  margin-bottom: 0;
  padding: 10px;
}

.editable-range output {
  display: inline-block;
  min-width: 30px;
  text-align: center;
  vertical-align: top;
}

.editable-color input[type=color] {
  width: 50px;
}

.editable-checkbox label span, .editable-checklist label span, .editable-radiolist label span {
  margin-right: 10px;
  margin-left: 7px;
}

.editable-hide {
  display: none !important;
}

.editable-click, a.editable-click {
  text-decoration: none;
  color: $color-blue;
}

.editable-click:hover, a.editable-click:hover {
  text-decoration: none;
  color: $color-blue-a500;
  border-bottom: 0;
}

.editable-empty, .editable-empty:hover, .editable-empty:focus, a.editable-empty, a.editable-empty:hover, a.editable-empty:focus {
  font-style: normal;
  text-decoration: none;
  color: $color-blue-700;
}

// copy the clear action outside any scope so it can be used outside forms and formfields
a.input-clear {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  width: 1.5em;
  margin: .25rem 0 -.65rem -1.4rem;
  white-space: nowrap;
  color: $color-grey-300;

  &:hover {
    color: $color-grey;
  }

  &:before {
    @extend .icon;
    @extend .icon-cross;
    padding-right: .3em;
    vertical-align: middle;
  }
}