$meta-color-green: $color-green;
$meta-color-yellow: $color-yellow;
$meta-color-red: $color-red;
$meta-color-alternative: $color-dark;
$meta-color-muted: $color-muted;
$meta-block-action-link: $color-grey-800;

@mixin meta-styling($color) {
  border-left: 30px solid $color;

  h3 {
    span.muted {
      @include font-size($font-size-base);
      color: $meta-color-muted;
    }
  }
}

.meta-block {
  .meta-block-head {
    @extend %large-12;
    @extend %col;
    @include rfb(padding, 0, 2);
    h3 {
      margin-top: 22px;
    }
  }

  .meta-block-body {
    @extend %large-12;
    @extend %col;
    @include rfb(padding, 0, 2);
  }

  .meta-block-column {
    @extend %large-6;
    @extend %col;
    &:first-child {
      padding-left: 0;
    }
    p {
      @include rfb(margin, 0);
      clear: both;
    }

    div {
      @include rfb(margin, 0);
      clear: both;
    }
  }

  .meta-block-content {
    @extend %panel-group;
    @extend %large-12;
    @extend %col;
    @include rfb(padding, 0);
    @include meta-styling($meta-color-yellow);

    &.alternative {
      @include meta-styling($meta-color-alternative);
    }

    &.tender {
      @include meta-styling($meta-color-red);
    }

    &.bgltal {
      @include meta-styling($color-purple-a500);
    }

    &.kansengroep {
      @include meta-styling($meta-color-green);
    }
    &.TWE {  //sass-lint:disable-line class-name-format
      @include meta-styling($meta-color-red) 
    }
  }

  .meta-block-actions {
    @include font-size(14, 0);
    @include rfb(padding, 0, 2, 1, 2);
    float: right;
    width: auto;
  }

  .meta-block-action {
    @include rfb(margin, 1, 1, 0, 0);
    float: right;

    a {
      cursor: pointer;
      text-decoration: none;
      color: $meta-block-action-link;
    }
  }
}
