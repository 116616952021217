body.white {
  background-color: $color-white;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: $color-link;

  &:visited { 
    color: $color-visited;
  }

  &:hover {
    transition: (color .2s ease-out); // needs fix for mobile safari dropdown
    color: darken($color-link, 15%);
  }

  &:focus {
    color: $color-blue-700;
    outline: thin dotted;
  }

  &:hover, &:active, &.accordion-toggle:focus {
    outline: 0;
  }

  &.active {
    color: $color-indigo;
  }

  &.action-link {
    display: block;
    text-decoration: none;

    &.centered {
      text-align: center;
    }
  }
}

/* Specific case for action links and icons that do not take on the visited color*/
a[class*='action-']:not(.button):visited,
a[class*='icon-']:not(.button):visited {
  color: $color-link;
}

ul, ol {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);
  padding-left: 1.1rem;

  ul, ol {
    margin-bottom: 0;
  }
}

hr {
  clear: both;
  height: 1px;
  margin: 10px 0 10px;
  border: solid $color-grey-300;
  border-width: 1px 0 0;
}

.vdab-logo {
  &:before {
    @extend .icon;
    @extend .icon-vdab;
    font-size: 2rem;
    line-height: 1rem;
    padding-right: .2rem;
    vertical-align: middle;
    color: $color-blue;
  }

  &.logo-dark {
    &:before {
      @extend .icon;
      @extend .icon-vdab;
      font-size: 2rem;
      line-height: 1rem;
      padding-right: .2rem;
      vertical-align: middle;
      color: $color-indigo;
    }
  }

  &.logo-white {
    &:before {
      @extend .icon;
      @extend .icon-vdab;
      font-size: 2rem;
      line-height: 1rem;
      padding-right: .2rem;
      vertical-align: middle;
      color: $color-white;
    }
  }

  &.logo-label {
    padding: .15rem .3rem .25rem;
    color: $color-white;
    border-radius: 2px;
    background: $color-blue;

    &:before {
      @extend .icon;
      @extend .icon-vdab;
      font-size: 2rem;
      line-height: 1rem;
      padding-right: .2rem;
      vertical-align: middle;
      color: $color-white;
    }
  }
}

a.logo-label:hover {
  background: $color-blue-a500;
}

//TODO move to better spot
button.passive {
  cursor: default !important;
}

/* IE password input -  don't show the x for text inputs */
::-ms-clear {
  width: 0;
  height: 0;
}

/* IE password input - don't show the eye for password inputs */
::-ms-reveal {
  width: 0;
  height: 0;
}

/* Make breadcrumbs smaller */
.breadcrumb {
  font-size: .875rem;
}

/* seperator line */
.hr-with-text {
  @include flexbox();
  margin: 1rem 0;
  align-items: center;
  
  .-line {
    @include flex-grow(4);
    height: 1px;
    background-color: $color-grey-400;
  }
  .-text {
    @include flex-grow(1);
    text-align: center;
  }
}