
// CLEARFIX
// - - - - - - - - - - - - - - - - - - - -
// Give an element a clearfix,
// when you can't add it through HTML.
//
// This mixin is @included in the .clearfix class
// located in _classes.scss.
//
// ! NOTE:
// This should not be used with @include,
// but instead the class should be extended.
// See example below.
//
// Example:
// --------
// .selector {
//   @extend %clear;
// }
//
@mixin clearfix() {
  &:before, &:after {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}
